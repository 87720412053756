import React, { HTMLProps } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props extends HTMLProps<HTMLInputElement> {
  children?: JSX.Element;
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(6),
    border: `1px solid ${theme.palette.grey['600']};`,
  },
  inputContainer: {
    flex: '0 0 auto',
    marginRight: theme.spacing(6),
    position: 'relative',
    height: 18,
    width: 18,
  },
  input: {
    position: 'absolute',
    height: 18,
    width: 18,
    borderRadius: '50%',
    cursor: 'pointer',
    margin: 0,
  },
  hidden: {
    opacity: 0,
    zIndex: 1,
  },
  checkmark: {
    height: 18,
    width: 18,
    display: 'flex',
    border: `1px solid ${theme.palette.primary.main}`,
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  checked: {
    height: 12,
    width: 12,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  labelNotChecked: {
    cursor: 'pointer',
  },
}));

const RadioButton = (props: Props): JSX.Element => {
  const { children, id, className, checked, ...rest } = props;
  const classes = useStyles();

  return (
    <label htmlFor={id} className={clsx(classes.root, !checked && classes.labelNotChecked, className)}>
      <div className={classes.inputContainer}>
        <input
          className={clsx(classes.input, classes.hidden)}
          id={id}
          type="radio"
          aria-checked={checked}
          checked={checked}
          {...rest}
        />
        <div className={clsx(classes.input, classes.checkmark)}>
          <div className={checked ? classes.checked : ''} />
        </div>
      </div>
      {children}
    </label>
  );
};

export default RadioButton;
