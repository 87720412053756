import React, { createContext, useContext, useEffect, useState } from 'react';
import Providers from 'common/providers/Providers';
import CheckoutEmail from './scenes/CheckoutEmail/CheckoutEmail';
import CheckoutDeliveryPayment from './scenes/CheckoutDeliveryPayment/CheckoutDeliveryPayment';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useCart } from 'common/hooks/useCart';
import EmptyCartPage from 'common/components/EmptyCartPage';
import getConfig from 'config';
import { redirect, retry } from 'common/lib/helpers';
import axios from 'axios';
import { useClients } from './scenes/CheckoutDeliveryPayment/scenes/CheckoutPayment/BraintreeHelpers';
import useScript from 'common/hooks/useScript';
import Loader from 'common/components/Loader';
import i18n from 'common/providers/i18n';
import { getHub } from 'common/interfaces/sentry-hub';

export interface Props {}

export const checkoutPaths = {
  email: '',
  delivery: `/${i18n.t('urls.delivery')}`,
  payment: `/${i18n.t('urls.delivery')}/${i18n.t('urls.payment')}`,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const Checkout = (props: Props): JSX.Element | null => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const { cart, loading: isLoadingCart } = useCart();
  const [clients, initClients] = useClients();

  // this script MUST be loaded before braintree loads or else google pay does not work consistently in safari/edge
  const scriptLoaded = useScript('https://pay.google.com/gp/p/js/pay.js');

  useEffect(() => {
    const initBraintree = async (cartId: string) => {
      let clientToken;
      const timeoutMs = 15 * 1000;

      try {
        const res = await retry(
          async () =>
            await axios.post(
              `${getConfig().api}/payment/init`,
              {
                cart_id: cartId,
              },
              { timeout: timeoutMs }
            ),
          5,
          4000
        );
        clientToken = res?.data?.client_token;
      } catch (err) {
        const hub = getHub();
        if (hub) {
          hub.withScope((scope) => scope.setExtra('type', 'init payment failed'));
          hub.captureException(err);
        }
        console.error(err);
      }

      if (initClients && clientToken?.length) {
        await initClients(clientToken);
      }
    };

    if (scriptLoaded && cart) initBraintree(cart.id);
  }, [scriptLoaded, cart?.id]);

  if (isLoadingCart) {
    return (
      <div className={classes.root}>
        <Loader />
      </div>
    );
  }
  if (!cart?.lineItems.length)
    return (
      <EmptyCartPage
        action={() => {
          redirect(getConfig().urls.catalog);
        }}
      />
    );
  if (pathname.startsWith(checkoutPaths.delivery))
    return (
      <div className={classes.root}>
        <CheckoutDeliveryPayment braintreeClients={clients} />
      </div>
    );
  return (
    <div className={classes.root}>
      <CheckoutEmail braintreeClients={clients} />
    </div>
  );
};

export const LoadingContext = createContext<{
  setLoading: (isLoading: boolean) => void;
  loading: boolean;
}>({
  setLoading: (isLoading: boolean) => {},
  loading: false,
});

export const useLoading = () => useContext(LoadingContext);

const ProvidedCheckout = (props: Props) => {
  const [loading, setLocalLoading] = useState(false);
  const setLoading = (isLoading: boolean) => {
    setLocalLoading(isLoading);
  };

  return (
    <Providers>
      <LoadingContext.Provider value={{ setLoading, loading }}>
        <Checkout {...props} />
      </LoadingContext.Provider>
    </Providers>
  );
};

export default ProvidedCheckout;
