import getConfig from 'config';
import { Address } from 'react-loqate';
import { getHub } from 'common/interfaces/sentry-hub';

export const getValidLoqatePostcode = async (postcode: string, country?: string) => {
  // loqate does not handle 9 length postcodes for US well, it returns address suggestions rather than postcodes
  if (country === 'US' && postcode.length > 5) {
    return postcode;
  }

  // if (country && ["US", "CA"].includes(country)) {
  //   // TODO maybe use https://www.loqate.com/resources/support/cleanse-api/international-batch-cleanse/ instead?
  //   console.warn(`Postcode validation for ${country} not supported yet`)
  //   return ''
  // }

  const languageCode = getConfig().locale.split('_')[0];
  const url =
    `https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws` +
    `?Key=${getConfig().loqateApiKey}` +
    `&Language=${languageCode}` +
    `&Text=${postcode}` +
    `${country ? '&Countries=' + country : ''}`;

  try {
    // check if loqate can find something resembling the postcode
    const res = await fetch(url).then((response: Response) => response.json());
    const postcodeResults = res?.Items.filter((item: any) => item.Type === 'Postcode').map((item: any) => item.Text);
    if (!postcodeResults.length) {
      return '';
    }

    // check if a sanitzed version of the postcode matches a sanitized version of a postcode by loqate
    const sanitizedInput = postcode.toLowerCase().replace(/\s+/g, '');
    const sanitizedResults = postcodeResults.map((item: String) => item.toLowerCase().replace(/\s+/g, ''));
    const lookupIndex = sanitizedResults.indexOf(sanitizedInput);
    const isValid = lookupIndex >= 0;
    if (isValid) {
      return postcodeResults[lookupIndex];
    }
  } catch (err) {
    getHub()?.captureException(err);
    console.error(err);
  }

  return '';
};

export const mergeLoqateAddressLines = (loqateAddress: Address): { addressLine1: string; addressLine2: string } => {
  const maxLineLength = 60;
  const separator = ', ';

  const { Line1, Line2, Line3, Line4 } = loqateAddress;
  const lines = [Line1, Line2, Line3, Line4].filter((e) => e && e !== '');

  // if it fits in the 2 lines, do nothing
  if (lines.length <= 2) {
    return {
      addressLine1: Line1,
      addressLine2: Line2,
    };
  }

  // try to fit the lines together gently while still using both lines
  if (lines.length === 3) {
    // see if 2 and 3 can fit on the second line
    if (Line2.length + Line3.length + separator.length <= maxLineLength) {
      return {
        addressLine1: Line1,
        addressLine2: Line2 + separator + Line3,
      };
    } else {
      // see if 1 and 2 can fit on the first line
      if (Line1.length + Line2.length + separator.length <= maxLineLength) {
        return {
          addressLine1: Line1 + separator + Line2,
          addressLine2: Line3,
        };
      }
    }
  }

  if (lines.length === 4) {
    const addressLine1 = Line1 + separator + Line2;
    const addressLine2 = Line3 + separator + Line4;
    if (addressLine1.length <= maxLineLength && addressLine2.length <= maxLineLength) {
      return {
        addressLine1,
        addressLine2,
      };
    }
  }

  // go for maximum word smush
  // check if every element plus a ", " between each element will with within max length
  let addressLine1 = '';
  let addressLine2 = '';
  if (lines.reduce((a, c) => a + c.length, 0) + lines.length - 1 * 2 < maxLineLength) {
    addressLine1 = lines.join(separator);
  } else {
    let joinedIndex = 0;
    addressLine1 = lines
      .reduce((acc, curr, i) => {
        if (i > 0 && i - 1 === joinedIndex && acc.length + curr.length + 2 < maxLineLength) {
          acc += `, ${curr}`;
          joinedIndex = i;
        }
        return acc;
      }, Line1)
      .trim();

    if (lines.length > joinedIndex) {
      addressLine2 = lines.slice(joinedIndex + 1).join(separator);
    }
  }

  return {
    addressLine1,
    addressLine2,
  };
};
