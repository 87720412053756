import DataLayer, { DataLayerEvent } from './datalayer';

// const externalTrackerModule = 'dev';
// // tslint:disable-next-line
// const externalTracker = require(`./externalTrackers/${externalTrackerModule}.ts`);

export const sendEvent = (event: DataLayerEvent) => {
  if (window.gdcDataLayer) window.gdcDataLayer.push(event);
};

export const init = () => {
  const dataLayer = window.gdcDataLayer || new DataLayer();
  window.gdcDataLayer = dataLayer;
  // externalTracker.init(window.gdcDataLayer);

  // screw ie11  https://stackoverflow.com/questions/27176983/dispatchevent-not-working-in-ie11/49071358#49071358
  let event;
  if (typeof Event === 'function') {
    event = new Event('gdcDataLayerLoaded');
    document.dispatchEvent(event);
  }
};
