import React from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
}

const UpChevron = (props: Props): JSX.Element => {
  const { className, onClick, ...rest } = props;
  return (
    <svg
      {...rest}
      onClick={onClick}
      className={className}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75739 15L13 10.7574L17.2427 15"
        stroke="#2D2D2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UpChevron;
