import { Box, makeStyles } from '@material-ui/core';
import Loader from 'common/components/Loader';
import Txt from 'common/components/Txt';
import { useUpsellProducts } from 'common/hooks/useUpsellProducts';
import ChevronRight from 'common/icons/ChevronRight';
import i18n from 'common/providers/i18n';
import Providers from 'common/providers/Providers';
import getConfig from 'config';
import React from 'react';
import UpsellProduct from './UpsellProduct';

export interface Props {
  sku: string;
}

export const Upsell = ({ sku }: Props) => {
  const classes = useStyles();

  const { data, loading } = useUpsellProducts(sku);

  if (!sku) {
    console.error('data-prop-sku not set');
    return null;
  }

  if (!loading && !data.length) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        {!data || loading ? (
          <Loader />
        ) : (
          <div className={classes.container}>
            <div className={classes.header}>
              <Txt fontWeight="bold" className={classes.title}>
                {i18n.t('upsellHeading')}
              </Txt>
              {getConfig().urls.upsellOverview && (
                <a href={getConfig().urls.upsellOverview} className={classes.viewAll}>
                  <Txt variant="small" className={classes.viewAllTxt}>
                    {i18n.t('upsellViewAll')}
                  </Txt>
                  <ChevronRight className={classes.viewAllIcon} />
                </a>
              )}
            </div>
            <div className={classes.list}>
              <Box>
                {data.map((item) => (
                  <UpsellProduct key={item.variant.sku} mainSku={sku} variant={item.variant} slugs={item.slugs} />
                ))}
              </Box>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {},
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  viewAll: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  viewAllIcon: {
    marginLeft: theme.spacing(1),
    '& polyline': {
      stroke: 'currentColor',
    },
  },
  viewAllTxt: {},
  title: {
    color: theme.palette.text.primary,
  },
  list: {},
}));

const ProvidedUpsell = (props: Props) => {
  return (
    <Providers>
      <Upsell {...props} />
    </Providers>
  );
};

export default ProvidedUpsell;
