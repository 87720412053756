import * as Sentry from '@sentry/browser';

export interface AnalyticsLineItem {
  sku?: string | null;
  name: { [key: string]: string };
  price: number;
  variant: { [key: string]: string };
  brand: string;
  category: string;
  quantity: number;
}

interface Payload {
  cartId: string;
  [key: string]: string | string[] | number | boolean | AnalyticsLineItem[] | undefined;
}

export interface DataLayerEvent {
  type:
    | 'ADD_LINE_ITEM'
    | 'REMOVE_LINE_ITEM'
    | 'VIEW_ELEMENT'
    | 'SET_EMAIL'
    | 'SET_SHIPPING_ADDRESS'
    | 'SET_SHIPPING_METHOD'
    | 'SET_MARKETING_OPT_IN'
    | 'SET_BILLING_ADDRESS'
    | 'PAY'
    | 'ORDER_CONFIRMED'
    | 'PAYMENT_FAILED'
    | 'SET_PROMO_CODE_SUCCESS'
    | 'SET_PROMO_CODE_FAILED';

  payload: Payload;
}

const events: DataLayerEvent[] = [];
const listeners: ((event: DataLayerEvent) => void)[] = [];

export default class DataLayer {
  push(event: DataLayerEvent) {
    events.push(event);
    this.broadcast(event);
  }

  listen(cb: (event: DataLayerEvent) => void) {
    listeners.push(cb);
    // push current events to new listener
    events.forEach((event) => {
      try {
        cb(event);
      } catch (err: any) {
        this.handleError(err);
      }
    });
  }

  broadcast(event: DataLayerEvent) {
    listeners.forEach((listen) => {
      try {
        listen(event);
      } catch (err: any) {
        this.handleError(err);
      }
    });
  }

  handleError(err: Error) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(err);
    } else {
      console.error(err);
    }
  }
}
