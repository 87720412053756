import * as React from 'react';
import { makeStyles } from '@material-ui/core';

interface Props {
  width?: number;
  height?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      '&:hover': {
        color: theme.palette.grey[300],
      },
    },
  },
}));

const BasketIcon = ({ width = 40, height = 40, ...rest }: Props) => {
  const classes = useStyles();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.root}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6 12.2a1 1 0 01.2 1.4L16 16h8l-1.8-2.4a1 1 0 111.6-1.2l2.7 3.6H29a1 1 0 110 2h-.68l-1.84 9.196a1 1 0 01-.98.804H20v-2h4.68l1.6-8H13.72l.6 3h-2.04l-.6-3H11a1 1 0 010-2h2.5l2.7-3.6a1 1 0 011.4-.2zM13 24a1 1 0 112 0v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0v-2h-2a1 1 0 110-2h2v-2z"
        fill="#2D2D2D"
      />
    </svg>
  );
};

export default BasketIcon;
