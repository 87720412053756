import React from 'react';
import Txt from 'common/components/Txt';
import i18n from 'common/providers/i18n';
import { makeStyles, Hidden } from '@material-ui/core';
import Button from 'common/components/Button';
import BasketLarge from 'common/icons/BasketLargeEmpty';
import ItemCount from 'common/components/ItemCount';

interface Props {
  action: () => void;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(6),
  },
  header: {
    display: 'flex',
  },
  itemCount: {
    marginLeft: theme.spacing(4),
  },
  content: {
    margin: 'auto',
    marginTop: theme.spacing(24),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  element: {
    marginBottom: theme.spacing(6),
  },
}));

const EmptyCartPage = (props: Props): JSX.Element => {
  const { action } = props;
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Hidden smUp>
        <header className={classes.header}>
          <Txt variant="headingFour">{i18n.t('MyBasket')}</Txt>
          <ItemCount className={classes.itemCount} />
        </header>
      </Hidden>

      <div className={classes.content} data-testid="empty-cart-page">
        <BasketLarge className={classes.element} />
        <Txt className={classes.element} variant="headingThree">
          {i18n.t('YourBasketIsEmpty')}
        </Txt>
        <Button className={classes.element} onClick={action}>
          {i18n.t('ContinueShopping')}
        </Button>
      </div>
    </section>
  );
};

export default EmptyCartPage;
