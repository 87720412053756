import React from 'react';
import { makeStyles, Portal } from '@material-ui/core';
import { StyleProvider } from 'common/providers/StyleProvider';
import clsx from 'clsx';
import Txt from './Txt';
import i18n from 'common/providers/i18n';
import { redirect } from 'common/lib/helpers';
import getConfig from 'config';
import Button from './Button';

type Props = {
  startOpenTimer: () => void;
  stopOpenTimer: () => void;
  isOpen: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#ffffff',
  },
  mobileRoot: (props: any) => ({
    zIndex: 1031, // sticky add to cart by dept is 1030
    boxShadow: theme.shadows[4],
    opacity: props.open ? 1 : 0,
    willChange: 'opacity',
    pointerEvents: props.open ? 'all' : 'none',
    transition: 'opacity .2s ease-in-out',
    width: '100%',
    bottom: 0,
    left: 0,
    height: 125,
    position: 'fixed',
    padding: theme.spacing(4),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100vw',
  }),
  button: {
    marginTop: theme.spacing(2),
    width: '100%',
    maxWidth: 450,
  },
}));

export const MobileAddedToCartPopup = ({ startOpenTimer, stopOpenTimer, isOpen }: Props) => {
  const classes = useStyles({ open: isOpen });

  return (
    <Portal container={document.body}>
      <StyleProvider>
        <div
          data-testid="quickview"
          onMouseEnter={stopOpenTimer}
          onTouchStart={stopOpenTimer}
          onMouseLeave={startOpenTimer}
          onTouchEnd={startOpenTimer}
          className={clsx(classes.root, classes.mobileRoot)}
        >
          <Txt>{i18n.t('AddedToBasket')}!</Txt>
          <Button
            fullWidth
            className={classes.button}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              redirect(getConfig().urls.basket);
            }}
            data-testid="view-my-basket-button"
          >
            {i18n.t('ViewBasket')}
          </Button>
        </div>
      </StyleProvider>
    </Portal>
  );
};
