import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  className?: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const PlusIcon = ({ className, onClick }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <svg
      onClick={() => onClick && onClick()}
      className={clsx(classes.icon, className)}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.714844 4.99998H9.28627" stroke="#2D2D2D" strokeWidth="1.12" strokeLinecap="square" />
      <path d="M5 0.601562L5 9.39855" stroke="#2D2D2D" strokeWidth="1.12" strokeLinecap="square" />
    </svg>
  );
};

export default PlusIcon;
