import i18n from 'common/providers/i18n';
import getConfig from 'config';

type PhoneNumberRegexMap = {
  [index: string]: {
    countryCode?: 'FORBIDDEN' | 'REQUIRED' | 'OPTIONAL';
    format?: RegExp;
    validate: RegExp;
    required?: boolean;
  };
};

export const phoneNumberRegexMap: PhoneNumberRegexMap = {
  GB: {
    validate: /^(\+[\d]{7,15}|0[\d]{6,14})$/,
    countryCode: 'REQUIRED',
  },
  FR: {
    validate: /^0(\d){9}$/,
    countryCode: 'FORBIDDEN',
    required: true,
  },
  US: {
    validate: /^[0-9()+\- ]{0,}[0-9]{1,}[0-9()+\- ]{0,}$/,
    countryCode: 'OPTIONAL',
    required: true,
  },
  CA: {
    validate: /^[0-9()+\- ]{0,}[0-9]{1,}[0-9()+\- ]{0,}$/,
    countryCode: 'OPTIONAL',
    required: true,
  },
};

type PostcodeRegexMap = { [index: string]: { format?: (postCode: string) => string; validate: RegExp } };
// Source: https://gist.github.com/jamesbar2/1c677c22df8f21e869cca7e439fc3f5b
export const postcodeRegexMap: PostcodeRegexMap = {
  GB: {
    validate: /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/,
    format: (postcode: string) => {
      const regex = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/;
      const parts = postcode
        .match(regex)
        ?.slice(1)
        .filter((e) => e !== undefined);
      return parts?.join(' ') ?? postcode;
    },
  },
  US: {
    validate: /^\d{5}(?:[- ]?\d{4})?$/,
    format: (postcode: string) => {
      const regex = /^(\d{5})(?:[- ]?(\d{4}))?$/;
      const parts = postcode
        .match(regex)
        ?.slice(1)
        .filter((e) => e !== undefined);
      return parts?.join('-') ?? postcode;
    },
  },
  CA: {
    validate: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/,
    format: (postcode: string) => {
      const regex = /^([ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z])[ -]?(\d[ABCEGHJ-NPRSTV-Z]\d)$/;
      const parts = postcode
        .match(regex)
        ?.slice(1)
        .filter((e) => e !== undefined);
      return parts?.join(' ') ?? postcode;
    },
  },
  FR: {
    validate: /^(?:[0-8]\d|9[0-6])\d{3}$/, // source: Bas
  },
};

export const validation = {
  email: (email: string): string | undefined => {
    if (
      !/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email
      )
    )
      return i18n.t('validation.InvalidEmail')!;
    return undefined;
  },
  required: (value: string): string | undefined => {
    if (!value || !value.length) return i18n.t('validation.required')!;
    return undefined;
  },
  asciiCharacters: (value: string): string | undefined => {
    if (!/^[A-Za-z \-]+$/.test(value)) return i18n.t('validation.onlyASCII')!;
    return undefined;
  },
  phone: (value: string): string | undefined => {
    if (value === '') return undefined;
    const config = getConfig();

    if (
      phoneNumberRegexMap[config.country]?.countryCode == 'REQUIRED' &&
      !(value.startsWith('0') || value.startsWith('+'))
    )
      return i18n.t('validation.noCountryCode')!;

    if (
      phoneNumberRegexMap[config.country]?.countryCode == 'FORBIDDEN' &&
      (value.startsWith('00') || value.startsWith('+'))
    )
      return i18n.t('validation.noCountryCodeAllowed')!;

    if (!phoneNumberRegexMap[config.country].validate.test(value)) return i18n.t('validation.InvalidPhone')!;
    return undefined;
  },
  isNotPOBox: (value: string, options: ValidationOptions): string | undefined => {
    // source: Bas on https://glendimplex.atlassian.net/browse/GDL-1824?atlOrigin=eyJpIjoiNGJkZTdiNmQyODY4NDI5OWFjMzQ4NTdhNDc1MTQ0NTUiLCJwIjoiaiJ9
    const frenchRegex =
      /^ *(.*,\s*|)((c(\.*|ase)(-|\/|)\s*p(\.*|ostale|ost)\s*(no|n|)\.*\s*\d{1,})|(b(\.*|oîte|-)\s*p(\.*|ostale|ost)\s*\d{1,})|(numéro\s*-*\d{1,})|(boîte\s*-*\d{1,})|(case\s*-*(no\s*|)\d{1,}))/i;
    const englishRegex =
      /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|((|.* )p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b\.|b$|b\s|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;

    const isPoBoxRegexes = {
      GB: (value: string) => englishRegex.test(value),
      FR: (value: string) => frenchRegex.test(value),
      CA: (value: string) => englishRegex.test(value) || frenchRegex.test(value),
      US: (value: string) => englishRegex.test(value),
    };

    // @ts-ignore
    if (isPoBoxRegexes[options.country!] && isPoBoxRegexes[options.country!](value))
      return i18n.t('validation.POBoxNotAllowed')!;
    return undefined;
  },
  postcode: (value: string, options: ValidationOptions): string | undefined => {
    if (value === '') return undefined;
    if (options.country && postcodeRegexMap[options.country]?.validate) {
      if (!postcodeRegexMap[options.country]?.validate.test(value)) return i18n.t('validation.InvalidPostcode')!;
    }
    return undefined;
  },
};

type ValidationOptions = {
  country?: string;
};

export const validate = (value: string | number, validations: any, options?: ValidationOptions): string | undefined => {
  const result = validations.reduce(
    (
      acc: string | undefined,
      validator: (v: string | number, options?: ValidationOptions) => string | undefined
    ): string | undefined => {
      const res = validator(value, options);
      if (res && !acc) acc = res;
      return acc;
    },
    undefined
  );
  return result;
};
