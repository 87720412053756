import React from 'react';
import Txt from 'common/components/Txt';
import { getActiveCart_inStore_me_activeCart_lineItems } from 'common/interfaces/generated/getActiveCart';
import { priceFromCents } from 'common/lib/commercetools';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  lineItem: getActiveCart_inStore_me_activeCart_lineItems;
  className?: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
  },
  oldPrice: {
    marginLeft: theme.spacing(2),
    textDecoration: 'line-through',
    color: theme.palette.error.main,
  },
}));

const LineItemCardPrice = (props: Props): JSX.Element => {
  const { lineItem, className } = props;
  const classes = useStyles();

  const price = lineItem.price.value.centAmount;
  const discountPrice = lineItem.price.discounted?.value.centAmount;

  return (
    <div className={clsx(classes.root, className)}>
      <Txt fontWeight="bold" data-testid="line-item-price">
        {discountPrice ? priceFromCents(discountPrice) : priceFromCents(price)}
      </Txt>
      {discountPrice && <Txt className={classes.oldPrice}>{priceFromCents(price)}</Txt>}
    </div>
  );
};

export default LineItemCardPrice;
