import { ApolloClient } from '@apollo/client';
import {
  getActiveCart,
  getActiveCart_inStore_me_activeCart,
  getActiveCart_inStore_me_activeCart_billingAddress,
  getActiveCart_inStore_me_activeCart_lineItems,
  getActiveCart_inStore_me_activeCart_lineItems_variant,
  getActiveCart_inStore_me_activeCart_shippingAddress,
} from 'common/interfaces/generated/getActiveCart';
import {
  getProductVariantPriceStock,
  getProductVariantPriceStock_product,
  getProductVariantPriceStock_product_masterData_current_variant,
} from 'common/interfaces/generated/getProductVariantPriceStock';
import { getProductsStock_products_results_masterData_current_allVariants } from 'common/interfaces/generated/getProductsStock';
import {
  getStore,
  getStore_store,
  getStore_store_supplyChannels_custom_customFieldsRaw,
} from 'common/interfaces/generated/getStore';
import { AddressInput } from 'common/interfaces/generated/globalTypes';
import i18n from 'common/providers/i18n';
import { GET_ACTIVE_CART, GET_PRODUCT_VARIANT_PRICE_AND_STOCK, GET_STORE } from 'common/queries';
import getConfig from 'config';
import { removeUndefinedDeep } from './helpers';
import {
  getProductPriceStock_product,
  getProductPriceStock_product_masterData_current_allVariants,
} from 'common/interfaces/generated/getProductPriceStock';
import { getProductsPriceAndStock_products_results_masterData_current_allVariants } from 'common/interfaces/generated/getProductsPriceAndStock';

export const getProductPriceAndStock = async ({
  client,
  sku,
  cart,
}: {
  client: ApolloClient<object>;
  sku: string;
  cart?: getActiveCart_inStore_me_activeCart | null;
}) => {
  const { distributionChannelId, supplyChannelId } = await getChannels(client);

  if (!distributionChannelId) {
    return {
      product: undefined,
      available: false,
    };
  }

  const { data } = await client.query<getProductVariantPriceStock>({
    query: GET_PRODUCT_VARIANT_PRICE_AND_STOCK,
    variables: {
      sku,
      distributionChannelId: distributionChannelId,
      // Use distributionChannelId as fallback;
      // If this does not have a inventory supply role, there will be no availability, which is what we want/expect
      supplyChannelId: supplyChannelId || distributionChannelId,
      currency: getConfig().currency,
      country: getConfig().country,
    },
  });

  return {
    product: data?.product,
    available: productHasStockAndPrice(data?.product, cart),
  };
};

export const getChannels = async (client: ApolloClient<object>, state?: string | null) => {
  const { data } = await client.query<getStore>({
    query: GET_STORE,
    variables: { key: getConfig().commercetools.store },
  });

  const { data: cartData } = await client.query<getActiveCart>({
    query: GET_ACTIVE_CART,
    variables: {
      storeKey: getConfig().commercetools.store,
    },
  });

  const { distributionChannelId, supplyChannelId } = filterChannels({
    store: data?.store!,
    shippingAddress: cartData?.inStore?.me?.activeCart?.shippingAddress,
    state: state,
  });
  return {
    distributionChannelId,
    supplyChannelId,
  };
};

/**
 * Filter channels based on given state.
 * Or all states if state is empty
 */
export const filterChannels = ({
  store,
  shippingAddress,
  state,
}: {
  store: getStore_store;
  shippingAddress?: getActiveCart_inStore_me_activeCart_shippingAddress | null;
  state?: string | null;
}) => {
  let useFallback = true;

  if (state) {
    // If state is given as input, we should filter channels on that state and not use a fallback.
    useFallback = false;
  } else {
    state = getConfig().state;
    if (shippingAddress?.state) {
      state = shippingAddress.state;
      useFallback = false;
    }
  }

  let { supplyChannels, distributionChannels } = store;
  if (state) {
    const supplyChannelsFiltered = supplyChannels.filter((c) => {
      const supported = _supportedStates(c.custom?.customFieldsRaw);
      if (supported === undefined) {
        // If there is no 'supportedStates' field, we can skip the entire check
        return true;
      }

      return supported.includes(state);
    });

    if (supplyChannelsFiltered.length > 0 || !useFallback) {
      supplyChannels = supplyChannelsFiltered;
    }
  }

  return {
    distributionChannelId: distributionChannels[0]?.id,
    supplyChannelId: supplyChannels[0]?.id,
  };
};

export const makeAddressInputFromAddress = (
  address: getActiveCart_inStore_me_activeCart_billingAddress | null
): AddressInput => {
  if (!address)
    return {
      country: '',
    };

  return { ...removeUndefinedDeep(address) };
};

type ProductVariant =
  | getProductVariantPriceStock_product_masterData_current_variant
  | getProductPriceStock_product_masterData_current_allVariants;

export const productHasStockAndPrice = (
  product?: getProductVariantPriceStock_product | getProductPriceStock_product | null,
  cart?: getActiveCart_inStore_me_activeCart | null
) => {
  let variants: ProductVariant[] | undefined = (product as getProductPriceStock_product)?.masterData?.current
    ?.allVariants;

  const variant = (product as getProductVariantPriceStock_product)?.masterData?.current?.variant;
  if (variant) {
    variants = [variant];
  }

  const availableVariants = variants?.filter((variant) => {
    if (!variant.price) {
      return false;
    }

    return productHasStock(variant, cart);
  });

  return availableVariants ? availableVariants.length > 0 : false;
};

export const productHasStock = (
  variant:
    | getProductVariantPriceStock_product_masterData_current_variant
    | getProductsStock_products_results_masterData_current_allVariants,
  cart?: getActiveCart_inStore_me_activeCart | null
): boolean => {
  // channels are queried with ID so either length is either 0 or 1
  const channel = variant.availability?.channels?.results[0];
  if (!channel) {
    return false;
  }

  const amountInCart = getAmountInCart(variant.sku || '', cart?.lineItems);

  return channel.availability.availableQuantity >= getConfig().features.minimumStockQuantity + (amountInCart || 0);
};

export const priceFromCents = (centPrice: number) =>
  (centPrice / 100).toLocaleString(i18n.language, {
    style: 'currency',
    currency: getConfig().currency,
  });

const _supportedStates = (fields?: getStore_store_supplyChannels_custom_customFieldsRaw[] | null) => {
  if (!fields) {
    return undefined;
  }
  for (const field of fields) {
    if (field.name == 'supportedStates') {
      return field.value || [];
    }
  }

  return undefined;
};

export const getVariantTitle = (variant?: getActiveCart_inStore_me_activeCart_lineItems_variant | null) => {
  return getVariantAttribute(variant, 'variantTitle')?.[getConfig().locale];
};

export const getProductName = (variant?: getActiveCart_inStore_me_activeCart_lineItems_variant | null): string => {
  return getVariantAttribute(variant, 'productDisplayTitle')?.[getConfig().locale] ?? '';
};

export const getProductBrand = (variant?: getActiveCart_inStore_me_activeCart_lineItems_variant | null): string => {
  return getVariantAttribute(variant, 'brand', true) ?? '';
};

export const getVariantAttribute = (
  variant: getActiveCart_inStore_me_activeCart_lineItems_variant | null | undefined,
  name: string,
  ignoreCase: boolean = false
) => {
  if (ignoreCase) {
    name = name.toLowerCase();
  }
  return variant?.attributesRaw.find((e) => (ignoreCase ? e.name.toLowerCase() : e.name) === name)?.value;
};

const getAmountInCart = (sku: string, lineItems?: getActiveCart_inStore_me_activeCart_lineItems[]) => {
  return lineItems?.find((e) => e.variant?.sku === sku)?.quantity || 0;
};

export const getProductImages = (
  variant?: getProductsPriceAndStock_products_results_masterData_current_allVariants
) => {
  const imageUrl = variant?.images?.[0]?.url ?? undefined;
  const imageUrlSmall = imageUrl?.replace(/\.png$/, '-small.png').replace(/\.jpg$/, '-small.jpg');
  const imageKey = imageUrl?.split('/')[imageUrl.split('/').length - 1].split('.')[0] || '';

  const filteredAssets = variant?.assets.filter((asset) =>
    Object.values(asset.nameAllLocales)
      .map((locale) => locale.value)
      .includes(imageKey)
  );

  const webpUrlSmall = filteredAssets?.length
    ? filteredAssets[0]?.sources.find((source) => source.uri.includes('-small'))?.uri || imageUrl
    : imageUrl;

  return { imageUrlSmall, webpUrlSmall };
};
