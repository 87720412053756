import { useQuery } from '@apollo/client';
import { GET_PRODUCT_PRICE_AND_STOCK, GET_PRODUCT_VARIANT_PRICE_AND_STOCK } from 'common/queries';
import getConfig from 'config';
import { useChannels } from './useChannels';
import {
  getProductVariantPriceStock,
  getProductVariantPriceStock_product_masterData_current_variant_price,
} from 'common/interfaces/generated/getProductVariantPriceStock';
import { productHasStockAndPrice } from 'common/lib/commercetools';
import {
  getProductPriceStock,
  getProductPriceStock_product_masterData_current_allVariants_price,
} from 'common/interfaces/generated/getProductPriceStock';
import { getActiveCart_inStore_me_activeCart } from 'common/interfaces/generated/getActiveCart';

type Price =
  | getProductPriceStock_product_masterData_current_allVariants_price
  | getProductVariantPriceStock_product_masterData_current_variant_price;

export interface ProductPriceAndStock {
  loading: boolean;
  available?: boolean;
  prices?: Price[];
}

export const useProductVariant = ({ sku }: { sku: string }) => {
  const { channels, loading: channelsLoading } = useChannels();
  const noChannels = !channelsLoading && !channels?.distributionChannelId;

  const { product, loading } = useGetVariantPriceAndStock({
    sku,
    channels,
    skip: channelsLoading || noChannels,
  });

  return {
    product,
    loading: loading || channelsLoading,
  };
};

export const useProductPriceAndStock = ({
  sku,
  productKey,
  cart,
}: {
  sku?: string;
  productKey?: string;
  cart?: getActiveCart_inStore_me_activeCart | null;
}): ProductPriceAndStock => {
  const { channels, loading: channelsLoading } = useChannels();
  const noChannels = !channelsLoading && !channels?.distributionChannelId;

  // We need to define & execute both useQuery calls in order to satisfy React
  const {
    product,
    prices: pPrices,
    loading: pLoading,
  } = useGetProductPriceAndStock({
    productKey,
    channels,
    skip: channelsLoading || noChannels,
  });

  const {
    product: variantProduct,
    prices: pvPrices,
    loading: pvLoading,
  } = useGetVariantPriceAndStock({
    sku,
    channels,
    skip: channelsLoading || noChannels,
  });

  if (!productKey && !sku) {
    throw new Error('Must define either sku or productKey');
  }

  if (noChannels) {
    return {
      loading: false,
      available: false,
      prices: undefined,
    };
  }

  const available = productHasStockAndPrice(product || variantProduct, cart);

  return {
    loading: pLoading || pvLoading || channelsLoading,
    available: available,
    prices: pPrices || pvPrices,
  };
};

const useGetProductPriceAndStock = ({
  productKey,
  channels,
  skip,
}: {
  productKey?: string;
  channels: any;
  skip: boolean;
}) => {
  const result = useQuery<getProductPriceStock>(GET_PRODUCT_PRICE_AND_STOCK, {
    variables: {
      key: productKey,
      distributionChannelId: channels.distributionChannelId,
      // Use distributionChannelId as fallback;
      // If this does not have a inventory supply role, there will be no availability, which is what we want/expect
      supplyChannelId: channels.supplyChannelId || channels.distributionChannelId,
      currency: getConfig().currency,
      country: getConfig().country,
    },
    skip: skip || !productKey,
  });

  const loading = result.loading;

  const product = result.data?.product;
  const prices = product?.masterData.current?.allVariants
    .map((variant) => variant.price as Price)
    .filter((price) => price)
    .sort((a, b) => a.value.centAmount - b.value.centAmount)
    .filter((price, pos, arr) => {
      if (!price) {
        return false;
      }

      return !pos || price.value.centAmount != arr[pos - 1].value.centAmount;
    });

  return {
    loading,
    product,
    prices,
  };
};

const useGetVariantPriceAndStock = ({ sku, channels, skip }: { sku?: string; channels: any; skip: boolean }) => {
  const result = useQuery<getProductVariantPriceStock>(GET_PRODUCT_VARIANT_PRICE_AND_STOCK, {
    variables: {
      sku,
      distributionChannelId: channels.distributionChannelId,
      // Use distributionChannelId as fallback;
      // If this does not have a inventory supply role, there will be no availability, which is what we want/expect
      supplyChannelId: channels.supplyChannelId || channels.distributionChannelId,
      currency: getConfig().currency,
      country: getConfig().country,
    },
    skip: skip || !sku,
  });
  const product = result.data?.product ?? undefined;
  const price = product?.masterData.current?.variant?.price;
  const prices = price ? [price] : [];

  return {
    loading: result.loading,
    product,
    prices,
  };
};
