import React from 'react';
import { useCart } from 'common/hooks/useCart';
import EmptyCartPage from 'common/components/EmptyCartPage';
import { makeStyles } from '@material-ui/core';
import Txt from 'common/components/Txt';
import ItemCount from 'common/components/ItemCount';
import i18n from 'common/providers/i18n';
import LineItemCards from 'common/components/LineItemCard/LineItemCards';
import EditableLineItemCard from 'common/components/LineItemCard/EditableLineItemCard';
import OrderSummaryTotals from 'common/components/OrderSummary/OrderSummaryTotals';
import getConfig from 'config';
import Button from 'common/components/Button';
import OrderSummaryPromoCode from 'common/components/OrderSummary/OrderSummaryPromoCode';
import { redirect } from 'common/lib/helpers';

interface Props {
  closeQuickview: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 450,
    maxHeight: '100vh',
    overflowY: 'auto',
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(4),
  },
  headerText: {
    marginRight: theme.spacing(2),
  },
  close: {
    marginLeft: 'auto',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderWidth: 0,
    '-webkit-appearance': 'none',
  },
  button: {
    marginTop: theme.spacing(2),
    maxWidth: 450,
  },
}));

const QuickviewContent = (props: Props) => {
  const { closeQuickview } = props;
  const { cart } = useCart();
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="quickview-contents">
      <header className={classes.header}>
        <Txt variant="headingFour" className={classes.headerText}>
          {i18n.t('MyBasket')}
        </Txt>
        <ItemCount />
        <button className={classes.close} onClick={closeQuickview} data-testid="close-quick-view">
          <Txt variant="headingFive">{i18n.t('Close')}</Txt>
        </button>
      </header>
      {!cart?.lineItems.length ? (
        <EmptyCartPage action={closeQuickview} />
      ) : (
        <>
          <LineItemCards component={EditableLineItemCard} variant="quickview" />
          <OrderSummaryTotals />
          <Button
            fullWidth
            className={classes.button}
            onClick={() => {
              redirect(getConfig().urls.checkout);
            }}
            data-testid="go-to-checkout-button"
          >
            {i18n.t('GoToCheckout')}
          </Button>
          <Button
            fullWidth
            className={classes.button}
            variant="secondary"
            onClick={() => {
              redirect(getConfig().urls.basket);
            }}
            data-testid="view-my-basket-button"
          >
            {i18n.t('ViewMyBasket')}
          </Button>
          <OrderSummaryPromoCode />
        </>
      )}
    </div>
  );
};

export default QuickviewContent;
