import { useQuery } from '@apollo/client';
import {
  getShippingMethodsByCart,
  getShippingMethodsByCart_shippingMethodsByCart,
} from 'common/interfaces/generated/getShippingMethodsByCart';
import { hasStates } from 'common/lib/states';
import { GET_SHIPPING_METHODS_BY_CART } from 'common/queries';
import { useEffect, useRef } from 'react';
import { useCart } from './useCart';

export const useShippingMethods = () => {
  const { cart } = useCart();
  const { totalPrice, shippingAddress } = cart!;

  const methods = useRef<getShippingMethodsByCart_shippingMethodsByCart[]>([]);
  const needMoreInput = useRef(false);
  const initialized = useRef(false);

  const { data, loading, refetch } = useQuery<getShippingMethodsByCart>(GET_SHIPPING_METHODS_BY_CART, {
    variables: {
      cartId: cart?.id,
    },
  });

  useEffect(() => {
    refetch();
  }, [shippingAddress?.country, shippingAddress?.state, cart?.version]);

  useEffect(() => {
    if (!loading) {
      initialized.current = true;
    }
  });

  if (loading) {
    return {
      methods: methods.current,
      loading: !initialized.current && loading,
      needMoreInput: needMoreInput.current,
    };
  }

  const shippingMethods = !data ? [] : data.shippingMethodsByCart;
  let needMoreInput_ = false;

  const methodsNew = shippingMethods.map((method) => {
    return {
      ...method,
      zoneRates: method.zoneRates
        .map((rate) => {
          return {
            ...rate,
            shippingRates: rate.shippingRates.filter((shippingRate) => {
              return shippingRate.isMatching && shippingRate.price.currencyCode === totalPrice?.currencyCode;
            }),
          };
        })
        .filter((rate) => rate.shippingRates.length),
    };
  });

  if (JSON.stringify(methods.current) !== JSON.stringify(methodsNew)) {
    // Only overwrite current if the list of methods are _actually_ different.
    // Otherwise it will trigger to much re-renders
    methods.current = methodsNew;
  }

  if (methods.current.length === 0) {
    // Having no options can mean two things; we either need more address changes,
    // or no options are available for this country (+ state).
    if (!shippingAddress?.country || !hasStates(shippingAddress.country) || !shippingAddress?.state) {
      needMoreInput_ = true;
    }
  }

  needMoreInput.current = needMoreInput_;

  return {
    methods: methods.current,
    loading,
    needMoreInput: needMoreInput.current,
  };
};
