import whiteLabelTheme from '../whitelabel/whitelabel-theme';
import F37Ginger from 'common/fonts/f37Ginger/F37Ginger.otf';
import F37GingerBold from 'common/fonts/f37Ginger/F37Ginger-Bold.otf';
import F37GingerLight from 'common/fonts/f37Ginger/F37Ginger-Thin.otf';
import * as overrides from './overrides';

const f37Ginger = {
  fontFamily: '"F37 Ginger"',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${F37Ginger})`,
};

const f37GingerLight = {
  fontFamily: '"Helvetica Neue"',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `url(${F37GingerLight})`,
};

const f37GingerBold = {
  fontFamily: '"F37 Ginger"',
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `url(${F37GingerBold})`,
};

const theme: any = JSON.parse(JSON.stringify(whiteLabelTheme));

theme.overrides = {
  ...overrides,
  MuiCssBaseline: {
    '@global': {
      html: null,
      body: null,
      '*, *::before, *::after': null,
      '@font-face': [f37Ginger, f37GingerLight, f37GingerBold],
    },
  },
};

theme.palette.button = {
  ...theme.palette.button,
  primary: {
    main: '#A3832A',
    hover: '#AF944C',
    active: '#8A6F27',
    contrastText: '#FFFFFF',
    disabled: '#8c8c8c',
  },
};
theme.palette.primary = {
  ...theme.palette.primary,
  main: '#A3832A',
  contrastText: '#FFFFFF',
};
theme.palette.secondary = {
  ...theme.palette.secondary,
  main: '#007672',
  contrastText: '#DFD1A7',
};

theme.palette.text = { ...theme.palette.text, primary: '#2D2D2D' };

theme.typography.h2 = { ...theme.typography.h2, letterSpacing: 0.5 };
theme.typography.h3 = { ...theme.typography.h3, letterSpacing: 0.5 };
theme.typography.h4 = { ...theme.typography.h4, letterSpacing: 0.5 };
theme.typography.h5 = { ...theme.typography.h5, letterSpacing: 0.5 };
theme.typography.button = { ...theme.typography.button, fontSize: 14, letterSpacing: 0.5, textTransform: 'uppercase' };
theme.typography.fontFamily = '"F37 Ginger", Helvetica, Arial, "Lucida Grande", sans-serif';
theme.typography.fontFaces = [f37Ginger, f37GingerLight, f37GingerBold];

export default theme;
