import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export type TxtVariant =
  | 'headingTwo'
  | 'headingThree'
  | 'headingFour'
  | 'headingFive'
  | 'regular'
  | 'small'
  | 'error'
  | 'link'
  | 'linkSubtle';

interface Props extends StyleProps {
  variant?: TxtVariant;
  fontWeight?: 'regular' | 'light' | 'bold';
  className?: string;
}

interface StyleProps {
  color?: 'primary' | 'secondary';
  align?: 'left' | 'center' | 'right';
}

const variantMapping: { [key: string]: keyof JSX.IntrinsicElements } = {
  headingTwo: 'h2',
  headingThree: 'h3',
  headingFour: 'h4',
  headingFive: 'h5',
  regular: 'span',
  small: 'small',
  error: 'small',
  linkSubtle: 'small',
  link: 'span',
};

const useStyles = makeStyles((theme) => ({
  root: (props: StyleProps) => {
    const { align, ...propsStyles }: any = props;

    if (align) {
      propsStyles.textAlign = props.align;
    }

    return propsStyles;
  },
  headingTwo: {
    ...theme.typography.h2,
    color: theme.palette.grey['900'],
    margin: 0,
  },
  headingThree: {
    ...theme.typography.h3,
    margin: 0,
  },
  headingFour: {
    ...theme.typography.h4,
    margin: 0,
  },
  headingFive: {
    ...theme.typography.h5,
    margin: 0,
  },
  regular: {
    ...theme.typography.body1,
  },
  bold: {
    fontWeight: 'bold',
  },
  light: {
    fontWeight: 300,
  },
  small: {
    ...theme.typography.body2,
  },
  error: {
    ...theme.typography.body2,
    color: theme.palette.error.main,
    lineHeight: theme.typography.body2.lineHeight,
    minHeight: theme.typography.body2.lineHeight,
    display: 'block',
  },
  linkSubtle: {
    ...theme.typography.body2,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.info.light,
    },
  },
  link: {
    ...theme.typography.bodyLight,
    color: theme.palette.info.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.info.light,
    },
  },
}));

const Txt = (props: PropsWithChildren<Props>) => {
  const { variant = 'regular', align = 'left', color = 'primary', fontWeight = 'regular', className, ...rest } = props;
  const classes = useStyles({ align, color });
  const Component = variantMapping[variant];

  return (
    <Component
      className={clsx(classes.root, classes[variant], fontWeight !== 'regular' && classes[fontWeight], className)}
      {...rest}
    />
  );
};

export default Txt;
