import { useQuery } from '@apollo/client';
import { Box, Hidden, makeStyles } from '@material-ui/core';
import Txt from 'common/components/Txt';
import { useCart } from 'common/hooks/useCart';
import { useCrossSellProducts } from 'common/hooks/useCrossSellProducts';
import { getMostRecentSku } from 'common/interfaces/generated/getMostRecentSku';
import { getVariantAttribute } from 'common/lib/commercetools';
import i18n from 'common/providers/i18n';
import { GET_MOST_RECENT_SKU } from 'common/queries';
import getConfig from 'config';
import React, { useState } from 'react';
import Loader from '../Loader';
import { MobileAddedToCartPopup } from '../MobileAddedToCartPopupContents';
import CrossSellProduct from './CrossSellProduct';

type Props = {
  useFirstProductInBasket?: boolean;
};

const CrossSellAddOn = ({ useFirstProductInBasket }: Props) => {
  const { cart } = useCart();
  const { data: skuData } = useQuery<getMostRecentSku>(GET_MOST_RECENT_SKU);
  const classes = useStyles();
  const showCta = getConfig().showCrossSellCta;

  // On the cart page, we use the first product in the basket
  // Otherwise, we use the mostRecentSku product
  const coreProduct = useFirstProductInBasket
    ? cart?.lineItems[0]
    : cart?.lineItems.find((e) => e.variant?.sku === skuData?.mostRecentSku);

  // slap it in state so prop change does not alter it
  const [crossSellSkus] = useState(getVariantAttribute(coreProduct?.variant, 'crossSelling'));
  const localizedSkus = crossSellSkus
    ?.map((sku: any) => sku?.[getConfig().locale])
    .filter((sku: string | undefined) => sku);

  const { data, loading } = useCrossSellProducts(localizedSkus);
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);
  const [isMobileQuickviewOpen, setIsMobileQuickviewOpen] = useState(false);

  const startOpenTimer = () => {
    if (timeoutId) stopOpenTimer();
    setIsMobileQuickviewOpen(true);
    setTimeoutId(
      window.setTimeout(() => {
        setIsMobileQuickviewOpen(false);
      }, 4000)
    );
  };

  const stopOpenTimer = () => {
    window.clearTimeout(timeoutId);
    setTimeoutId(undefined);
  };

  if (!getConfig().features.crossSelling) {
    return null;
  }

  if (!loading && !data.length) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        {!data || loading ? (
          <Loader />
        ) : (
          <div className={classes.container}>
            <Txt className={classes.header} align="center">
              {i18n.t('crossSellHeading')}
            </Txt>
            <div className={classes.list}>
              <Box>
                {data.map((item) => (
                  <CrossSellProduct
                    key={item.variant.sku}
                    item={item.variant}
                    slugs={item.slugs}
                    showCta={showCta}
                    startOpenTimer={startOpenTimer}
                  />
                ))}
              </Box>
            </div>
          </div>
        )}
      </div>
      <Hidden lgUp>
        <MobileAddedToCartPopup
          isOpen={isMobileQuickviewOpen}
          startOpenTimer={startOpenTimer}
          stopOpenTimer={stopOpenTimer}
        />
      </Hidden>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(4),
    minHeight: 200,
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      width: 221,
      minHeight: '100vh',
      maxHeight: '100vh',
      borderTop: 'none',
      borderRight: `1px solid ${theme.palette.divider}`,
      padding: 0,
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      flex: '0 0 auto',
    },
  },
  container: {
    marginLeft: -8,
    marginRight: -8,
    overflowY: 'auto',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      marginRight: 8,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  header: {
    display: 'block',
    marginBottom: theme.spacing(3),
  },
  list: {
    '& .MuiBox-root': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      '& .MuiBox-root': {
        paddingBottom: theme.spacing(5),
      },
    },
  },
}));

export default CrossSellAddOn;
