import {
  getProductsPriceAndStock_products_results_masterData_current_allVariants,
  getProductsPriceAndStock_products_results_masterData_current_slugAllLocales,
} from 'common/interfaces/generated/getProductsPriceAndStock';
import { useAvailableProducts } from './useAvailableProducts';

export interface CrossSellItem {
  variant: getProductsPriceAndStock_products_results_masterData_current_allVariants;
  slugs?: getProductsPriceAndStock_products_results_masterData_current_slugAllLocales[];
}

export const useCrossSellProducts = (skus?: string[]) => {
  const { availableProducts, ...rest } = useAvailableProducts(skus, true);
  return { data: availableProducts.slice(0, 3), ...rest };
};
