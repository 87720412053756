import i18n from 'i18next';
import getConfig from 'config';
import enGB from 'common/i18n/en_GB.json';
import enUS from 'common/i18n/en_US.json';
import enCA from 'common/i18n/en_CA.json';
import frCA from 'common/i18n/fr_CA.json';
import frFR from 'common/i18n/fr_FR.json';
import source from 'common/i18n/source.json';

const resources = {
  'en-GB': {
    common: enGB,
  },
  'en-US': {
    common: enUS,
  },
  'en-CA': {
    common: enCA,
  },
  'fr-CA': {
    common: frCA,
  },
  'fr-FR': {
    common: frFR,
  },
  source: {
    common: source,
  },
};

export const initI18n = () =>
  i18n.init({
    resources,
    defaultNS: 'common',
    ns: ['common'],
    lng: getConfig().locale,
    fallbackLng: 'source',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
