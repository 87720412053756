import React from 'react';
import BasketDefault from 'common/icons/BasketDefault';
import { makeStyles } from '@material-ui/core';
import getConfig from 'config';
import BasketEmpty from 'common/icons/BasketEmpty';
import { isGdamTheme } from 'common/lib/helpers';

interface Props {
  products: number;
  open?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: (props: { theme: string }) => {
    const base = {
      position: 'relative',
      width: 'fit-content',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: 0,
      '-webkit-appearance': 'none',
      '&:focus': {
        outline: isGdamTheme() ? 'revert' : 'inherit',
      },
      padding: 0,
    } as any;

    const morphyrichards = {
      ...base,
      // Force width/height because it looks different on production otherwise
      width: 32,
      height: 32,

      [theme.breakpoints.down('lg')]: {
        width: 26,
        height: 26,
      },
    };

    switch (props.theme) {
      case 'morphy-richards':
        return morphyrichards;
      default:
        return base;
    }
  },
  basketIcon: {
    marginTop: 4,
    marginRight: 10,
  },
  number: (props: { theme: string; productCount: number }) => {
    const base = {
      position: 'absolute',
      width: '1rem',
      height: '1rem',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ffffff',
      fontSize: 12,
      [theme.breakpoints.down('lg')]: {
        fontSize: 10,
      },
    };

    const whitelabel = {
      ...base,
      top: 0,
      right: 0,
      backgroundColor: theme.palette.grey['800'],
      borderRadius: 20,
    } as any;

    const robertsradio = {
      ...base,
      right: 'calc(50% - 0.5rem)',
      top: 8.15,
      [theme.breakpoints.down('lg')]: {
        top: 6,
      },
    };

    const morphyrichards = {
      ...base,
      fontSize: 12,
      bottom: 0,
      right: 0,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',

      [theme.breakpoints.down('lg')]: {
        fontSize: 10,
        bottom: -3,
        right: -2,
      },
    };

    const gdam = {
      ...base,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
      fontSize: 10,
      // Make it exact same size as the basket icon and make sure
      // content is centered
      width: 32,
      height: 32,
      top: 0,
      [theme.breakpoints.down('lg')]: {
        fontSize: 8,
        width: 26,
        height: 26,
        color: '#fff',
      },
    };

    if (isGdamTheme()) {
      return gdam;
    }

    switch (props.theme) {
      case 'morphy-richards':
        return morphyrichards;
      case 'robertsradio':
        return robertsradio;
      default:
        return whitelabel;
    }
  },
}));

const CartButton = (props: Props): JSX.Element => {
  const { products, open, ...rest } = props;
  const classes = useStyles({ theme: getConfig().theme, productCount: products });

  return (
    <button className={classes.root} {...rest}>
      {!!products ? (
        <BasketDefault open={open} className={classes.basketIcon} />
      ) : (
        <BasketEmpty className={classes.basketIcon} />
      )}
      {!!products && (
        <div data-testid="basket-count" className={classes.number}>
          <span>{products}</span>
        </div>
      )}
    </button>
  );
};

export default CartButton;
