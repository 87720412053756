import React from 'react';
import { makeStyles } from '@material-ui/core';
import i18n from 'common/providers/i18n';
import getConfig from 'config';
import { useCart } from 'common/hooks/useCart';

interface Props {}

const useStyles = makeStyles((theme) => ({
  root: {
    '& #submit-braintree': {
      // styling copied from Button component
      width: '100%',
      maxWidth: 450,
      cursor: 'pointer',
      lineHeight: '20px',
      fontWeight: 'bold',
      'font-family': theme.typography.fontFamily,
      padding: '10px  0px',
      fontSize: theme.typography.button.fontSize,
      color: theme.palette.button.primary.contrastText,
      backgroundColor: `${theme.palette.button.primary.main}`,
      borderWidth: 0,
      borderRadius: theme.shape.borderRadius || 0,
      textAlign: 'center',
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        backgroundColor: `${theme.palette.button.primary.hover}`,
      },
      '&:active': {
        backgroundColor: `${theme.palette.button.primary.active}`,
      },
      '&:disabled': {
        backgroundColor: `${theme.palette.button.primary.disabled}`,
        color: '#000000',
        opacity: 0.2,
      },
    },
  },
}));

const SubmitBraintreeButton = (props: Props): JSX.Element => {
  const { cart } = useCart();
  const { taxedPrice } = cart!;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <button type="submit" id="submit-braintree" data-testid="submit-braintree">{`${i18n.t('braintree.Pay')} ${(
        taxedPrice?.totalGross.centAmount / 100
      ).toLocaleString(i18n.language, {
        style: 'currency',
        currency: getConfig().currency,
      })}`}</button>
    </div>
  );
};

export default SubmitBraintreeButton;
