import { ApolloClient, ApolloLink, Observable } from '@apollo/client';
import { InMemoryCache, TypePolicies } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { SnackbarType } from 'common/interfaces/generated/globalTypes';
import { getHub } from 'common/interfaces/sentry-hub';
import { getAuthToken, removeTokenFromLocalStorage } from 'common/lib/auth';
import i18n from 'common/providers/i18n';
import getConfig from 'config';
import { GET_QUICKVIEW_STATE, GET_SNACKBAR, SET_SNACKBAR } from '../../queries';
import { omitDeep } from '../helpers';
import { resolvers, typeDefs } from './localTypeDefs';

export const typePolicies: TypePolicies = {
  Cart: {
    fields: {
      lineItems: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
      discountCodes: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
  LineItem: {
    fields: {
      discountedPricePerQuantity: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
      taxedPrice: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
};

// set up client side-data
const setInitialState = async (cache: InMemoryCache) => {
  const quickviewState = { isQuickviewOpen: false };
  const snackbarState = { snackbar: null };
  cache.writeQuery({ query: GET_QUICKVIEW_STATE, data: quickviewState });
  cache.writeQuery({ query: GET_SNACKBAR, data: snackbarState });
};

let _client: ApolloClient<any>;

export const getApolloClient = () => (_client ??= createClient());

const createClient = () => {
  const config = getConfig();

  const httpLink = new HttpLink({
    uri: ({ operationName }) => `${config.host}/${config.commercetools.projectKey}/graphql?op=${operationName}`,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAuthToken();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const typeNameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = omitDeep(operation.variables, '__typename');
    }
    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError, forward, operation, response }) => {
    // clean up broken tokens from before tokens were split per client
    if (networkError && networkError.message === 'invalid_client') {
      return new Observable((observer) => {
        removeTokenFromLocalStorage()
          .then(() => getAuthToken())
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            forward(operation).subscribe(subscriber);
          });
      });
    }

    if (networkError && 'statusCode' in networkError) {
      if ([401, 403].includes(networkError.statusCode)) {
        return new Observable((observer) => {
          removeTokenFromLocalStorage()
            .then(() => getAuthToken())
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              forward(operation).subscribe(subscriber);
            });
        });
      }
    }
    if (networkError) {
      client.mutate({
        mutation: SET_SNACKBAR,
        variables: { snackbar: { message: i18n.t('errors.tryAgain'), type: SnackbarType.ERROR } },
      });
    }

    // handle concurrent error versions
    const concurrentError = graphQLErrors?.find((e) => e.extensions.code === 'ConcurrentModification');
    if (concurrentError) {
      return new Observable((observer) => {
        operation.variables.version = concurrentError.extensions?.currentVersion;
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };
        forward(operation).subscribe(subscriber);
      });
    }

    // handle all other errors
    if (graphQLErrors?.length) {
      // we are not interested in these errors
      const filtered = graphQLErrors.filter(
        (e) => !['DiscountCodeNonApplicable', 'ConcurrentModification'].includes(e.extensions.code as string)
      );
      if (filtered.length) {
        client.mutate({
          mutation: SET_SNACKBAR,
          variables: { snackbar: { message: i18n.t('errors.tryAgain'), type: SnackbarType.ERROR } },
        });
        if (process.env.NODE_ENV === 'production') {
          const hub = getHub();
          if (hub) {
            hub.withScope((scope) => {
              scope.setExtra('operation', operation);
              scope.setExtra('operationJson', JSON.stringify(operation));
              scope.setExtra('errors', filtered);
              scope.setExtra('errorsJson', JSON.stringify(filtered));
              hub.captureException(new Error(filtered[0].extensions.code as string));
            });
          }
        } else {
          console.error(graphQLErrors);
        }
      }
    }
  });

  // httLink must always be last
  const link = ApolloLink.from([typeNameLink, errorLink, authLink, httpLink]);

  const cache = new InMemoryCache({
    typePolicies,
  });

  const client = new ApolloClient({
    link,
    cache,
    resolvers,
    typeDefs,
  });

  client.onResetStore(() => setInitialState(cache));
  setInitialState(cache);

  return client;
};
