import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import ArrowLeftIcon from 'common/icons/ArrowLeftIcon';

interface Props {
  onClick: () => void;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '&:focus': {
      outline: 'none',
    },
  },
}));

const BackButton: FC<Props> = (props) => {
  const { onClick, className } = props;
  const classes = useStyles();

  return (
    <button className={clsx(classes.root, className)} onClick={onClick}>
      <ArrowLeftIcon />
    </button>
  );
};

export default BackButton;
