import { ThemeOptions } from '@material-ui/core';
import HelveticaNeue from 'common/fonts/helveticaNeue/HelveticaNeue.ttf';
import HelveticaNeueBold from 'common/fonts/helveticaNeue/HelveticaNeueBd.ttf';
import HelveticaNeueLight from 'common/fonts/helveticaNeue/HelveticaNeueLt.ttf';

const helveticaNeue = {
  fontFamily: '"Helvetica Neue"',
  fontStyle: 'normal',
  fontWeight: 'regular',
  src: `url(${HelveticaNeue})`,
};

const helveticaNeueLight = {
  fontFamily: '"Helvetica Neue"',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${HelveticaNeueLight})`,
};

const helveticaNeueBold = {
  fontFamily: '"Helvetica Neue"',
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `url(${HelveticaNeueBold})`,
};

const theme: ThemeOptions = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: undefined,
        body: undefined,
        '*, *::before, *::after': undefined,
        '@font-face': [helveticaNeue, helveticaNeueLight, helveticaNeueBold],
      },
    },
  },
  palette: {
    button: {
      primary: {
        main: '#2D2D2D',
        hover: '#373737',
        active: '#000000',
        contrastText: '#FFFFFF',
        disabled: '#8c8c8c',
      },
      secondary: {
        main: '#2D2D2D',
        hover: '#2D2D2D',
        active: '#000000',
        contrastText: '#FFFFFF',
        disabled: '#8c8c8c',
      },
    },
    background: {
      default: 'transparent',
    },
    primary: {
      main: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#979797',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#00A3E0',
      light: '#1EC1F5',
      dark: '#008FCC',
    },
    grey: {
      200: '#F7F7F7',
      300: '#FAFAFA',
      400: '#ECECEC',
      500: '#D8D8D8',
      600: '#C8C8C8',
      700: '#979797',
      800: '#6D6D6D',
      900: '#2D2D2D',
    },
    text: {
      primary: '#6D6D6D',
      secondary: '#C8C8C8',
    },
    error: {
      light: '#1CE782', // actually not error, but green for valid
      main: '#D50032',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 600,
      lg: 960,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: '"Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
    h2: {
      fontSize: 35,
      lineHeight: '46px',
      fontWeight: 'bold',
      '@media (min-width:600px)': {
        fontSize: 45,
        lineHeight: '65px',
      },
    },
    h3: {
      fontSize: 30,
      lineHeight: '40px',
      fontWeight: 'normal',
      '@media (min-width:600px)': {
        fontSize: 35,
        lineHeight: '45px',
      },
    },
    h4: {
      fontSize: 25,
      lineHeight: '30px',
      fontWeight: 'bold',
      '@media (min-width:600px)': {
        fontSize: 25,
        lineHeight: '30px',
      },
    },
    h5: {
      fontSize: 20,
      lineHeight: '30px',
      fontWeight: 'normal',
    },
    bodyLight: {
      fontSize: 16,
      lineHeight: '30px',
      fontWeight: 300,
      '@media (min-width:600px)': {
        fontSize: 15,
      },
    },
    body1: {
      fontSize: 16,
      lineHeight: '30px',
      fontWeight: 'normal',
      '@media (min-width:600px)': {
        fontSize: 15,
      },
    },
    bodyBold: {
      fontSize: 16,
      lineHeight: '30px',
      fontWeight: 'bold',
      '@media (min-width:600px)': {
        fontSize: 15,
      },
    },
    body2: {
      // used for small and error
      fontSize: 12,
      lineHeight: '15px',
      fontWeight: 'normal',
    },
    button: {
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  shadows: [
    'none',
    '0px 0px 20px rgba(0, 0, 0, 0.05)',
    '0px 10px 20px rgba(0, 0, 0, 0.05)',
    '0px -10px 10px -10px rgba(0, 0, 0, 0.08)',
    '0px 0px 25px rgba(0, 0, 0, 0.05)',
    '1px 1px 4px 2px rgba(0, 0, 0, 0.1)',
    '1px 1px 1px 1px rgba(0, 0, 0, 0.1)',
    '0px -5px 20px 0px rgba(0, 0, 0, 0.08)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  spacing: 5,
};

export default theme;
