import whiteLabelTheme from 'common/themes/whitelabel/whitelabel-theme';
import MontserratRegular from 'common/fonts/montserrat/Montserrat-Regular.woff';
import MontserratMedium from 'common/fonts/montserrat/Montserrat-Medium.woff';
import MontserratSemibold from 'common/fonts/montserrat/Montserrat-Semibold.woff';

const montserratRegular = {
  fontFamily: 'Montserrat',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url(${MontserratRegular}) format('woff')`,
};

const montserratMedium = {
  fontFamily: 'Montserrat',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `url(${MontserratMedium}) format('woff')`,
};

const montserratSemibold = {
  fontFamily: 'Montserrat',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `url(${MontserratSemibold}) format('woff)`,
};

const theme: any = JSON.parse(JSON.stringify(whiteLabelTheme));

theme.overrides.MuiCssBaseline = {
  '@global': {
    html: null,
    body: null,
    '*, *::before, *::after': null,
    '@font-face': [montserratRegular, montserratMedium, montserratSemibold],
  },
};

theme.palette.button = {
  ...theme.palette.button,
  primary: {
    main: '#0067B1',
    hover: '#147BC5',
    active: '#00539D',
    contrastText: '#FFFFFF',
    disabled: '#8c8c8c',
  },
};
theme.palette.primary = {
  ...theme.palette.primary,
  main: '#0067B1',
  contrastText: '#FFFFFF',
};
theme.palette.secondary = {
  ...theme.palette.secondary,
  main: '#0067B1',
  contrastText: '#FFFFFF',
};

theme.palette.info = { ...theme.palette.info, main: '#0099DC', light: '#14ADF0', dark: '#0085C8' };

theme.palette.text = { ...theme.palette.text, primary: '#2D2D2D' };

theme.palette.error = { ...theme.palette.error, main: '#BD2952' };

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: '26px',
  fontWeight: 'normal',
  lineHeight: '32px',
  letterSpacing: 0.5,
  '@media (min-width:600px)': {
    fontSize: '40px',
    lineHeight: '48px',
  },
};
theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: '24px',
  fontWeight: 'normal',
  lineHeight: '32px',
  letterSpacing: 0.5,
  '@media (min-width:600px)': {
    fontSize: '32px',
    lineHeight: '40px',
  },
};
theme.typography.h4 = {
  ...theme.typography.h4,
  fontSize: '22px',
  fontWeight: 'normal',
  lineHeight: '28px',
  letterSpacing: 0.5,
  '@media (min-width:600px)': {
    fontSize: '24px',
    lineHeight: '32px',
  },
};
theme.typography.h5 = {
  ...theme.typography.h5,
  fontSize: '18px',
  fontWeight: 'normal',
  lineHeight: '24px',
  letterSpacing: 0.5,
  '@media (min-width:600px)': {
    fontSize: '20px',
    lineHeight: '30px',
  },
};
theme.typography.bodyLight = {
  ...theme.typography.bodyLight,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: 0,
};
theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '28px',
  letterSpacing: 0,
};
theme.typography.bodyBold = {
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '28px',
  letterSpacing: 0,
};
theme.typography.body2 = {
  // used for small and error
  ...theme.typography.body2,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '14px',
  letterSpacing: 0,
};
theme.typography.button = {
  ...theme.typography.button,
  fontSize: '15px',
  letterSpacing: 0.5,
  textTransform: 'uppercase',
  fontWeight: 600,
};
theme.typography.fontFamily = 'Montserrat, Helvetica, Arial, "Lucida Grande", sans-serif';
theme.typography.fontFaces = [montserratRegular, montserratMedium, montserratSemibold];

export default theme;
