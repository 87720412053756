import { makeStyles } from '@material-ui/core';
import { getProductsPriceAndStock_products_results_masterData_current_allVariants } from 'common/interfaces/generated/getProductsPriceAndStock';
import { getProductImages, getProductName } from 'common/lib/commercetools';
import { isGdamTheme } from 'common/lib/helpers';
import React from 'react';

const CrossSellProductImage = ({
  item,
}: {
  item: getProductsPriceAndStock_products_results_masterData_current_allVariants;
}) => {
  const classes = useStyles();
  const name = getProductName(item);
  const { imageUrlSmall, webpUrlSmall } = getProductImages(item);

  return (
    <picture className={classes.root}>
      <source type="image/webp" srcSet={webpUrlSmall}></source>
      <img className={classes.image} src={imageUrlSmall} alt={name} />
    </picture>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '1/1',
    maxWidth: 156,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: isGdamTheme() ? '#FFFFFF' : theme.palette.grey['300'],
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      height: 160,
      width: 160,
      maxWidth: 'unset',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  image: {
    maxHeight: '100%',
    maxWidth: 120,
  },
}));

export default CrossSellProductImage;
