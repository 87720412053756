import React from 'react';
import WarningIcon from 'common/icons/WarningIcon';
import { Hidden, makeStyles } from '@material-ui/core';
import Txt from './Txt';
import i18n from 'common/providers/i18n';
import Button from './Button';

interface Props {
  errorMessage?: string;
  ctaFn: () => void;
  ctaText: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(14),
    paddingTop: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6),
      paddingTop: theme.spacing(),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  warningIcon: {
    marginBottom: theme.spacing(2),
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  errorText: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    marginBottom: theme.spacing(12),
  },
}));

const ErrorContent = (props: Props): JSX.Element => {
  const classes = useStyles();

  const { errorMessage, ctaFn, ctaText } = props;
  return (
    <div className={classes.root} data-testid="error-content">
      <WarningIcon className={classes.warningIcon} />
      <Hidden smDown>
        <Txt variant="headingTwo" className={classes.header} data-testid="error-header">
          {i18n.t('errors.somethingWentWrong')}
        </Txt>
      </Hidden>
      <Hidden mdUp>
        <Txt variant="headingFour" className={classes.header} data-testid="error-header">
          {i18n.t('errors.somethingWentWrong')}
        </Txt>
      </Hidden>
      <Txt className={classes.errorText} data-testid="error-message">
        {errorMessage}
      </Txt>
      <Button fullWidth data-testid="error-close-button" onClick={ctaFn}>
        {ctaText}
      </Button>
    </div>
  );
};

export default ErrorContent;
