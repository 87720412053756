import * as overrides from './overrides';
import whiteLabelTheme from '../whitelabel/whitelabel-theme';
import MatterLight from 'common/fonts/matter/Matter-Light.otf';
import MatterRegular from 'common/fonts/matter/Matter-Regular.otf';
import MatterSemiBold from 'common/fonts/matter/Matter-SemiBold.otf';
import IvarMedium from 'common/fonts/ivar/IvarDisplay-Medium.otf';

const ivarMedium = {
  fontFamily: 'Ivar',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `url(${IvarMedium})`,
};

const matterLight = {
  fontFamily: 'Matter',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `url(${MatterLight})`,
};

const matterRegular = {
  fontFamily: 'Matter',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${MatterRegular})`,
};

const matterSemiBold = {
  fontFamily: 'Matter',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `url(${MatterSemiBold})`,
};

const theme: any = JSON.parse(JSON.stringify(whiteLabelTheme));

theme.overrides = {
  ...overrides,
  MuiCssBaseline: {
    '@global': {
      html: null,
      body: null,
      '*, *::before, *::after': null,
      '@font-face': [matterLight, matterRegular, matterSemiBold, ivarMedium],
    },
  },
};

theme.palette.button = {
  ...theme.palette.button,
  primary: {
    main: '#262626',
    hover: '#3D3D3D',
    active: '#3D3D3D',
    contrastText: '#F6F6F6',
    disabled: '#EBEBEB',
    inactiveText: '#C2C2C2',
    disabledOpacity: 1,
  },
  secondary: {
    main: '#262626',
    hover: '#262626',
    active: '#EBEBEB',
    contrastText: '#F6F6F6',
    backgroundColorDefault: 'transparent',
    disabled: '#EBEBEB',
    inactiveText: '#C2C2C2',
  },
};

theme.palette.primary = {
  ...theme.palette.primary,
  main: '#262626',
  light: '#D8E6E7',
  contrastText: '#f6f6f6',
};

theme.palette.secondary = {
  ...theme.palette.secondary,
  main: '#262626',
  contrastText: '#D8E6E7',
};

theme.palette.info = {
  ...theme.palette.info,
  main: '#2767C5',
  light: '#3374D7',
};

theme.palette.error = { ...theme.palette.error, main: '#D93F6A' };

theme.palette.text = { ...theme.palette.text, primary: '#262626' };

theme.typography.h2 = {
  ...theme.typography.h2,
  fontFamily: 'Ivar',
  fontWeight: 500,
  fontSize: 24,
  lineHeight: '48px',
  letterSpacing: 0.5,
  '@media (min-width:600px)': {
    fontSize: 40,
    lineHeight: '48px',
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  fontFamily: 'Ivar',
  fontWeight: 500,
  fontSize: 22,
  lineHeight: '28px',
  letterSpacing: 0.5,
  '@media (min-width:600px)': {
    fontSize: 32,
    lineHeight: '40px',
  },
};

theme.typography.h4 = {
  ...theme.typography.h4,
  fontFamily: 'Ivar',
  fontWeight: 500,
  fontSize: 20,
  lineHeight: '28px',
  letterSpacing: 0.5,
  '@media (min-width:600px)': {
    fontSize: 24,
    lineHeight: '32px',
  },
};

theme.typography.h5 = {
  ...theme.typography.h5,
  fontWeight: 400,
  fontSize: 18,
  lineHeight: '24px',
  letterSpacing: 0.5,
  '@media (min-width:600px)': {
    lineHeight: '28px',
  },
};

theme.typography.bodyLight = {
  ...theme.typography.bodyLight,
  fontWeight: 300,
  fontSize: 16,
  lineHeight: '28px',
  letterSpacing: 0,
};

theme.typography.body1 = {
  ...theme.typography.body1,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: 0,
  '@media (min-width:600px)': {
    lineHeight: '28px',
  },
};

theme.typography.bodyBold = {
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: 0,
  '@media (min-width:600px)': {
    lineHeight: '28px',
  },
};

theme.typography.body2 = {
  // used for small and error
  ...theme.typography.body2,
  fontWeight: 400,
  fontSize: 13,
  lineHeight: '16px',
  letterSpacing: 0.5,
};

theme.typography.button = {
  ...theme.typography.button,
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '16px',
  textTransform: 'uppercase',
  letterSpacing: 1,
};

theme.typography.fontFamily = 'Matter, Helvetica, Arial, "Lucida Grande", sans-serif';
theme.typography.fontFaces = [matterLight, matterRegular, matterSemiBold, ivarMedium];

export default theme;
