import React, { useRef, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import CloseIcon from 'common/icons/CloseIcon';
import { getActiveCart_inStore_me_activeCart_lineItems } from 'common/interfaces/generated/getActiveCart';
import { UPDATE_ACTIVE_CART } from 'common/queries';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { updateActiveCart } from 'common/interfaces/generated/updateActiveCart';
import LineItemCardImage from 'common/components/LineItemCard/LineItemCardImage';
import LineItemCardName from 'common/components/LineItemCard/LineItemCardName';
import clsx from 'clsx';
import getConfig from 'config';
import LineItemCardPrice from 'common/components/LineItemCard/LineItemCardPrice';
import Rocker from 'common/components/LineItemCard/Rocker';
import { analytics } from 'common/analytics/actions';
import { useCart } from 'common/hooks/useCart';
import { useAddToCart } from 'common/hooks/useAddToCart';
import { getAnalyticsInfoFromLineItems } from 'common/lib/cart';
import { useProductPriceAndStock } from 'common/hooks/useProduct';
import { makeOptimisticResponseForLineItems } from 'common/lib/optimistic/cart';
import { getProductBrand } from 'common/lib/commercetools';
import Txt from '../Txt';
import LineItemCardExtraDescription from './LineItemCardExtraDescription';
import { buildProductPageUrl, isGdamTheme } from 'common/lib/helpers';
import { useUpdateActiveCart } from 'common/hooks/useUpdateActiveCart';

interface Props {
  lineItem: getActiveCart_inStore_me_activeCart_lineItems;
  className?: string;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      marginBottom: theme.spacing(5),
    },
    inner: {
      position: 'relative',
      display: 'flex',
      padding: theme.spacing(2),
      transition: 'background-color 200ms ease',
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(3),
      },
    },
    innerHover: {
      '&:hover': {
        backgroundColor: isGdamTheme() ? '#FFFFFF' : theme.palette.grey['300'],
        '& .MuiLineItemCardImage-hoverable img': {
          transform: 'scale(1)',
        },
      },
    },
    topLine: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
      marginBottom: theme.spacing(1),
    },
    productDetails: {
      flexGrow: 1,
      marginLeft: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    price: {
      justifySelf: 'flex-end',
      marginRight: theme.spacing(7),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2),
      },
      fontWeight: 'bold',
      marginLeft: 'auto',
    },
    removeButton: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      height: 42,
      width: 42,
      flex: '0 0 auto',
      padding: 0,
      backgroundColor: 'transparent',
      border: 'none',
    },
    removeIcon: {
      cursor: 'pointer',
      marginTop: 3,
      flexShrink: 0,
      zIndex: 2,
    },
    middleLine: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    bottomLine: {
      marginTop: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    link: {
      color: 'inherit',
      textDecoration: 'none',
      '&::after': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        pointerEvents: 'auto',
        content: '""',
        backgroundColor: 'rgba(0,0,0,0)',
      },
    },
  }),
  { name: 'MuiEditableLineItemCard' }
);

const EditableLineItemCard = (props: Props): JSX.Element => {
  const { cart } = useCart();
  const { lineItem, className } = props;
  const classes = useStyles();
  const liRef = useRef(null);
  const theme = useTheme();
  const [componentWidth, setComponentWidth] = useState(500);
  const isSmall = componentWidth < theme.breakpoints.values.sm;
  const linkToPDP = getConfig().features.displayLinkToPDP;
  const sku = lineItem.variant?.sku || '';
  const locale = getConfig().locale;
  const productSlug = lineItem.productSlugAllLocales?.find((slug) => slug.locale === locale)?.value || '';
  const productPageUrl = buildProductPageUrl(locale, productSlug, sku);
  const updateActiveCart = useUpdateActiveCart();

  const { available } = useProductPriceAndStock({
    sku: lineItem.variant?.sku || '',
    cart,
  });

  useEffect(() => {
    // @ts-ignore
    if (liRef?.current?.offsetWidth) {
      // @ts-ignore;
      setComponentWidth(liRef?.current?.offsetWidth);
    }
  }, [liRef.current]);

  const addToCart = useAddToCart({
    sku: lineItem.variant?.sku || '',
    extraDescription: lineItem.custom?.customFieldsRaw?.find((e) => e.name === 'extraDescription')?.value,
  });

  const removeLineItem = (quantity: number) => {
    const optimisticLineItems = cart!.lineItems.reduce((items, item) => {
      if (lineItem.id === item.id) {
        if (quantity === 1 && item.quantity > 1) {
          return [...items, { ...item, quantity: item.quantity - 1 }];
        }
        return [...items];
      }
      return [...items, item];
    }, [] as getActiveCart_inStore_me_activeCart_lineItems[]);

    const optimisticCart = makeOptimisticResponseForLineItems({ ...cart!, lineItems: optimisticLineItems });

    const update = async () => {
      await updateActiveCart({
        cart: cart!,
        actions: [
          {
            removeLineItem: {
              lineItemId: lineItem.id,
              quantity,
            },
          },
        ],
        optimisticResponse: optimisticCart as unknown as updateActiveCart,
        checkShipingMethod: true,
      });

      analytics.removeLineItem(
        cart!.id,
        getAnalyticsInfoFromLineItems([{ ...lineItem }]).map((li) => {
          li.quantity = quantity;
          return li;
        })
      );
    };
    update();
  };

  const brand = getConfig().features.displayProductBrand ? getProductBrand(lineItem.variant) : undefined;
  const price = isSmall ? undefined : <LineItemCardPrice className={classes.price} lineItem={lineItem} />;
  const closeButton = (
    <button type="button" className={classes.removeButton}>
      <CloseIcon
        data-testid="remove-line-item"
        className={classes.removeIcon}
        onClick={() => removeLineItem(lineItem.quantity)}
      />
    </button>
  );

  return (
    <li ref={liRef} className={clsx(classes.root, className)} data-testid="line-item-card">
      <div className={clsx(classes.inner, linkToPDP && classes.innerHover)}>
        {linkToPDP && productPageUrl ? (
          <a href={productPageUrl} className={classes.link}>
            <LineItemCardImage lineItem={lineItem} hoverable={true} />
          </a>
        ) : (
          <LineItemCardImage lineItem={lineItem} />
        )}
        <div className={classes.productDetails}>
          {brand && (
            <div className={classes.topLine}>
              <Txt data-testid="line-item-product-brand" fontWeight="bold">
                {brand}
              </Txt>
              {price}
              {closeButton}
            </div>
          )}
          <div className={classes.topLine}>
            <LineItemCardName lineItem={lineItem} />
            {!brand && price}
            {!brand && closeButton}
          </div>
          <div className={classes.middleLine}>
            <LineItemCardExtraDescription lineItem={lineItem} />
          </div>
          <div className={classes.bottomLine}>
            {isSmall && <LineItemCardPrice lineItem={lineItem} />}
            <Rocker
              alignRight={isSmall}
              quantity={lineItem.quantity}
              removeDisabled={lineItem.quantity === 1}
              onRemove={() => {
                if (lineItem.quantity > 1) {
                  removeLineItem(1);
                }
              }}
              addDisabled={!available}
              onAdd={() => {
                addToCart();
              }}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default EditableLineItemCard;
