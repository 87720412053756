import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ValidIcon = ({ className }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <svg
      className={clsx(classes.root, className)}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="validIcon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47594 0 0 4.47715 0 10C0 15.5241 4.47715 20 10 20C15.5241 20 20 15.5228 20 10C20 4.47594 15.5228 0 10 0ZM8.51357 14.2059C8.47399 14.2455 8.42954 14.2773 8.38214 14.3012C8.18983 14.3992 7.94859 14.3677 7.78662 14.2057L4.25303 10.6721C4.05244 10.4716 4.05269 10.1461 4.25207 9.9467L4.61545 9.58332C4.81551 9.38326 5.13948 9.38288 5.34089 9.58429L8.15021 12.3936L14.4961 6.04775C14.6965 5.84728 15.0219 5.84763 15.2213 6.04701L15.5847 6.41038C15.7847 6.61044 15.7841 6.93546 15.5839 7.1356L8.51357 14.2059Z"
        fill="#1CE782"
      />
    </svg>
  );
};

export default ValidIcon;
