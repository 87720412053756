import { Hidden, makeStyles } from '@material-ui/core';
import { analytics } from 'common/analytics/actions';
import Button from 'common/components/Button';
import CrossSellAddOn from 'common/components/CrossSellAddOn/CrossSellAddOn';
import ItemCount from 'common/components/ItemCount';
import Loader from 'common/components/Loader';
import OrderSummary from 'common/components/OrderSummary/OrderSummary';
import OrderSummaryPromoCode from 'common/components/OrderSummary/OrderSummaryPromoCode';
import OrderSummaryTotals from 'common/components/OrderSummary/OrderSummaryTotals';
import Sticky from 'common/components/Sticky';
import Txt from 'common/components/Txt';
import VerticalDivider from 'common/components/VerticalDivider';
import { useCart } from 'common/hooks/useCart';
import { useCheckPrices } from 'common/hooks/useCheckPrices';
import { getAnalyticsInfoFromLineItems } from 'common/lib/cart';
import { redirect } from 'common/lib/helpers';
import i18n from 'common/providers/i18n';
import Providers from 'common/providers/Providers';
import getConfig from 'config';
import React, { useEffect, useState } from 'react';
import EmptyCartPage from '../../common/components/EmptyCartPage';
import EditableLineItemCard from '../../common/components/LineItemCard/EditableLineItemCard';
import LineItemCards from '../../common/components/LineItemCard/LineItemCards';
import NeedHelp from './components/NeedHelp';
import ShippingAndReturnsPolicy from './components/ShippingAndReturnPolicy';

export interface Props {}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 570 + 150 + 450,
    minHeight: 596,
    margin: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  basketContents: {
    maxWidth: 570,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      padding: theme.spacing(4),
    },
  },
  header: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: theme.spacing(5),
    },
  },
  itemCount: {
    marginLeft: theme.spacing(4),
  },
  sticky: {
    marginLeft: theme.spacing(3),
    width: 450,
    flexShrink: 0,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 450,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

export const CartPage = (props: Props): JSX.Element | null => {
  const { cart, loading } = useCart();
  const [calledViewElement, setCalledViewElement] = useState(false);
  const classes = useStyles();
  const checkPrices = useCheckPrices();

  useEffect(() => {
    if (cart && !calledViewElement) {
      checkPrices();
      analytics.viewElement(cart.id, 'basketPage', getAnalyticsInfoFromLineItems(cart.lineItems));
      setCalledViewElement(true);
    }
  }, [cart, calledViewElement]);

  if (!cart?.lineItems.length && !loading) {
    return (
      <main className={classes.root}>
        <EmptyCartPage
          action={() => {
            redirect(getConfig().urls.catalog);
          }}
        />
      </main>
    );
  }

  return (
    <main className={classes.root} data-testid="cart-page">
      {loading ? (
        <Loader />
      ) : (
        <>
          <section className={classes.basketContents}>
            <header className={classes.header}>
              <Txt variant="headingFour">{i18n.t('MyBasket')}</Txt>
              <Hidden lgUp>
                <ItemCount className={classes.itemCount} />
              </Hidden>
            </header>
            <Hidden mdDown>
              <LineItemCards component={EditableLineItemCard} variant="cart" />
            </Hidden>
            <Hidden lgUp>
              <LineItemCards maxHeight={250} component={EditableLineItemCard} variant="cart" />
              <OrderSummaryTotals />
              <GoToCheckout />
              <OrderSummaryPromoCode />
              <footer className={classes.footer}>
                <ShippingAndReturnsPolicy />
                <Hidden smDown>
                  <VerticalDivider spacing={3} margin={5} />
                </Hidden>

                <NeedHelp />
              </footer>
            </Hidden>
          </section>
          <Hidden mdDown>
            <Sticky className={classes.sticky}>
              <OrderSummary>
                <OrderSummaryTotals />
                <GoToCheckout />
                <OrderSummaryPromoCode />
              </OrderSummary>
              <footer className={classes.footer}>
                <ShippingAndReturnsPolicy />
                <VerticalDivider spacing={3} margin={5} />
                <NeedHelp />
              </footer>
            </Sticky>
          </Hidden>
          <Hidden lgUp>
            <CrossSellAddOn useFirstProductInBasket={true} />
          </Hidden>
        </>
      )}
    </main>
  );
};

const ProvidedCartPage = (props: Props) => {
  return (
    <Providers>
      <CartPage {...props} />
    </Providers>
  );
};

const GoToCheckout = (props: Props) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      fullWidth
      onClick={() => {
        redirect(getConfig().urls.checkout);
      }}
      data-testid="go-to-checkout-button"
    >
      {i18n.t('GoToCheckout')}
    </Button>
  );
};

export default ProvidedCartPage;
