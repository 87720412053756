import React from 'react';
import Txt from 'common/components/Txt';
import i18n from 'common/providers/i18n';
import getConfig from 'config';

interface Props {}

const ShippingAndReturnsPolicy = (props: Props): JSX.Element => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={getConfig().urls.shippingAndReturns}>
      <Txt variant="link">{i18n.t('ShippingAndReturnsPolicy')}</Txt>
    </a>
  );
};

export default ShippingAndReturnsPolicy;
