import React, { FC, ChangeEvent, HTMLProps } from 'react';
import { makeStyles } from '@material-ui/core';
import Txt from '../Txt';
import clsx from 'clsx';
import { FormikErrors } from 'formik';
import i18n from 'common/providers/i18n';
import ValidIcon from 'common/icons/ValidIcon';
import InvalidIcon from 'common/icons/InvalidIcon';

interface Props {
  label?: string;
  contextLabel?: string;
  valid?: boolean;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  required?: boolean;
  className?: string;
  errorClassName?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  children: JSX.Element;
  icon?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 450,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  children: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    textTransform: 'none',
  },
  labels: {
    lineHeight: theme.typography.body2.lineHeight,
  },
  icon: {
    height: theme.typography.body1.lineHeight,
    position: 'absolute',
    right: theme.spacing(3),
  },
  valid: {
    stroke: theme.palette.error.light,
  },
  invalid: {
    stroke: theme.palette.error.main,
  },
  error: {
    display: 'block',
    marginTop: theme.spacing(),
    lineHeight: theme.typography.body2.lineHeight,
    minHeight: theme.typography.body2.lineHeight,
    marginBottom: theme.spacing(2),
  },
  optional: {
    opacity: 0.5,
  },
  contextLabel: {
    display: 'block',
    marginTop: theme.spacing(),
  },
}));

const FormField: FC<Props & HTMLProps<HTMLLabelElement>> = (props) => {
  const { className, label, contextLabel, valid, error, required, children, errorClassName, icon, ...rest } = props;
  const classes = useStyles();
  return (
    <label className={classes.root} {...rest}>
      <span className={clsx(classes.label, className)}>
        <span className={classes.labels}>
          <Txt variant="small">{label}</Txt>
          <Txt variant="small" className={classes.optional} color="secondary">
            {!required && ` (${i18n.t('Optional')})`}
          </Txt>
        </span>
      </span>
      <div className={classes.children}>
        {children}
        {valid === true && <ValidIcon className={clsx(classes.icon)} />}
        {valid === false && <InvalidIcon className={clsx(classes.icon)} />}
        {valid === undefined && icon}
      </div>
      {contextLabel && (
        <Txt variant="small" className={classes.contextLabel}>
          {contextLabel}
        </Txt>
      )}
      <Txt variant="error" className={clsx(classes.error, errorClassName)} data-testid="validation-error">
        {error as string}
      </Txt>
    </label>
  );
};

export default FormField;
