import React from 'react';

interface Props {
  className?: string;
}

const SearchIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6368 8.07873C18.5834 10.8588 17.9078 14.6906 15.1277 16.6372C12.3476 18.5839 8.51588 17.9082 6.56924 15.1281C4.62261 12.348 5.29825 8.51628 8.07833 6.56964C10.8584 4.62301 14.6902 5.29865 16.6368 8.07873Z"
        stroke="#4D4D4D"
        strokeWidth="1.5"
      />
      <path d="M15.5566 17.2515L20.1706 23.841" stroke="#4D4D4D" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default SearchIcon;
