import React from 'react';
import Checkbox from 'common/components/formComponents/Checkbox';
import Txt from 'common/components/Txt';
import { useCart } from 'common/hooks/useCart';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { UPDATE_ACTIVE_CART } from 'common/queries';
import i18n from 'common/providers/i18n';
import getConfig from 'config';
import { analytics } from 'common/analytics/actions';
import { makeOptimisticResponseForMarketingOptIn } from 'common/lib/optimistic/cart';

interface Props {
  className?: string;
}

const MarketingOptIn = (props: Props): JSX.Element => {
  const { className } = props;
  const { cart } = useCart();
  const { custom, id: cartId } = cart!;
  const [updateCart] = useGraphqlMutation(UPDATE_ACTIVE_CART);

  const isOptIn = custom?.customFieldsRaw?.find((e) => e.name === 'marketingConsent')?.value === true;

  return (
    <Checkbox
      data-testid="marketing-opt-in"
      className={className}
      checked={isOptIn}
      onChange={async () => {
        await updateCart({
          variables: {
            actions: [
              {
                setCustomField: {
                  name: 'marketingConsent',
                  value: isOptIn ? 'false' : 'true',
                },
              },
            ],
          },
          optimisticResponse: makeOptimisticResponseForMarketingOptIn(cart!, !isOptIn),
        });
        // inverse since it takes the value from before execution
        await analytics.setMarketingOptIn(cartId, !isOptIn);
      }}
      id="marketingOptIn"
    >
      <span>
        <Txt data-testid="marketing-opt-in-text" fontWeight="light">
          {i18n.t('marketingOptInText', { brand: getConfig().brandName })}
        </Txt>{' '}
        {/* space here on purpose */}
        <a
          target="_blank"
          rel="noopener noreferrer"
          data-testid="marketing-opt-in-link"
          href={getConfig().urls.privacyPolicy}
        >
          <Txt variant="link">{i18n.t('PrivacyPolicy')}</Txt>
        </a>
      </span>
    </Checkbox>
  );
};

export default MarketingOptIn;
