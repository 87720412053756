import React, { forwardRef } from 'react';
import Txt from 'common/components/Txt';
import { makeStyles } from '@material-ui/core';
import i18n from 'common/providers/i18n';
import clsx from 'clsx';
import ItemCount from '../ItemCount';

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.spacing(6),
    maxWidth: 450,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    maxHeight: '100%',
    boxShadow: theme.shadows[1],
    backgroundColor: '#ffffff',
    overflow: 'auto',
  },
  header: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'end',
  },
  headerText: {
    color: theme.palette.secondary.contrastText,
    marginRight: theme.spacing(2),
  },
  content: {
    paddingTop: theme.spacing(7),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(10),
  },
}));

const OrderSummary = forwardRef(
  (props: Props, ref: any): JSX.Element => {
    const { className, children, ...rest } = props;
    const classes = useStyles();

    return (
      <aside className={clsx(classes.root, className)} data-testid="order-summary" {...rest} ref={ref}>
        <header className={classes.header}>
          <Txt variant="headingFour" className={classes.headerText} data-testid="order-summary-label">
            {i18n.t('OrderSummary')}
          </Txt>
          <ItemCount className={classes.headerText} />
        </header>
        <div className={classes.content}>{children}</div>
      </aside>
    );
  }
);

OrderSummary.displayName = 'OrderSummary';

export default OrderSummary;
