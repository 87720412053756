import React, { useState, useEffect } from 'react';
import Select from './Select';
import { getStates } from 'common/lib/states';

interface Props {
  value: string | undefined;
  onChange: (value: string) => void;
  id: string;
  country: string;
}

const StateSelect = (props: Props): JSX.Element => {
  const { value, onChange, id, country } = props;
  const options = getStates(country).map((c) => ({ value: c.code, label: c.name }));
  const [selected, setSelected] = useState(options.find((e) => e.value === value));

  useEffect(() => {
    setSelected(options.find((e) => e.value === value));
  }, [country, value]);

  return (
    <Select
      options={options}
      onChange={(opt: any) => {
        onChange(opt.value);
        setSelected(options.find((e) => e.value === opt.value));
      }}
      value={selected}
      id={id}
      disabled={options.length === 1}
    />
  );
};

export default StateSelect;
