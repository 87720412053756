import React from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
  height?: string;
  width?: string;
  color?: string;
  strokeWidth?: string;
}

const CloseIcon = ({ className, onClick, height, width, color, strokeWidth, ...rest }: Props): JSX.Element => {
  return (
    <svg
      {...rest}
      onClick={() => onClick && onClick()}
      className={className}
      width={width || '23'}
      height={height || '24'}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.65735 6.64087L16.3756 17.3591"
        stroke={color || '#6D6D6D'}
        strokeWidth={strokeWidth || '1.4'}
        strokeLinecap="square"
      />
      <path
        d="M16.375 6.64083L5.65671 17.3591"
        stroke={color || '#6D6D6D'}
        strokeWidth={strokeWidth || '1.4'}
        strokeLinecap="square"
      />
    </svg>
  );
};

export default CloseIcon;
