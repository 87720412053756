import { googlePayment } from 'braintree-web';
import { analytics, PaymentMethod } from 'common/analytics/actions';
import { useCart } from 'common/hooks/useCart';
import { useErrorDialog } from 'common/hooks/useErrorDialog';
import useUpdateBillingAddress from 'common/hooks/useUpdateBillingAddress';
import i18n from 'common/providers/i18n';
import getConfig from 'config';
import { useLoading } from 'modules/Checkout/Checkout';
import React, { useEffect, useState } from 'react';
import { Clients, useSubmitNonce } from '../BraintreeHelpers';
import useUpdateEmailShippingBilling from 'common/hooks/useUpdateEmailShippingBilling';

interface Props {
  className?: string;
  clients?: Clients;
  variant: 'quickCheckout' | 'fullCheckout';
}
const GooglePayButton = (props: Props): JSX.Element => {
  const { className, clients, variant } = props;
  const { cart } = useCart();
  const { setLoading } = useLoading();
  const errorDialog = useErrorDialog();
  const updateBillingAddress = useUpdateBillingAddress();
  const setEmailShippingBilling = useUpdateEmailShippingBilling();

  const submitNonce = useSubmitNonce();
  const [braintreeGoogleClient, setBraintreeGoogleClient] = useState<any | undefined>(undefined);
  const [googleClient, setGoogleClient] = useState<any | undefined>(undefined);
  const isQuickCheckout = variant === 'quickCheckout';

  useEffect(() => {
    // TODO: Shouldn't we also return when googleMerchantId is not set?
    if (!clients || braintreeGoogleClient) return;

    const init = async () => {
      const btGoogleClient = await googlePayment.create({
        client: clients?.braintreeClient,
        googlePayVersion: 2,
        googleMerchantId: getConfig().googleMerchantId,
      });
      const googClient = new google.payments.api.PaymentsClient({
        environment: getConfig().paymentEnvironment.toUpperCase() as 'TEST' | 'PRODUCTION',
      });
      const paymentRequest = await btGoogleClient.createPaymentDataRequest();

      const res = await googClient.isReadyToPay({
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: paymentRequest.allowedPaymentMethods,
        existingPaymentMethodRequired: true,
      });

      if (res.result) {
        setBraintreeGoogleClient(btGoogleClient);
        setGoogleClient(googClient);
      }
    };

    init();
  }, [clients, braintreeGoogleClient]);

  useEffect(() => {
    if (!braintreeGoogleClient || !googleClient) return;

    const onClick = async (event: any) => {
      event.preventDefault();
      setLoading(true);

      const paymentRequestData = braintreeGoogleClient.createPaymentDataRequest({
        transactionInfo: {
          currencyCode: getConfig().currency,
          totalPriceStatus: 'FINAL',
          totalPrice: (cart!.taxedPrice?.totalGross.centAmount / 100).toString(),
        },
      });

      if (!paymentRequestData.allowedPaymentMethods.length) {
        errorDialog(i18n.t('errors.tryDifferentPaymentMethod'));
        return;
      }

      const cardPaymentMethod = paymentRequestData.allowedPaymentMethods[0];
      cardPaymentMethod.parameters.billingAddressRequired = true;
      cardPaymentMethod.parameters.billingAddressParameters = {
        format: 'FULL',
        phoneNumberRequired: false,
      };
      paymentRequestData.shippingAddressRequired = isQuickCheckout;
      paymentRequestData.emailRequired = isQuickCheckout;

      let paymentData;
      let googleBillingAddress;
      try {
        paymentData = await googleClient.loadPaymentData(paymentRequestData);
        googleBillingAddress = paymentData.paymentMethodData.info.billingAddress;
      } catch (err) {
        console.error(err);
        setLoading(false);
        return;
      }

      const billingAddress = {
        firstName: googleBillingAddress.name,
        streetName: googleBillingAddress.address1,
        additionalStreetInfo: googleBillingAddress.address2,
        postalCode: googleBillingAddress.postalCode,
        city: googleBillingAddress.locality,
        country: googleBillingAddress.countryCode,
      };

      let data;
      if (isQuickCheckout) {
        const googleShippingAddress = paymentData.shippingAddress;
        const shippingAddress = {
          firstName: googleShippingAddress.name,
          streetName: googleShippingAddress.address1,
          additionalStreetInfo: googleShippingAddress.address2,
          postalCode: googleShippingAddress.postalCode,
          city: googleShippingAddress.locality,
          country: googleShippingAddress.countryCode,
        };
        const email = paymentData.email;
        const shippingCountry = shippingAddress.country.toUpperCase();
        if (!getConfig().supportedCountries.includes(shippingCountry)) {
          setLoading(false);
          errorDialog(
            i18n.t('errors.noShippingForCountry', {
              shippingCountries: getConfig()
                .supportedCountries.map((country) => i18n.t(`countriesLong.${country}`))
                .join(', '),
            })
          );
          return;
        }

        data = await setEmailShippingBilling(email, shippingAddress, billingAddress);
      } else {
        data = await updateBillingAddress(billingAddress);
      }

      try {
        const result = await braintreeGoogleClient.parseResponse(paymentData);
        if (data?.updateMyCart) {
          analytics.pay(data.updateMyCart.id, PaymentMethod.GOOGLE_PAY, variant);
          submitNonce(
            result.nonce,
            clients,
            data.updateMyCart,
            data.updateMyCart.version,
            data.updateMyCart.billingAddress
          );
        }
      } catch (err) {
        setLoading(false);
        errorDialog(i18n.t('errors.tryAgain'));
        console.error(err);
      }
    };

    const container = document.getElementById('google-pay-container');
    while (container?.firstChild) {
      container.removeChild(container.firstChild);
    }
    const button = googleClient.createButton({
      onClick,
      buttonColor: 'black',
      buttonLocale: getConfig().locale.split('-')[0],
    });

    document.getElementById('google-pay-container')?.appendChild(button);
  }, [braintreeGoogleClient, googleClient, cart]);

  return <div className={className} id="google-pay-container"></div>;
};

export default GooglePayButton;
