import { useApolloClient } from '@apollo/client';
import { getActiveCart_inStore_me_activeCart } from 'common/interfaces/generated/getActiveCart';
import { getProductsStock } from 'common/interfaces/generated/getProductsStock';
import { MyCartUpdateAction, SnackbarType } from 'common/interfaces/generated/globalTypes';
import { getProductName, productHasStock } from 'common/lib/commercetools';
import i18n from 'common/providers/i18n';
import { GET_PRODUCTS_STOCK, UPDATE_ACTIVE_CART } from 'common/queries';
import { useEffect, useRef, useState } from 'react';
import { useCart } from './useCart';
import { useChannels } from './useChannels';
import useGraphqlMutation from './useGraphlMutation';
import useSnackbar from './useSnackbar';
import getConfig from 'config';

export const useHandleSupplyChannelChange = () => {
  const { cart, isNewVersion: cartIsNewVersion } = useCart();
  const {
    channels: { supplyChannelId },
  } = useChannels();
  const [updateCart] = useGraphqlMutation(UPDATE_ACTIVE_CART);
  const setSnackbar = useSnackbar();
  const client = useApolloClient();
  const [isValidSupplyChannel, setIsValidSupplyChannel] = useState(false);

  const prevSupplyChannelId = useRef<string | undefined>(undefined);

  interface OutOfStockInfo {
    sku: string;
    name: string;
  }

  const switchCart = (cart: getActiveCart_inStore_me_activeCart, outOfStock: OutOfStockInfo[]) => {
    const outOfStockSkus = outOfStock.map((info) => info.sku);
    console.log(
      'Will switch supplyChannels. Following products will get removed (out of stock):' +
        outOfStock.map((item) => `${item.name} (${item.sku})`).join(', ')
    );

    updateCart({
      variables: {
        actions: cart.lineItems
          .map((line) => {
            console.log(`Switching from ${line.supplyChannel?.id} to ${supplyChannelId} for ${line.variant?.sku}`);

            let actions: MyCartUpdateAction[] = [
              {
                removeLineItem: {
                  lineItemId: line.id,
                },
              },
            ];
            if (!outOfStockSkus.includes(line.variant?.sku!)) {
              actions.push({
                addLineItem: {
                  sku: line.variant?.sku,
                  quantity: line.quantity,
                  supplyChannel: {
                    id: supplyChannelId,
                  },
                  distributionChannel: {
                    id: line.distributionChannel?.id,
                  },
                },
              });
            }
            return actions;
          })
          .flat(),
      },
    }).then(() => {
      setSnackbar({ type: SnackbarType.INFO, message: i18n.t('CartChangedError') });
      setIsValidSupplyChannel(true);
    });
  };

  useEffect(() => {
    if (!cart) {
      setIsValidSupplyChannel(false);
      return;
    }

    let channelChanged = false;

    if (cartIsNewVersion) {
      // Only if the cart is from a new version, we know the update
      // comes from the server and we're interested in any changes
      channelChanged = !!prevSupplyChannelId.current && supplyChannelId !== prevSupplyChannelId.current;
      prevSupplyChannelId.current = supplyChannelId;
    }

    if (!channelChanged) {
      setIsValidSupplyChannel(!!supplyChannelId);
      return;
    }

    console.log(`Supply channel changed to ${supplyChannelId}; will re-add all lines if stock is available`);

    if (!supplyChannelId) {
      // TODO: We should have better handling for this.
      // Some implementation ideas:
      // - form validation on the state field should fail (is this possible with the current setup?)
      // - updating the state on the shippingAddress should be rolled back (or set to an empty string)
      // - we should allow it but let the error come from setting the shipping method
      // For now, set some state and use that to determine if we can continue...
      setSnackbar({
        type: SnackbarType.ERROR,
        message: i18n.t(getConfig().textKeys.noSupplyChannelError || 'errors.noSupplyChannel'),
      });
      setIsValidSupplyChannel(false);
      return;
    }

    client
      .query<getProductsStock>({
        query: GET_PRODUCTS_STOCK,
        variables: {
          skus: cart.lineItems.map((line) => line.variant?.sku!),
          supplyChannelId: supplyChannelId,
          locale: getConfig().locale,
        },
      })
      .then(({ data }) => {
        const outOfStock = data.products.results
          .map((product) => ({
            productData: product.masterData.current!,
            variant: product.masterData?.current?.allVariants[0]!,
          }))
          .filter(({ variant }) => variant.sku && !productHasStock(variant))
          .map(({ productData, variant }) => ({
            sku: variant.sku!,
            name: getProductName(variant) ?? productData.name,
          }));

        switchCart(cart, outOfStock);
      });
  }, [cart?.version, supplyChannelId]);

  return isValidSupplyChannel;
};
