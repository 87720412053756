import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  color?: string;
  margin?: string | number;
  spacing?: number;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: (props: Props) => ({
    marginLeft: theme.spacing(props.spacing || 1),
    marginRight: theme.spacing(props.spacing || 1),
    borderBottom: `1px solid ${props.color || theme.palette.text.primary}`,
    width: theme.spacing(1),
    marginTop: props.margin || 'auto',
    marginBottom: props.margin || 'auto',
  }),
}));

const HorizontalDivider = (props: Props): JSX.Element => {
  const { className } = props;
  const classes = useStyles(props);
  return <div className={clsx(classes.root, className)}></div>;
};

export default HorizontalDivider;
