import { BaseConfig } from './index';
import baseConfig from './base-config';

const config: BaseConfig = {
  ...baseConfig,
  authHost: 'https://auth.us-central1.gcp.commercetools.com',
  host: 'https://api.us-central1.gcp.commercetools.com',
  features: {
    ...baseConfig.features,
    externalTax: true,
    displayTaxes: 'BREAKDOWN',
    displayProductBrand: true,
    use3DS: false,
    allowPOBoxes: false,
  },
  textKeys: {
    findRetailer: 'findRetailer.gdam',
    // In the case of GDAM; most probably when a supply channel cannot be found is that the
    // given state is not supported. To give a more user-friendly error message, just inform the user
    // that the state is not supported
    noSupplyChannelError: 'validation.stateNotSupported',
  },
};

export default config;
