import React from 'react';
import Select from './Select';
import i18n from 'common/providers/i18n';
import { getCountries } from 'common/lib/countries';
import getConfig from 'config';

interface Props {
  value: string;
  onChange: (value: string) => void;
  id?: string;
  name: string;
  allCountries: boolean;
}

const CountrySelect = (props: Props): JSX.Element => {
  const { value, onChange, id, name, allCountries } = props;

  let options: any[] = getConfig().supportedCountries.map((country) => ({
    value: country,
    label: i18n.t(`countries.${country}`),
  }));
  let selected = options.find((e) => e.value === value);

  const allOptions = allCountries ? getCountries().map((c) => ({ value: c.iso2, label: c.name })) : undefined;

  if (allOptions) {
    selected = selected || allOptions.find((e) => e.value === value);
    options =
      options.length > 0
        ? [
            {
              label: '',
              options: options,
            },
            {
              label: i18n.t('countriesGroupLabelAll'),
              options: allOptions,
            },
          ]
        : allOptions;
  }

  return (
    <Select
      options={options}
      onChange={(opt: any) => {
        onChange(opt.value);
      }}
      value={selected}
      id={id}
      name={name}
      disabled={options.length === 1}
    />
  );
};

export default CountrySelect;
