import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import LineItemCardImage from 'common/components/LineItemCard/LineItemCardImage';
import LineItemCardName from 'common/components/LineItemCard/LineItemCardName';
import { getActiveCart_inStore_me_activeCart_lineItems } from 'common/interfaces/generated/getActiveCart';
import { buildProductPageUrl, isGdamTheme } from 'common/lib/helpers';
import i18n from 'common/providers/i18n';
import getConfig from 'config';
import React from 'react';
import LineItemCardBrand from '../LineItemCard/LineItemCardBrand';
import LineItemCardExtraDescription from '../LineItemCard/LineItemCardExtraDescription';
import LineItemCardPrice from '../LineItemCard/LineItemCardPrice';
import Txt from '../Txt';

interface Props {
  lineItem: getActiveCart_inStore_me_activeCart_lineItems;
  className?: string;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 570,
      width: '100%',
      marginBottom: theme.spacing(5),
    },
    inner: {
      position: 'relative',
      display: 'flex',
      padding: theme.spacing(3),
      transition: 'background-color 200ms ease',
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(2),
      },
    },
    innerHover: {
      '&:hover': {
        backgroundColor: isGdamTheme() ? '#FFFFFF' : theme.palette.grey['300'],
        '& .MuiLineItemCardImage-hoverable img': {
          transform: 'scale(1)',
        },
      },
    },
    image: {
      flexShrink: 0,
    },
    topLine: {
      marginBottom: theme.spacing(3),
    },
    productDetails: {
      flexGrow: 1,
      marginLeft: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    middleLine: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(3),
    },
    bottomLine: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    quantity: {
      marginLeft: 'auto',
      textOverflow: 'no-wrap',
    },
    link: {
      color: 'inherit',
      textDecoration: 'none',
      '&::after': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        pointerEvents: 'auto',
        content: '""',
        backgroundColor: 'rgba(0,0,0,0)',
      },
    },
  }),
  { name: 'MuiOrderSummaryLineItemCard' }
);

const OrderSummaryLineItemCard = (props: Props): JSX.Element => {
  const { lineItem, className } = props;
  const classes = useStyles();
  const linkToPDP = getConfig().features.displayLinkToPDP;
  const sku = lineItem.variant?.sku || '';
  const locale = getConfig().locale;
  const productSlug = lineItem.productSlugAllLocales?.find((slug) => slug.locale === locale)?.value || '';
  const productPageUrl = buildProductPageUrl(locale, productSlug, sku);

  return (
    <li className={clsx(classes.root, className)} data-testid="order-summary-products-line-item-card">
      <div className={clsx(classes.inner, linkToPDP && classes.innerHover)}>
        <div className={classes.image}>
          {linkToPDP && productPageUrl ? (
            <a href={productPageUrl} className={classes.link}>
              <LineItemCardImage lineItem={lineItem} hoverable={true} />
            </a>
          ) : (
            <LineItemCardImage lineItem={lineItem} />
          )}
        </div>

        <div className={classes.productDetails}>
          {getConfig().features.displayProductBrand && (
            <div className={classes.topLine}>
              <LineItemCardBrand lineItem={lineItem} />
            </div>
          )}
          <div className={classes.topLine}>
            <LineItemCardName lineItem={lineItem} />
          </div>
          <div className={classes.middleLine}>
            <LineItemCardExtraDescription lineItem={lineItem} />
          </div>
          <div className={classes.bottomLine}>
            <LineItemCardPrice lineItem={lineItem} />
            <Txt className={classes.quantity} data-testid="order-summary-line-item-quantity">
              {i18n.t('Qty')}: {lineItem.quantity}
            </Txt>
          </div>
        </div>
      </div>
    </li>
  );
};

export default OrderSummaryLineItemCard;
