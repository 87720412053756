import React from 'react';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Txt from './Txt';
import i18n from 'common/providers/i18n';
import { isGdamTheme } from 'common/lib/helpers';

interface Props {
  size?: number;
  text?: string;
}

const useStyles = makeStyles((theme) => ({
  root: (props: Props) => ({
    display: 'flex',
    flexDirection: isGdamTheme() ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#ffffff',
    // paypal button zindex is 100
    zIndex: 101,
  }),
  loaderWrapper: { flexDirection: 'column', alignItems: 'center' },
  loader: () => {
    if (isGdamTheme()) {
      return {
        marginTop: '24px',
      };
    }

    return {
      zIndex: 1,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '50px',
      position: 'relative',
      margin: '0 auto',
      width: '50px',
    };
  },
  text: {
    ...theme.typography.body1,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  item: {
    backgroundColor: '#000',
    borderRadius: 3,
    height: 3,
    position: 'absolute',
    width: 3,
    '&:nth-child(1)': { animation: `$loaderAnimation1 2.8s infinite ease-in-out`, left: 11 },
    '&:nth-child(2)': { animation: `$loaderAnimation2 2.8s infinite ease-in-out`, left: 16 },
    '&:nth-child(3)': { animation: `$loaderAnimation3 2.8s infinite ease-in-out`, left: 21 },
    '&:nth-child(4)': { animation: `$loaderAnimation4 2.8s infinite ease-in-out`, left: 26 },
    '&:nth-child(5)': { animation: `$loaderAnimation5 2.8s infinite ease-in-out`, left: 31 },
    '&:nth-child(6)': { animation: `$loaderAnimation6 2.8s infinite ease-in-out`, left: 36 },
  },
  '@keyframes loaderAnimation1': {
    '0%': { height: 3 },
    '12.5%': { height: 19 },
    '25%': { height: 3 },
    '37.5%': { height: 14 },
    '50%': { height: 3 },
    '62.5%': { height: 7 },
    '75%': { height: 3 },
    '87.5%': { height: 24 },
    '100%': { height: 3 },
  },
  '@keyframes loaderAnimation2': {
    '0%': { height: 3 },
    '12.5%': { height: 6 },
    '25%': { height: 3 },
    '37.5%': { height: 6 },
    '50%': { height: 3 },
    '62.5%': { height: 23 },
    '75%': { height: 3 },
    '87.5%': { height: 6 },
    '100%': { height: 3 },
  },
  '@keyframes loaderAnimation3': {
    '0%': { height: 3 },
    '12.5%': { height: 29 },
    '25%': { height: 3 },
    '37.5%': { height: 23 },
    '50%': { height: 3 },
    '62.5%': { height: 14 },
    '75%': { height: 3 },
    '87.5%': { height: 23 },
    '100%': { height: 3 },
  },
  '@keyframes loaderAnimation4': {
    '0%': { height: 3 },
    '12.5%': { height: 14 },
    '25%': { height: 3 },
    '37.5%': { height: 15 },
    '50%': { height: 3 },
    '62.5%': { height: 10 },
    '75%': { height: 3 },
    '87.5%': { height: 15 },
    '100%': { height: 3 },
  },
  '@keyframes loaderAnimation5': {
    '0%': { height: 3 },
    '12.5%': { height: 10 },
    '25%': { height: 3 },
    '37.5%': { height: 25 },
    '50%': { height: 3 },
    '62.5%': { height: 25 },
    '75%': { height: 3 },
    '87.5%': { height: 11 },
    '100%': { height: 3 },
  },
  '@keyframes loaderAnimation6': {
    '0%': { height: 3 },
    '12.5%': { height: 21 },
    '25%': { height: 3 },
    '37.5%': { height: 10 },
    '50%': { height: 3 },
    '62.5%': { height: 16 },
    '75%': { height: 3 },
    '87.5%': { height: 20 },
    '100%': { height: 3 },
  },
  '@keyframes loaderAnimationAlt1': {
    '0%': { height: 3 },
    '7.69%': { height: 19 },
    '15.38%': { height: 14 },
    '23.07%': { height: 7 },
    '30.76%': { height: 24 },
    '38.45%': { height: 19 },
    '46.14%': { height: 14 },
    '53.83%': { height: 7 },
    '61.52%': { height: 24 },
    '69.21%': { height: 19 },
    '76.9%': { height: 14 },
    '84.59%': { height: 7 },
    '92.28%': { height: 24 },
    '100%': { height: 3 },
  },
  '@keyframes loaderAnimationAlt2': {
    '0%': { height: 3 },
    '7.69%': { height: 6 },
    '15.38%': { height: 6 },
    '23.07%': { height: 23 },
    '30.76%': { height: 6 },
    '38.45%': { height: 6 },
    '46.14%': { height: 6 },
    '53.83%': { height: 23 },
    '61.52%': { height: 6 },
    '69.21%': { height: 6 },
    '76.9%': { height: 6 },
    '84.59%': { height: 23 },
    '92.28%': { height: 6 },
    '100%': { height: 3 },
  },
  '@keyframes loaderAnimationAlt3': {
    '0%': { height: 3 },
    '7.69%': { height: 29 },
    '15.38%': { height: 23 },
    '23.07%': { height: 14 },
    '30.76%': { height: 23 },
    '38.45%': { height: 29 },
    '46.14%': { height: 23 },
    '53.83%': { height: 14 },
    '61.52%': { height: 23 },
    '69.21%': { height: 29 },
    '76.9%': { height: 23 },
    '84.59%': { height: 14 },
    '92.28%': { height: 23 },
    '100%': { height: 3 },
  },
  '@keyframes loaderAnimationAlt4': {
    '0%': { height: 3 },
    '7.69%': { height: 14 },
    '15.38%': { height: 15 },
    '23.07%': { height: 10 },
    '30.76%': { height: 15 },
    '38.45%': { height: 14 },
    '46.14%': { height: 15 },
    '53.83%': { height: 10 },
    '61.52%': { height: 15 },
    '69.21%': { height: 14 },
    '76.9%': { height: 15 },
    '84.59%': { height: 10 },
    '92.28%': { height: 15 },
    '100%': { height: 3 },
  },
  '@keyframes loaderAnimationAlt5': {
    '0%': { height: 3 },
    '7.69%': { height: 10 },
    '15.38%': { height: 25 },
    '23.07%': { height: 25 },
    '30.76%': { height: 11 },
    '38.45%': { height: 10 },
    '46.14%': { height: 25 },
    '53.83%': { height: 25 },
    '61.52%': { height: 11 },
    '69.21%': { height: 10 },
    '76.9%': { height: 25 },
    '84.59%': { height: 25 },
    '92.28%': { height: 11 },
    '100%': { height: 3 },
  },
  '@keyframes loaderAnimationAlt6': {
    '0%': { height: 3 },
    '7.69%': { height: 21 },
    '15.38%': { height: 10 },
    '23.07%': { height: 16 },
    '30.76%': { height: 20 },
    '38.45%': { height: 21 },
    '46.14%': { height: 10 },
    '53.83%': { height: 16 },
    '61.52%': { height: 20 },
    '69.21%': { height: 21 },
    '76.9%': { height: 10 },
    '84.59%': { height: 16 },
    '92.28%': { height: 20 },
    '100%': { height: 3 },
  },
}));

const Loader = (props: Props): JSX.Element => {
  const classes = useStyles(props);

  if (isGdamTheme()) {
    return (
      <div className={classes.root}>
        <CircularProgress size={props.size} />
        <Txt variant="regular" data-testid="loader-text" className={classes.loader}>
          {props.text || i18n.t('Loading')}
        </Txt>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.loaderWrapper}>
        <div className={classes.loader}>
          <div className={classes.item}></div>
          <div className={classes.item}></div>
          <div className={classes.item}></div>
          <div className={classes.item}></div>
          <div className={classes.item}></div>
          <div className={classes.item}></div>
        </div>
        <Txt variant="regular" data-testid="loader-text">
          {props.text || i18n.t('Loading')}
        </Txt>
      </div>
    </div>
  );
};

export default Loader;
