import React from 'react';
import { useCart } from 'common/hooks/useCart';
import i18n from 'common/providers/i18n';
import Txt, { TxtVariant } from './Txt';

interface Props {
  className?: string;
  variant?: TxtVariant;
}

const ItemCount = (props: Props): JSX.Element => {
  const { className, variant = 'headingFour' } = props;
  const { cart } = useCart();

  const count = cart?.lineItems.reduce((acc, curr) => acc + curr.quantity, 0) ?? 0;

  return (
    <Txt variant={variant} fontWeight="light" className={className} data-testid="item-count">
      {count} {count === 1 ? i18n.t('item') : i18n.t('items')}
    </Txt>
  );
};

export default ItemCount;
