import React, { useState } from 'react';
import Providers from 'common/providers/Providers';
import Button from 'common/components/Button';
import i18n from 'common/providers/i18n';
import { useCart } from 'common/hooks/useCart';
import { useAddToCart } from 'common/hooks/useAddToCart';
import { useProductPriceAndStock } from 'common/hooks/useProduct';
import getConfig from 'config';
import { makeStyles, alpha, Box } from '@material-ui/core';
import { GET_UPSELL_SKUS } from 'common/queries';
import { getUpsellSkus } from 'common/interfaces/generated/getUpsellSkus';
import { useQuery } from '@apollo/client';
import ButtonArrow from 'common/icons/ButtonArrow';
import CheckmarkIcon from 'common/icons/CheckmarkIcon';

export interface Props {
  sku: string;
  fullWidth?: boolean;
  retailerUrl?: string;
  extraDescription?: string;
  variant?: 'default' | 'minimal';
  showOutOfStock?: boolean;
}

export const AddToCart = ({
  sku,
  fullWidth,
  extraDescription,
  retailerUrl = getConfig().urls.retailer,
  variant = 'default',
  showOutOfStock,
}: Props): JSX.Element | null => {
  const { cart } = useCart();
  const classes = useStyles({ variant });
  const minimal = variant === 'minimal';
  const [mouseDown, setMouseDown] = useState(false);

  const { available, prices, loading } = useProductPriceAndStock({
    sku,
    cart,
  });
  const { data: upsellData } = useQuery<getUpsellSkus>(GET_UPSELL_SKUS, {
    variables: {
      mainSku: sku,
    },
  });

  const addToCart = useAddToCart({
    sku,
    extraDescription,
    upsellSkus: upsellData?.upsellSkus,
  });

  if (loading) {
    return <div className={classes.empty} />;
  }

  if (!sku) {
    console.error('data-prop-sku not set');
    return null;
  }

  const disabled = loading || !available;

  if (prices && !available && !loading && retailerUrl) {
    return (
      <div className={classes.root}>
        <a href={retailerUrl} rel="noreferrer" target="_blank" className={classes.retailerLink}>
          <Button variant="primary" className={classes.retailerBtn} data-testid="out-of-stock-button">
            {i18n.t(`${getConfig().textKeys.findRetailer}`)}
          </Button>
        </a>
      </div>
    );
  }

  // Button variant: minimal
  // Used for product blocks.
  if (minimal) {
    if (showOutOfStock && disabled) {
      return (
        <Button fullWidth={fullWidth} data-testid="out-of-stock-button" disabled={disabled} className={classes.minimal}>
          {i18n.t('OutOfStock')}
        </Button>
      );
    }

    return (
      <Button
        fullWidth={fullWidth}
        data-testid="add-to-cart"
        disabled={disabled}
        onClick={async (e) => {
          setMouseDown(true);
          e.stopPropagation();
          e.preventDefault();
          await addToCart();
          if (!mouseDown) setTimeout(() => setMouseDown(false), 1000);
        }}
        className={classes.minimal}
      >
        {!mouseDown ? (
          <>
            {i18n.t('AddToBasket')}
            <span className={classes.arrowWrapper}>
              <ButtonArrow color={'currentColor'} />
            </span>
          </>
        ) : (
          <>
            {i18n.t('AddedToBasket')}
            <Box ml={1}>
              <CheckmarkIcon color={'currentColor'} />
            </Box>
          </>
        )}
      </Button>
    );
  }

  // Button variant: default
  return (
    <Button
      fullWidth={fullWidth}
      data-testid="add-to-cart"
      disabled={disabled}
      onClick={async (e) => {
        e.stopPropagation();
        e.preventDefault();
        await addToCart();
      }}
    >
      {i18n.t('AddToBasket')}
    </Button>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
        height: theme.spacing(13),
        backgroundColor: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: theme.shadows[7],
        zIndex: 10000,
      },
    },
    retailerLink: {
      textDecoration: 'none',
      '&:hover, &:visited, &:focus': {
        textDecoration: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '0px 22px',
      },
    },
    retailerBtn: {
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
      },
    },
    empty: {
      minHeight: theme.spacing(13),
    },
    labelExtra: {
      paddingLeft: theme.spacing(2),
    },
    minimal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'transparent',
      color: theme.palette.text.primary,

      '&:not([disabled])&:hover, &:not([disabled])&:active': {
        background: 'transparent',
        color: theme.palette.text.primary,

        '& .MuiAddToCart-arrowWrapper': {
          transform: 'translateX(5px)',

          '& svg #line': {
            opacity: 1,
          },
        },
      },

      '&[disabled]': {
        background: 'transparent',
        color: alpha(theme.palette.text.primary, 0.25),
        opacity: 1,
      },
    },
    arrowWrapper: {
      display: 'inline-flex',
      alignItems: 'center',
      transition: 'transform 200ms ease-in',

      '& svg': {
        height: 14,
        width: 'auto',
      },

      '& svg #line': {
        opacity: 0,
        transition: 'opacity 200ms ease-in',
      },
    },
  }),
  { name: 'MuiAddToCart' }
);

const ProvidedAddToCart = (props: Props) => {
  return (
    <Providers>
      <AddToCart {...props} />
    </Providers>
  );
};

export default ProvidedAddToCart;
