import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import { analytics } from 'common/analytics/actions';
import Button from 'common/components/Button';
import Txt from 'common/components/Txt';
import { getActiveCart_inStore_me_activeCart_lineItems } from 'common/interfaces/generated/getActiveCart';
import { getOrder } from 'common/interfaces/generated/getOrder';
import { getAnalyticsInfoFromLineItems } from 'common/lib/cart';
import { redirect } from 'common/lib/helpers';
import Providers from 'common/providers/Providers';
import i18n from 'common/providers/i18n';
import { GET_ORDER } from 'common/queries';
import getConfig from 'config';
import NodeRSA from 'node-rsa';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface Props {}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    width: '100%',
    maxWidth: 690,
    textAlign: 'center',
    marginBottom: theme.spacing(10),
  },
  button: {
    maxWidth: 450,
    width: '100%',
    borderRadius: theme.shape.borderRadius || 0,
  },
}));

export const Confirmation = (props: Props): JSX.Element | null => {
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const errorMsg = params.get('errorMsg');
  const returnUrl = params.get('returnUrl');
  const [encrypt, setEncrypt] = useState<{ nodeRSA?: NodeRSA; keyId?: string } | undefined>(undefined);
  const id = params.get('id');
  const { data } = useQuery<getOrder>(GET_ORDER, {
    variables: { orderNumber: id },
    skip: !id,
    fetchPolicy: 'no-cache', // no cache because you dont want to mess with the ME object for testing
  });

  const firstName = data?.me.order?.billingAddress?.firstName ?? data?.me.order?.shippingAddress?.firstName;

  useEffect(() => {
    if (errorMsg) {
      document.title = `${i18n.t('errors.checkoutError')} | ${getConfig().brandName}`;
    }
  }, []);

  useEffect(() => {
    const getPublicKey = async () => {
      try {
        const res = await axios.get(getConfig().bazaarvoiceUrl);
        if (res?.data?.publicKey) {
          const nodeRSA = new NodeRSA();
          nodeRSA.importKey(res.data?.publicKey, 'pkcs8-public');
          nodeRSA.setOptions({ encryptionScheme: 'pkcs1' });
          setEncrypt({ nodeRSA, keyId: res.data?.encryptionKeyId });
        }
      } catch (err) {
        // api call to bazaarvoice failed; no keyId for encryption received
        // if nodeRSA and keyId are undefined, values sent to analytics for
        // encryptedEmail and encryptionKeyId will be empty strings
        setEncrypt({ nodeRSA: undefined, keyId: undefined });
      }
    };
    getPublicKey();
  }, []);
  useEffect(() => {
    if (data?.me.order && encrypt) {
      const order = data.me.order;
      const totalTaxAmount = order.taxedPrice?.totalGross.centAmount - order.taxedPrice?.totalNet.centAmount;
      const encryptedEmail = encrypt.nodeRSA ? encrypt.nodeRSA.encrypt(order.customerEmail || '', 'hex') : '';
      analytics.confirmation(
        order.cart?.id || '',
        id as string,
        order.taxedPrice?.totalGross.centAmount / 100,
        order.shippingInfo?.taxedPrice?.totalGross.centAmount / 100,
        order.discountCodes.filter((dc) => dc.discountCode?.isActive).map((dc) => dc.discountCode?.code ?? ''),
        getAnalyticsInfoFromLineItems(order.lineItems as getActiveCart_inStore_me_activeCart_lineItems[]),
        order.paymentInfo?.payments[0]?.paymentMethodInfo?.method ?? '',
        firstName,
        encryptedEmail,
        encrypt.keyId ?? '',
        totalTaxAmount / 100
      );
    }
  }, [data, encrypt]);

  return (
    <div className={classes.root} data-testid="confirmation">
      {errorMsg ? (
        <>
          <Txt data-testid="confirmation-header" variant="headingThree" fontWeight="bold" className={classes.text}>
            {i18n.t('errors.unableToProcess')}
          </Txt>
          <Txt variant="headingFive" fontWeight="light" data-testid="confirmation-sub-header" className={classes.text}>
            {errorMsg}
          </Txt>
        </>
      ) : (
        <>
          <Txt data-testid="confirmation-header" variant="headingThree" fontWeight="bold" className={classes.text}>
            {i18n.t('orderConfirmed', { firstName: firstName ?? i18n.t('customer') })}
          </Txt>
          <Txt variant="headingFive" fontWeight="light" data-testid="confirmation-sub-header" className={classes.text}>
            {i18n.t('YourOrderNumberIs')}
            <b>{` ${id}. `}</b>
            {i18n.t('confirmationSent')}
          </Txt>
        </>
      )}

      {returnUrl && typeof returnUrl === 'string' && (
        <Button
          className={classes.button}
          onClick={() => {
            redirect(returnUrl);
          }}
        >
          {i18n.t('BackToPayment')}
        </Button>
      )}

      <Button
        data-testid="confirmation-button"
        variant="secondary"
        className={classes.button}
        onClick={() => {
          redirect(getConfig().urls.catalog);
        }}
      >
        {i18n.t('BackToHomepage')}
      </Button>
    </div>
  );
};

const ProvidedConfirmation = (props: Props) => {
  return (
    <Providers>
      <Confirmation {...props} />
    </Providers>
  );
};

export default ProvidedConfirmation;
