import { useQuery } from '@apollo/client';
import { Hidden, makeStyles, SwipeableDrawer } from '@material-ui/core';
import clsx from 'clsx';
import { MobileAddedToCartPopup } from 'common/components/MobileAddedToCartPopupContents';
import { useCart } from 'common/hooks/useCart';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { getQuickviewState } from 'common/interfaces/generated/getQuickviewState';
import { getProductsNumber } from 'common/lib/cart';
import Providers from 'common/providers/Providers';
import { StyleProvider } from 'common/providers/StyleProvider';
import { GET_QUICKVIEW_STATE, SET_QUICKVIEW_STATE } from 'common/queries';
import React, { HTMLProps, useEffect, useState } from 'react';
import CrossSellAddOn from '../../common/components/CrossSellAddOn/CrossSellAddOn';
import QuickviewContent from './components/QuickviewContent';

export interface Props {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#ffffff',
  },
  button: {
    marginTop: theme.spacing(2),
    width: 450,
  },
  paper: {
    width: 'auto',
  },
}));

export const Quickview = (props: Props & HTMLProps<HTMLDivElement>): JSX.Element | null => {
  const { data } = useQuery<getQuickviewState>(GET_QUICKVIEW_STATE);
  const [setQuickviewOpen] = useGraphqlMutation(SET_QUICKVIEW_STATE);
  const { cart, previousData } = useCart();
  const classes = useStyles({ open: data?.isQuickviewOpen });
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);
  const [isMobileQuickviewOpen, setIsMobileQuickviewOpen] = useState(false);

  const startOpenTimer = () => {
    if (timeoutId) stopOpenTimer();
    setTimeoutId(
      window.setTimeout(() => {
        setQuickviewOpen({ variables: { open: false } });
      }, 4000)
    );
  };

  const closeQuickview = async () => {
    setQuickviewOpen({ variables: { open: false } });
  };

  const stopOpenTimer = () => {
    window.clearTimeout(timeoutId);
  };

  useEffect(() => {
    // open when adding a product
    if (cart?.lineItems && previousData) {
      const currentCount = getProductsNumber(cart?.lineItems);
      const prevCount = getProductsNumber(previousData?.inStore?.me?.activeCart?.lineItems);
      if (currentCount > prevCount) {
        setQuickviewOpen({ variables: { open: true } });
        startOpenTimer();
      }
    }
  }, [cart?.lineItems]);

  return (
    <>
      <Hidden lgUp>
        <MobileAddedToCartPopup
          isOpen={!!data?.isQuickviewOpen}
          startOpenTimer={startOpenTimer}
          stopOpenTimer={stopOpenTimer}
        />
        <SwipeableDrawer
          anchor="right"
          open={isMobileQuickviewOpen}
          onOpen={() => setIsMobileQuickviewOpen(true)}
          onClose={() => setIsMobileQuickviewOpen(false)}
          classes={{ paper: classes.paper }}
        >
          <StyleProvider>
            <QuickviewContent closeQuickview={() => setIsMobileQuickviewOpen(false)} />
          </StyleProvider>
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown>
        <SwipeableDrawer
          anchor="right"
          open={!!data?.isQuickviewOpen}
          onOpen={() => setQuickviewOpen({ variables: { open: true } })}
          onClose={() => setQuickviewOpen({ variables: { open: false } })}
          classes={{ paper: classes.paper }}
        >
          <StyleProvider>
            <div
              data-testid="quickview"
              onMouseEnter={stopOpenTimer}
              onTouchStart={stopOpenTimer}
              onMouseLeave={startOpenTimer}
              onTouchEnd={startOpenTimer}
              className={clsx(classes.root)}
            >
              <CrossSellAddOn />
              <QuickviewContent closeQuickview={closeQuickview} />
            </div>
          </StyleProvider>
        </SwipeableDrawer>
      </Hidden>
    </>
  );
};

const ProvidedQuickview = (props: Props) => {
  return (
    <Providers>
      <Quickview {...props} />
    </Providers>
  );
};

export default ProvidedQuickview;
