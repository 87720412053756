import React from 'react';
import { makeStyles } from '@material-ui/core';

interface Props {
  color?: string;
  margin?: string | number;
  spacing?: number;
}

const useStyles = makeStyles((theme) => ({
  root: (props: Props) => ({
    marginLeft: theme.spacing(props.spacing || 1),
    marginRight: theme.spacing(props.spacing || 1),
    borderLeft: `1px solid ${props.color || theme.palette.text.primary}`,
    marginTop: props.margin || 0,
    marginBottom: props.margin || 0,
  }),
}));

const VerticalDivider = (props: Props): JSX.Element => {
  const classes = useStyles(props);
  return <div className={classes.root}></div>;
};

export default VerticalDivider;
