/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CartState {
  Active = 'Active',
  Merged = 'Merged',
  Ordered = 'Ordered',
}

export enum InventoryMode {
  None = 'None',
  ReserveOnOrder = 'ReserveOnOrder',
  TrackOnly = 'TrackOnly',
}

export enum RoundingMode {
  HalfDown = 'HalfDown',
  HalfEven = 'HalfEven',
  HalfUp = 'HalfUp',
}

export enum ShippingMethodState {
  DoesNotMatchCart = 'DoesNotMatchCart',
  MatchesCart = 'MatchesCart',
}

export enum SnackbarType {
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export enum TaxCalculationMode {
  LineItemLevel = 'LineItemLevel',
  UnitPriceLevel = 'UnitPriceLevel',
}

export enum TaxMode {
  Disabled = 'Disabled',
  External = 'External',
  ExternalAmount = 'ExternalAmount',
  Platform = 'Platform',
}

export interface AddCartDiscountCode {
  code: string;
  validateDuplicates?: boolean | null;
}

export interface AddCartItemShippingAddress {
  address: AddressInput;
}

export interface AddCartPayment {
  payment: ResourceIdentifierInput;
}

export interface AddCartShoppingList {
  shoppingList: ResourceIdentifierInput;
  supplyChannel?: ResourceIdentifierInput | null;
  distributionChannel?: ResourceIdentifierInput | null;
}

export interface AddMyCartLineItem {
  addedAt?: any | null;
  shippingDetails?: ItemShippingDetailsDraft | null;
  custom?: CustomFieldsDraft | null;
  distributionChannel?: ResourceIdentifierInput | null;
  supplyChannel?: ResourceIdentifierInput | null;
  variantId?: number | null;
  quantity?: any | null;
  sku?: string | null;
  productId?: string | null;
}

export interface AddressInput {
  id?: string | null;
  title?: string | null;
  salutation?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  streetName?: string | null;
  streetNumber?: string | null;
  additionalStreetInfo?: string | null;
  postalCode?: string | null;
  city?: string | null;
  region?: string | null;
  state?: string | null;
  country: any;
  company?: string | null;
  department?: string | null;
  building?: string | null;
  apartment?: string | null;
  pOBox?: string | null;
  phone?: string | null;
  mobile?: string | null;
  email?: string | null;
  fax?: string | null;
  additionalAddressInfo?: string | null;
  externalId?: string | null;
  key?: string | null;
  custom?: CustomFieldsDraft | null;
}

export interface ApplyCartDeltaToLineItemShippingDetailsTargets {
  lineItemId: string;
  targetsDelta: ShippingTargetDraft[];
}

export interface BaseMoneyInput {
  centPrecision?: MoneyInput | null;
  highPrecision?: HighPrecisionMoneyInput | null;
}

export interface ChangeCartLineItemQuantity {
  lineItemId: string;
  quantity: any;
  externalPrice?: BaseMoneyInput | null;
  externalTotalPrice?: ExternalLineItemTotalPriceDraft | null;
}

export interface ChangeMyCartTaxMode {
  taxMode: TaxMode;
}

/**
 * A key-value pair representing the field name and value of one single custom field.
 *
 * The value of this custom field consists of escaped JSON based on the FieldDefinition of the Type.
 *
 * Examples for `value`:
 *
 * * FieldType `String`: `"\"This is a string\""`
 * * FieldType `DateTimeType`: `"\"2018-10-12T14:00:00.000Z\""`
 * * FieldType `Number`: `"4"`
 * * FieldType `Set` with an elementType of `String`: `"[\"This is a string\", \"This is another string\"]"`
 * * FieldType `Reference`: `"{\"id\", \"b911b62d-353a-4388-93ee-8d488d9af962\", \"typeId\", \"product\"}"`
 */
export interface CustomFieldInput {
  name: string;
  value: string;
}

export interface CustomFieldsDraft {
  typeId?: string | null;
  typeKey?: string | null;
  type?: ResourceIdentifierInput | null;
  fields?: CustomFieldInput[] | null;
}

export interface ExternalLineItemTotalPriceDraft {
  price: BaseMoneyInput;
  totalPrice: MoneyInput;
}

export interface HighPrecisionMoneyInput {
  currencyCode: any;
  preciseAmount: any;
  fractionDigits: number;
  centAmount?: any | null;
}

export interface ItemShippingDetailsDraft {
  targets: ShippingTargetDraft[];
}

export interface MoneyInput {
  currencyCode: any;
  centAmount: any;
}

export interface MyCartDraft {
  currency: any;
  country?: any | null;
  inventoryMode?: InventoryMode | null;
  custom?: CustomFieldsDraft | null;
  customerEmail?: string | null;
  shippingAddress?: AddressInput | null;
  billingAddress?: AddressInput | null;
  shippingMethod?: ResourceIdentifierInput | null;
  taxMode?: TaxMode | null;
  locale?: any | null;
  deleteDaysAfterLastModification?: number | null;
  itemShippingAddresses?: AddressInput[] | null;
  discountCodes?: string[] | null;
  store?: ResourceIdentifierInput | null;
  lineItems?: MyLineItemDraft[] | null;
}

export interface MyCartUpdateAction {
  addDiscountCode?: AddCartDiscountCode | null;
  addItemShippingAddress?: AddCartItemShippingAddress | null;
  addLineItem?: AddMyCartLineItem | null;
  addPayment?: AddCartPayment | null;
  addShoppingList?: AddCartShoppingList | null;
  applyDeltaToLineItemShippingDetailsTargets?: ApplyCartDeltaToLineItemShippingDetailsTargets | null;
  changeLineItemQuantity?: ChangeCartLineItemQuantity | null;
  changeTaxMode?: ChangeMyCartTaxMode | null;
  recalculate?: RecalculateCart | null;
  removeDiscountCode?: RemoveCartDiscountCode | null;
  removeItemShippingAddress?: RemoveCartItemShippingAddress | null;
  removeLineItem?: RemoveCartLineItem | null;
  removePayment?: RemoveCartPayment | null;
  setBillingAddress?: SetCartBillingAddress | null;
  setBillingAddressCustomField?: SetCartBillingAddressCustomField | null;
  setBillingAddressCustomType?: SetCartBillingAddressCustomType | null;
  setCountry?: SetCartCountry | null;
  setCustomField?: SetCartCustomField | null;
  setCustomType?: SetCartCustomType | null;
  setCustomerEmail?: SetCartCustomerEmail | null;
  setDeleteDaysAfterLastModification?: SetCartDeleteDaysAfterLastModification | null;
  setItemShippingAddressCustomField?: SetCartItemShippingAddressCustomField | null;
  setItemShippingAddressCustomType?: SetCartItemShippingAddressCustomType | null;
  setLineItemCustomField?: SetCartLineItemCustomField | null;
  setLineItemCustomType?: SetCartLineItemCustomType | null;
  setLineItemDistributionChannel?: SetCartLineItemDistributionChannel | null;
  setLineItemShippingDetails?: SetCartLineItemShippingDetails | null;
  setLineItemSupplyChannel?: SetCartLineItemSupplyChannel | null;
  setLocale?: SetCartLocale | null;
  setShippingMethod?: SetMyCartShippingMethod | null;
  setShippingAddress?: SetCartShippingAddress | null;
  setShippingAddressCustomField?: SetCartShippingAddressCustomField | null;
  setShippingAddressCustomType?: SetCartShippingAddressCustomType | null;
  updateItemShippingAddress?: UpdateCartItemShippingAddress | null;
}

export interface MyLineItemDraft {
  productId?: string | null;
  sku?: string | null;
  quantity?: any | null;
  variantId?: number | null;
  supplyChannel?: ResourceIdentifierInput | null;
  distributionChannel?: ResourceIdentifierInput | null;
  custom?: CustomFieldsDraft | null;
  shippingDetails?: ItemShippingDetailsDraft | null;
  addedAt?: any | null;
}

export interface RecalculateCart {
  updateProductData?: boolean | null;
}

export interface ReferenceInput {
  typeId: string;
  id: string;
}

export interface RemoveCartDiscountCode {
  discountCode: ReferenceInput;
}

export interface RemoveCartItemShippingAddress {
  addressKey: string;
}

export interface RemoveCartLineItem {
  lineItemId: string;
  quantity?: any | null;
  externalPrice?: BaseMoneyInput | null;
  externalTotalPrice?: ExternalLineItemTotalPriceDraft | null;
  shippingDetailsToRemove?: ItemShippingDetailsDraft | null;
}

export interface RemoveCartPayment {
  payment: ResourceIdentifierInput;
}

export interface ResourceIdentifierInput {
  typeId?: string | null;
  id?: string | null;
  key?: string | null;
}

export interface SetCartBillingAddress {
  address?: AddressInput | null;
}

export interface SetCartBillingAddressCustomField {
  name: string;
  value?: string | null;
}

export interface SetCartBillingAddressCustomType {
  fields?: CustomFieldInput[] | null;
  type?: ResourceIdentifierInput | null;
  typeKey?: string | null;
  typeId?: string | null;
}

export interface SetCartCountry {
  country?: any | null;
}

export interface SetCartCustomField {
  name: string;
  value?: string | null;
}

export interface SetCartCustomType {
  fields?: CustomFieldInput[] | null;
  type?: ResourceIdentifierInput | null;
  typeKey?: string | null;
  typeId?: string | null;
}

export interface SetCartCustomerEmail {
  email?: string | null;
}

export interface SetCartDeleteDaysAfterLastModification {
  deleteDaysAfterLastModification?: number | null;
}

export interface SetCartItemShippingAddressCustomField {
  addressKey: string;
  name: string;
  value?: string | null;
}

export interface SetCartItemShippingAddressCustomType {
  addressKey: string;
  fields?: CustomFieldInput[] | null;
  type?: ResourceIdentifierInput | null;
  typeKey?: string | null;
  typeId?: string | null;
}

export interface SetCartLineItemCustomField {
  lineItemId: string;
  name: string;
  value?: string | null;
}

export interface SetCartLineItemCustomType {
  lineItemId: string;
  fields?: CustomFieldInput[] | null;
  type?: ResourceIdentifierInput | null;
  typeKey?: string | null;
  typeId?: string | null;
}

export interface SetCartLineItemDistributionChannel {
  lineItemId: string;
  distributionChannel?: ResourceIdentifierInput | null;
}

export interface SetCartLineItemShippingDetails {
  lineItemId: string;
  shippingDetails?: ItemShippingDetailsDraft | null;
}

export interface SetCartLineItemSupplyChannel {
  lineItemId: string;
  supplyChannel?: ResourceIdentifierInput | null;
}

export interface SetCartLocale {
  locale?: any | null;
}

export interface SetCartShippingAddress {
  address?: AddressInput | null;
}

export interface SetCartShippingAddressCustomField {
  name: string;
  value?: string | null;
}

export interface SetCartShippingAddressCustomType {
  fields?: CustomFieldInput[] | null;
  type?: ResourceIdentifierInput | null;
  typeKey?: string | null;
  typeId?: string | null;
}

export interface SetMyCartShippingMethod {
  shippingMethod?: ResourceIdentifierInput | null;
}

export interface ShippingTargetDraft {
  addressKey: string;
  quantity: any;
}

export interface SnackbarInput {
  message: string;
  type: SnackbarType;
}

export interface UpdateCartItemShippingAddress {
  address: AddressInput;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
