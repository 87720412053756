import { Config } from './index';
import baseConfig from './base-config-gdam';

const config: Config = {
  ...baseConfig,
  api: 'https://na-gdam-prd.gd-commerce-services.net',
  brandName: 'Glen Dimplex Americas',
  country: '',
  supportedCountries: [],
  locale: 'en-US',
  commercetools: {
    clientId: '',
    clientSecret: '',
    projectKey: 'na-gdam-prd',
    store: '',
  },
  currency: '',
  paymentEnvironment: 'production',
  cookieDomain: '.glendimplexamericas.com',
  theme: 'gdam',
  bazaarvoiceUrl:
    'https://api.bazaarvoice.com/notifications/rsa_encryption_key/public/current/?passkey=pedpamuvhnprx4hazkqe9xwt',
  features: {
    ...baseConfig.features,
  },
  countryMapping: {
    US: {
      currency: 'USD',
      supportedCountries: ['US'],
      commercetools: {
        store: 'US',
        clientId: 'YFXKsNPdKNwxCacMRzm9h__A',
        clientSecret: 'iJk_2xYQc1ycdFL_EYaGumoQV9Xcb406',
        projectKey: 'na-gdam-prd',
      },
      textKeys: {
        ...baseConfig.textKeys,
        deliveryCountryContext: 'deliveryCountryContextLabel.gdam_us',
      },
    },
    CA: {
      currency: 'CAD',
      supportedCountries: ['CA'],
      commercetools: {
        store: 'CA',
        clientId: 'Fm6vOhz26odEQTt3vfiffmmW',
        clientSecret: 'd03iH96HYwm_kQaLlzZrKg-pNwRKYM-D',
        projectKey: 'na-gdam-prd',
      },
      features: {
        ...baseConfig.features,
        displayTaxes: 'BREAKDOWN_COLLAPSED',
        use3DS: false,
      },
      textKeys: {
        ...baseConfig.textKeys,
        deliveryCountryContext: 'deliveryCountryContextLabel.gdam_ca',
      },
    },
  },
};

export default config;
