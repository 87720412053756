import { useQuery } from '@apollo/client';
import { getActiveCart } from 'common/interfaces/generated/getActiveCart';
import { GET_ACTIVE_CART } from 'common/queries';
import getConfig from 'config';

export const useCart = () => {
  const { data, ...rest } = useQuery<getActiveCart>(GET_ACTIVE_CART, {
    variables: {
      storeKey: getConfig().commercetools.store,
    },
  });
  const { previousData } = rest;
  const cart = data?.inStore?.me?.activeCart;

  const isNewVersion = previousData?.inStore?.me?.activeCart?.version !== cart?.version;
  return { cart, isNewVersion, ...rest };
};
