import React from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
  color?: string;
}

const ButtonArrow = (props: Props): JSX.Element => {
  const { className, onClick, color, ...rest } = props;

  return (
    <svg
      {...rest}
      onClick={onClick}
      className={className}
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group" transform="translate(0.750000, 1.000000)" stroke={color ?? '#555555'} strokeWidth="1.5">
        <polyline
          id="chevron"
          strokeLinejoin="round"
          points="5.4922 8.8817842e-16 9.7348 4.24264 5.4922 8.4853"
        ></polyline>
        <line id="line" x1="8.5" y1="4.25" x2="0" y2="4.25"></line>
      </g>
    </svg>
  );
};

export default ButtonArrow;
