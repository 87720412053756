import React, { HTMLProps, useEffect, useState } from 'react';
import { useCart } from 'common/hooks/useCart';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  component: (lineItem: any) => JSX.Element;
  variant: 'quickview' | 'cart' | 'checkout';
  maxHeight?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    minHeight: 200,
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'auto',
    '& >li:not(:last-child)': {
      position: 'relative',
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      '&::after': {
        position: 'absolute',
        content: '""',
        bottom: 0,
        height: 1,
        backgroundColor: theme.palette.grey['600'],
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  },
  quickview: {
    marginLeft: -theme.spacing(3),
    '& >li:not(:last-child)::after': {
      left: theme.spacing(3),
      right: theme.spacing(3),
    },
  },
  cart: {
    marginLeft: -theme.spacing(2),
    '& >li:not(:last-child)::after': {
      left: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: -theme.spacing(3),
      '& >li:not(:last-child)::after': {
        left: theme.spacing(3),
        right: theme.spacing(3),
      },
    },
  },
  checkout: {
    marginLeft: -theme.spacing(3),
    '& >li:not(:last-child)::after': {
      left: theme.spacing(3),
      right: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: -theme.spacing(2),
      '& >li:not(:last-child)::after': {
        left: theme.spacing(2),
        right: theme.spacing(2),
      },
    },
  },
  scroll: (props: { maxHeight?: number; hasScroll?: boolean }) => ({
    maxHeight: props.maxHeight,
  }),
  shadow: (props: any) => ({
    width: '100%',
    boxShadow: props.hasScroll && theme.shadows[3],
    height: 30,
    flexShrink: 0,
  }),
}));

const LineItemCards = (props: Props & HTMLProps<HTMLUListElement>): JSX.Element => {
  const { maxHeight, variant, component: Component, ...rest } = props;
  const { cart } = useCart();
  const { lineItems } = cart!;
  const [hasScroll, setHasSCroll] = useState(false);
  const classes = useStyles({ maxHeight, hasScroll });

  useEffect(() => {
    const lineItemCards = document.getElementById('line-item-cards');
    setHasSCroll(!!lineItemCards && lineItemCards.scrollHeight > lineItemCards.clientHeight);
  });

  return (
    <>
      <ul
        id="line-item-cards"
        className={clsx(
          classes.root,
          maxHeight && classes.scroll,
          variant === 'cart' && classes.cart,
          variant === 'quickview' && classes.quickview,
          variant === 'checkout' && classes.checkout
        )}
        data-testid="order-summary-products"
        {...rest}
      >
        {lineItems.map((e) => (
          <Component lineItem={e} key={e.id} />
        ))}
      </ul>
      <div className={classes.shadow}></div>
    </>
  );
};

export default LineItemCards;
