import { Component, ErrorInfo, ReactNode } from 'react';
import { getHub } from 'common/interfaces/sentry-hub';

class ErrorBoundary extends Component<{ children: ReactNode }> {
  state = { hasError: false };
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    getHub()?.configureScope((scope: any) => {
      scope.setExtra('errorInfo', errorInfo);
    });
    getHub()?.captureException(error);
  }

  render() {
    if (this.state.hasError) return null;

    return this.props.children;
  }
}

export default ErrorBoundary;
