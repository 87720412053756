// @ts-ignore
import SdkAuth from '@commercetools/sdk-auth';
import getConfig from 'config';
import localForage from 'localforage';
import Cookies from 'js-cookie';

interface Token {
  access_token: string;
  expires_in: number;
  scope: string[];
  refresh_token: string;
  token_type: string;
  expires_at: number;
}

const scopesVersion = 2;

const getTokenKey = () => `gdc_${getConfig().commercetools.clientId.slice(-6)}_access_token_${scopesVersion}`;

const memoizedGetAuthToken = (): [() => Promise<string>, () => void] => {
  const config = getConfig();

  const scopes = [
    'create_anonymous_token',
    'manage_my_shopping_lists',
    'manage_my_profile',
    'view_products',
    'manage_my_payments',
    'manage_my_orders',
    'view_project_settings',
    'view_stores',
  ].map((scope) => `${scope}:${config.commercetools.projectKey}`);
  // Increase when scope changes
  // GDAM uses 2 clients on the same site, so need to refer to the proper token

  const authClient = new SdkAuth({
    host: config.authHost,
    projectKey: config.commercetools.projectKey,
    disableRefreshToken: false,
    credentials: {
      clientId: config.commercetools.clientId,
      clientSecret: config.commercetools.clientSecret,
    },
    scopes,
    fetch,
  });
  let token: Token | undefined;
  const getToken = async (): Promise<string> => {
    const now = new Date().getTime() / 1000;

    if (token && now < token.expires_at) return token.access_token;
    const localToken = await getTokenFromLocalStorage();
    if (localToken && now < localToken.expires_at) {
      token = localToken;
      return localToken.access_token;
    } else if (localToken && now > localToken.expires_at) {
      if (localToken.refresh_token) {
        try {
          const refreshRes = await authClient.refreshTokenFlow(localToken.refresh_token);
          if (refreshRes.access_token) {
            refreshRes.expires_at = getTokenExpirationUnixTime(refreshRes);
            refreshRes.refresh_token = localToken.refresh_token;
            setTokenInLocalStorage(refreshRes);
            token = refreshRes;
            return refreshRes.access_token;
          }
        } catch {
          removeTokenFromLocalStorage();
        }
      }
    }

    // TODO: error handling
    const res = await authClient.anonymousFlow();
    res.expires_at = getTokenExpirationUnixTime(res);
    setTokenInLocalStorage(res);
    token = res;
    return res.access_token;
  };

  const clearToken = () => {
    token = undefined;
  };

  return [getToken, clearToken];
};

export const getTokenFromLocalStorage = async (): Promise<Token | null> => {
  const cookieValRaw = Cookies.get(getTokenKey());

  // TODO: temporary migration logic to new cookie type, can be removed in a month or so
  const oldTokenKey = 'access_token_2';
  const oldCookieValRaw = Cookies.get(oldTokenKey);
  if (oldCookieValRaw && !cookieValRaw) {
    const cookieVal = JSON.parse(oldCookieValRaw);
    setTokenInCookie(cookieVal);
    Cookies.remove(oldTokenKey);

    return cookieVal;
  }

  const localStorageVal = await localForage.getItem(getTokenKey());
  const oldLocalStorageVal = await localForage.getItem(oldTokenKey);
  if (!localStorageVal && oldLocalStorageVal) {
    setTokenInCookie(oldLocalStorageVal as Token);
    localForage.removeItem(oldTokenKey);
    localForage.setItem(getTokenKey(), oldLocalStorageVal);
    return oldLocalStorageVal as Token;
  }
  // end migration logic

  if (cookieValRaw) {
    return JSON.parse(cookieValRaw);
  } else {
    // Not being able to fetch it from the cookies, let's try
    // local storage.
    // If it has a value, let set the cookie as well
    return localForage.getItem(getTokenKey()).then((value: Token | unknown) => {
      if (value) {
        setTokenInCookie(value as Token);
      }
      return value as Token | null;
    });
  }
};

export const removeTokenFromLocalStorage = (): Promise<void> => {
  Cookies.remove(getTokenKey());
  return localForage.removeItem(getTokenKey(), clearAuthToken);
};

const setTokenInLocalStorage = (token: Token): void => {
  setTokenInCookie(token);
  localForage.setItem(getTokenKey(), token);
};

const setTokenInCookie = (token: Token): void => {
  const cookieOptions: {
    domain?: string;
  } = {};
  if (getConfig().cookieDomain) {
    cookieOptions.domain = getConfig().cookieDomain;
  }
  Cookies.set(getTokenKey(), JSON.stringify(token), cookieOptions);
};

const getTokenExpirationUnixTime = (token: Token): number => {
  const now = new Date().getTime() / 1000;
  return now + token.expires_in - 60; // to compensate for network lag
};

export const [getAuthToken, clearAuthToken] = memoizedGetAuthToken();
