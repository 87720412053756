import { PaymentVariant } from './../interfaces/enums';
import { sendEvent } from './utils';
import { AnalyticsLineItem } from './datalayer';

export enum PaymentMethod {
  CREDITCARD = 'credit_card',
  GOOGLE_PAY = 'android_pay_card',
  APPLE_PAY = 'apple_pay_card',
  PAYPAL = 'paypal_account',
}

export const analytics = {
  addLineItem: (cartId: string, fromCrossSell: boolean, lineItems: AnalyticsLineItem[]) => {
    sendEvent({
      type: 'ADD_LINE_ITEM',
      payload: {
        cartId,
        lineItems,
        fromCrossSell,
      },
    });
  },
  removeLineItem: (cartId: string, lineItems: AnalyticsLineItem[]) => {
    sendEvent({
      type: 'REMOVE_LINE_ITEM',
      payload: {
        cartId,
        lineItems,
      },
    });
  },
  viewElement: (
    cartId: string,
    element: 'quickview' | 'basketPage' | 'checkoutEmail' | 'checkoutAddress' | 'checkoutPayment',
    lineItems: AnalyticsLineItem[]
  ) => {
    sendEvent({
      type: 'VIEW_ELEMENT',
      payload: {
        cartId,
        element,
        lineItems,
      },
    });
  },
  setPromoCodeSuccess: (cartId: string, promoCode: string) => {
    sendEvent({
      type: 'SET_PROMO_CODE_SUCCESS',
      payload: {
        cartId,
        promoCode,
      },
    });
  },
  setPromoCodeFailed: (cartId: string, promoCode: string) => {
    sendEvent({
      type: 'SET_PROMO_CODE_FAILED',
      payload: {
        cartId,
        promoCode,
      },
    });
  },
  setEmail: (cartId: string) => {
    sendEvent({
      type: 'SET_EMAIL',
      payload: {
        cartId,
      },
    });
  },
  setShippingAddress: (cartId: string) => {
    sendEvent({
      type: 'SET_SHIPPING_ADDRESS',
      payload: {
        cartId,
      },
    });
  },
  setShippingMethod: (cartId: string, methodId: string) => {
    sendEvent({
      type: 'SET_SHIPPING_METHOD',
      payload: {
        cartId,
        methodId,
      },
    });
  },
  setMarketingOptIn: (cartId: string, isMarketingOptIn: boolean) => {
    sendEvent({
      type: 'SET_MARKETING_OPT_IN',
      payload: {
        cartId,
        isMarketingOptIn,
      },
    });
  },
  setBillingAddress: (cartId: string) => {
    sendEvent({
      type: 'SET_BILLING_ADDRESS',
      payload: {
        cartId,
      },
    });
  },
  pay: (cartId: string, paymentMethod: PaymentMethod, variant: PaymentVariant) => {
    sendEvent({
      type: 'PAY',
      payload: {
        cartId,
        paymentMethod,
        variant,
      },
    });
  },
  confirmation: (
    cartId: string,
    orderId: string,
    revenue: number,
    shipping: number,
    promoCodes: string[],
    lineItems: AnalyticsLineItem[],
    paymentMethod: string,
    firstName: string | undefined | null,
    encryptedEmail: string,
    encryptionKeyId: string,
    tax: number
  ) => {
    sendEvent({
      type: 'ORDER_CONFIRMED',
      payload: {
        cartId,
        orderId,
        revenue,
        shipping,
        promoCodes,
        lineItems,
        paymentMethod,
        firstName: firstName ?? '',
        email: encryptedEmail,
        encryptionKeyId,
        tax,
      },
    });
  },
  paymentFailed: (cartId: string, newCartId: string) => {
    sendEvent({
      type: 'PAYMENT_FAILED',
      payload: { cartId, newCartId },
    });
  },
};
