import { gql } from '@apollo/client';

const addressFields = gql`
  fragment addressFields on Address {
    firstName
    lastName
    streetName
    additionalStreetInfo
    city
    postalCode
    country
    state
    phone
  }
`;

const discountFields = gql`
  fragment discountFields on DiscountedLineItemPrice {
    includedDiscounts {
      discount {
        id
        isActive
        sortOrder
        nameAllLocales {
          locale
          value
        }
      }
      discountedAmount {
        centAmount
      }
    }
  }
`;

const lineItemVariantFields = gql`
  fragment lineItemVariantFields on ProductVariant {
    sku
    key
    attributesRaw(includeNames: ["productDisplayTitle", "variantTitle", "brand", "Brand", "crossSelling", "upsell"]) {
      name
      value
    }
    images {
      url
    }
    assets {
      nameAllLocales {
        value
      }
      sources {
        uri
      }
    }
  }
`;

const cartFields = gql`
  fragment cartFields on Cart {
    __typename
    id
    country
    version
    cartState
    locale
    customerEmail
    custom {
      customFieldsRaw {
        name
        value
      }
    }
    discountCodes {
      discountCode {
        id
        cartDiscounts {
          id
        }
        code
        isActive
      }
    }
    shippingInfo {
      shippingMethodState
      shippingMethod {
        id
      }
      taxRate {
        amount
        includedInPrice
      }
      taxedPrice {
        totalGross {
          centAmount
        }
        totalNet {
          centAmount
        }
      }
      discountedPrice {
        ...discountFields
      }
      price {
        centAmount
      }
    }
    taxedPrice {
      totalGross {
        currencyCode
        centAmount
      }
      totalNet {
        currencyCode
        centAmount
      }
      taxPortions {
        rate
        amount {
          centAmount
        }
        name
      }
    }
    totalPrice {
      currencyCode
      centAmount
    }
    taxCalculationMode
    taxRoundingMode
    taxMode
    lineItems {
      id
      quantity
      productId
      productType {
        name
      }
      productSlugAllLocales {
        locale
        value
      }
      variant {
        ...lineItemVariantFields
      }
      distributionChannel {
        id
      }
      supplyChannel {
        id
      }
      discountedPricePerQuantity {
        quantity
        discountedPrice {
          ...discountFields
        }
      }
      totalPrice {
        centAmount
      }
      price {
        discounted {
          value {
            centAmount
          }
        }
        value {
          centAmount
        }
      }
      taxRate {
        amount
        includedInPrice
      }
      custom {
        customFieldsRaw {
          name
          value
        }
      }
    }
    shippingAddress {
      ...addressFields
    }
    billingAddress {
      ...addressFields
    }
  }
  ${addressFields}
  ${discountFields}
  ${lineItemVariantFields}
`;

export const CREATE_CART = gql`
  mutation createMyCart($draftCart: MyCartDraft!, $storeKey: KeyReferenceInput!) {
    createMyCart(draft: $draftCart, storeKey: $storeKey) {
      ...cartFields
    }
  }
  ${cartFields}
`;

export const DELETE_CART = gql`
  mutation deleteMyCart($id: String!, $version: Long!) {
    deleteMyCart(id: $id, version: $version) {
      id
    }
  }
`;

export const GET_STORE = gql`
  query getStore($key: String!) {
    store(key: $key) {
      supplyChannels {
        key
        id
        custom {
          customFieldsRaw(includeNames: ["supportedStates"]) {
            name
            value
          }
        }
      }
      distributionChannels {
        key
        id
      }
    }
  }
`;

export const GET_ACTIVE_CART = gql`
  query getActiveCart($storeKey: KeyReferenceInput!) {
    inStore(key: $storeKey) {
      me {
        activeCart {
          ...cartFields
        }
      }
    }
  }
  ${cartFields}
`;

export const UPDATE_ACTIVE_CART = gql`
  mutation updateActiveCart($id: String!, $version: Long!, $actions: [MyCartUpdateAction!]!) {
    updateMyCart(id: $id, version: $version, actions: $actions) {
      ...cartFields
    }
  }
  ${cartFields}
`;

export const GET_PRODUCT_PRICE_AND_STOCK = gql`
  query getProductPriceStock(
    $key: String!
    $currency: Currency!
    $distributionChannelId: String!
    $supplyChannelId: String!
    $country: Country!
  ) {
    product(key: $key) {
      masterData {
        current {
          allVariants {
            ...lineItemVariantFields
            price(currency: $currency, channelId: $distributionChannelId, country: $country) {
              value {
                centAmount
              }
              discounted {
                value {
                  centAmount
                }
              }
            }
            availability {
              channels(includeChannelIds: [$supplyChannelId]) {
                results {
                  channel {
                    id
                  }
                  availability {
                    availableQuantity
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${lineItemVariantFields}
`;

export const GET_PRODUCT_VARIANT_PRICE_AND_STOCK = gql`
  query getProductVariantPriceStock(
    $sku: String!
    $currency: Currency!
    $distributionChannelId: String!
    $supplyChannelId: String!
    $country: Country!
  ) {
    product(sku: $sku) {
      masterData {
        current {
          variant(sku: $sku) {
            ...lineItemVariantFields
            price(currency: $currency, channelId: $distributionChannelId, country: $country) {
              value {
                centAmount
              }
              discounted {
                value {
                  centAmount
                }
              }
            }
            availability {
              channels(includeChannelIds: [$supplyChannelId]) {
                results {
                  channel {
                    id
                  }
                  availability {
                    availableQuantity
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${lineItemVariantFields}
`;

export const GET_PRODUCTS_STOCK = gql`
  query getProductsStock($skus: [String!], $supplyChannelId: String!, $locale: Locale) {
    products(skus: $skus) {
      results {
        masterData {
          current {
            name(locale: $locale)
            allVariants(skus: $skus) {
              ...lineItemVariantFields
              availability {
                channels(includeChannelIds: [$supplyChannelId]) {
                  results {
                    channel {
                      id
                    }
                    availability {
                      availableQuantity
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${lineItemVariantFields}
`;

export const GET_PRODUCTS_PRICE_AND_STOCK = gql`
  query getProductsPriceAndStock(
    $skus: [String!]
    $locale: Locale
    $currency: Currency!
    $distributionChannelId: String!
    $supplyChannelId: String!
    $country: Country!
  ) {
    products(skus: $skus, where: "masterData(published=true)") {
      results {
        masterData {
          current {
            name(locale: $locale)
            slugAllLocales {
              value
              locale
            }
            allVariants(skus: $skus) {
              ...lineItemVariantFields
              price(currency: $currency, channelId: $distributionChannelId, country: $country) {
                value {
                  centAmount
                }
                discounted {
                  value {
                    centAmount
                  }
                }
              }
              availability {
                channels(includeChannelIds: [$supplyChannelId]) {
                  results {
                    channel {
                      id
                    }
                    availability {
                      availableQuantity
                    }
                  }
                }
              }
            }
          }
          published
        }
      }
    }
  }
  ${lineItemVariantFields}
`;

export const GET_SHIPPING_METHODS_BY_CART = gql`
  query getShippingMethodsByCart($cartId: String!) {
    shippingMethodsByCart(id: $cartId) {
      id
      name
      localizedDescriptionAllLocales {
        locale
        value
      }
      localizedNameAllLocales {
        locale
        value
      }
      zoneRates {
        shippingRates {
          isMatching
          freeAbove {
            centAmount
            currencyCode
          }
          price {
            centAmount
            currencyCode
          }
        }
      }
    }
  }
`;

export const GET_UPSELL_SKUS = gql`
  query getUpsellSkus($mainSku: String!) {
    upsellSkus(mainSku: $mainSku) @client
  }
`;

export const TOGGLE_UPSELL_SKU = gql`
  mutation toggleUpsellSku($mainSku: String!, $sku: String!) {
    toggleUpsellSku(mainSku: $mainSku, sku: $sku) @client
  }
`;

export const GET_MOST_RECENT_SKU = gql`
  query getMostRecentSku {
    mostRecentSku @client
  }
`;

export const SET_MOST_RECENT_SKU = gql`
  mutation setMostRecentSku($sku: String) {
    setMostRecentSku(sku: $sku) @client
  }
`;

export const GET_QUICKVIEW_STATE = gql`
  query getQuickviewState {
    isQuickviewOpen @client
  }
`;

export const SET_QUICKVIEW_STATE = gql`
  mutation setQuickviewState($open: Boolean!) {
    setQuickviewOpen(open: $open) @client
  }
`;

export const GET_SNACKBAR = gql`
  query getSnackbar {
    snackbar @client {
      message
      type
    }
  }
`;

export const SET_SNACKBAR = gql`
  mutation setSnackbar($snackbar: SnackbarInput) {
    setSnackbar(snackbar: $snackbar) @client {
      message
      type
    }
  }
`;

export const GET_ORDER = gql`
  query getOrder($orderNumber: String!) {
    me {
      order(orderNumber: $orderNumber) {
        customerEmail
        cart {
          id
        }
        taxedPrice {
          totalGross {
            centAmount
          }
          totalNet {
            centAmount
          }
        }
        shippingInfo {
          taxedPrice {
            totalGross {
              centAmount
            }
          }
        }
        shippingAddress {
          firstName
        }
        billingAddress {
          firstName
        }
        discountCodes {
          discountCode {
            isActive
            code
          }
        }
        paymentInfo {
          payments {
            paymentMethodInfo {
              method
            }
          }
        }
        lineItems {
          id
          quantity
          productType {
            name
          }
          price {
            value {
              centAmount
            }
            discounted {
              value {
                centAmount
              }
            }
          }
          variant {
            ...lineItemVariantFields
          }
        }
      }
    }
  }
  ${lineItemVariantFields}
`;
