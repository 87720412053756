import getConfig from 'config';
import { makeStyles } from '@material-ui/core';
import HorizontalDivider from 'common/components/HorizontalDivider';
import Txt from 'common/components/Txt';
import { PaymentVariant } from 'common/interfaces/enums';
import React from 'react';
import { Clients } from '../BraintreeHelpers';
import ApplePayButton from './ApplePayButton';
import GooglePayButton from './GooglePayButton';
import PaypalButton from './PaypalButton';

interface Props {
  braintreeClients?: Clients;
  variant: PaymentVariant;
  dividerText: string;
}

const useStyles = makeStyles((theme) => ({
  dividers: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
  },
  divider: {
    width: '100%',
    maxWidth: 200,
  },
  dividerText: {
    whiteSpace: 'nowrap',
  },
  button: {
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
    '& button': {
      width: '100% !important',
    },
  },
}));

const PayButtons = (props: Props): JSX.Element | null => {
  const { braintreeClients, variant, dividerText } = props;
  const classes = useStyles();

  if (!braintreeClients) return null;

  return (
    <>
      <div className={classes.dividers}>
        <HorizontalDivider className={classes.divider} />
        <Txt fontWeight="light" className={classes.dividerText}>
          {dividerText}
        </Txt>
        <HorizontalDivider className={classes.divider} />
      </div>
      {getConfig().paymentMethods.applePay && (
        <ApplePayButton className={classes.button} clients={braintreeClients} variant={variant} />
      )}
      {getConfig().paymentMethods.googlePay && (
        <GooglePayButton className={classes.button} clients={braintreeClients} variant={variant} />
      )}
      {getConfig().paymentMethods.paypal && (
        <PaypalButton className={classes.button} clients={braintreeClients} variant={variant} />
      )}
    </>
  );
};

export default PayButtons;
