import React, { ChangeEvent, forwardRef, HTMLProps } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  label?: string;
  className?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  Svg?: ({ className }: { className: string }) => JSX.Element;
}

const useStyles = makeStyles((theme) => ({
  input: {
    webkitBorderRadius: 0,
    borderRadius: 0,
    webkitBoxShadow: 'none',
    boxShadow: 'none',
    width: '100%',
    height: 50,
    margin: 0,
    backgroundColor: 'transparent',
    border: 'none',
    lineHeight: theme.typography.body1.lineHeight,
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.fontFamily,
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey['700']}`,
    fontWeight: 300,
    '&::placeholder': {
      color: theme.palette.grey['600'],
      fontWeight: 300,
    },
    '&:focus': {
      outline: 'none',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '&:focus::placeholder': {
      color: 'transparent',
    },
  },
  withSvg: {
    paddingLeft: theme.spacing(8),
  },
  svg: {
    position: 'absolute',
    left: theme.spacing(2),
  },
}));

const Input = forwardRef((props: Props & HTMLProps<HTMLInputElement>, ref: any) => {
  const { className, label, onChange, disabled, required, Svg, ...rest } = props;

  const classes = useStyles();

  return (
    <>
      {Svg && <Svg className={classes.svg} />}
      <input
        ref={ref}
        aria-label={label}
        className={clsx(classes.input, Svg && classes.withSvg, className)}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
    </>
  );
});

Input.displayName = 'Input';

export default Input;
