import { BrowserClient, Hub, defaultStackParser, makeFetchTransport } from '@sentry/browser';
import getConfig from 'config';

let _hub: Hub | undefined;
export const getHub = () => _hub;

export const initSentry = () => {
  if (process.env.NODE_ENV === 'production' && getConfig().sentryDSN) {
    const client = new BrowserClient({
      dsn: getConfig().sentryDSN,
      release: process.env.VERSION_TAG,
      environment: getConfig().commercetools.projectKey,
      integrations: [],
      stackParser: defaultStackParser,
      transport: makeFetchTransport,
    });

    _hub = new Hub(client);

    _hub.configureScope((scope) => {
      scope.setTag('brand', getConfig().brandName);
      scope.setExtra('config', getConfig());
      scope.setExtra('userAgent', navigator.userAgent);
    });
  }
};
