import React, { useEffect } from 'react';
import Input from 'common/components/formComponents/Input';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { UPDATE_ACTIVE_CART } from 'common/queries';
import { useCart } from 'common/hooks/useCart';
import i18n from 'common/providers/i18n';
import Txt from 'common/components/Txt';
import Button from 'common/components/Button';
import { makeStyles } from '@material-ui/core';
import theme from 'common/themes';
import { useFormik } from 'formik';
import { validate, validation } from 'common/lib/validation';
import { useNavigate } from 'react-router-dom';
import { checkoutPaths, useLoading } from 'modules/Checkout/Checkout';
import FormField from 'common/components/formComponents/FormField';
import { removeUndefinedDeep, getIsQuickCheckout } from 'common/lib/helpers';
import { getAnalyticsInfoFromLineItems } from 'common/lib/cart';
import { analytics } from 'common/analytics/actions';

import { Clients } from '../CheckoutDeliveryPayment/scenes/CheckoutPayment/BraintreeHelpers';
import PayButtons from '../CheckoutDeliveryPayment/scenes/CheckoutPayment/components/PayButtons';
import Loader from 'common/components/Loader';
import { useCheckPrices } from 'common/hooks/useCheckPrices';

interface Props {
  braintreeClients?: Clients;
}

const useStyles = makeStyles({
  root: {
    margin: '30px 15px',
    maxWidth: 450,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 'calc(30vh - 145px)',
    marginBottom: 'calc(30vh - 145px)',
  },
  form: {
    width: ' 100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
      textAlign: 'left',
    },
  },
  submit: {
    marginTop: theme.spacing(),
  },
});

const CheckoutEmail = (props: Props): JSX.Element => {
  const { braintreeClients } = props;
  const { cart } = useCart();
  const { id: cartId, lineItems, customerEmail } = cart!;
  const [updateCart] = useGraphqlMutation(UPDATE_ACTIVE_CART);
  const classes = useStyles();
  let navigate = useNavigate();
  const { loading } = useLoading();
  const checkPrices = useCheckPrices();
  const isQuickCheckout = getIsQuickCheckout();

  useEffect(() => {
    analytics.viewElement(cartId, 'checkoutEmail', getAnalyticsInfoFromLineItems(lineItems));
    checkPrices();
  }, []);

  const formik = useFormik({
    initialValues: {
      email: customerEmail || '',
    },
    onSubmit: async (values) => {
      await updateCart({
        variables: { actions: [{ setCustomerEmail: { email: values.email } }] },
        update: () => {
          analytics.setEmail(cartId);
        },
      });
      navigate(checkoutPaths.delivery);
    },
    validate: (values) => {
      const errors: any = {};

      errors.email = validate(values.email, [validation.required, validation.email]);

      return removeUndefinedDeep(errors);
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, [customerEmail]);

  return (
    <div className={classes.root}>
      {loading && <Loader />}
      <Txt variant="headingFour" fontWeight="bold" className={classes.title}>
        {i18n.t('GuestCheckout')}
      </Txt>
      <form className={classes.form} onSubmit={formik.handleSubmit} noValidate data-testid="email-form">
        <FormField
          required
          label={i18n.t('EmailAddress')!}
          valid={formik.touched.email ? !formik.errors.email : undefined}
          error={formik.touched.email ? formik.errors.email : undefined}
        >
          <Input
            data-testid="email"
            id="email"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormField>
        <Button
          className={classes.submit}
          disabled={!formik.isValid}
          fullWidth
          type="submit"
          data-testid="CheckoutAsGuest"
        >
          {i18n.t('Checkout')}
        </Button>
      </form>
      {isQuickCheckout && (
        <PayButtons
          braintreeClients={braintreeClients}
          variant="quickCheckout"
          dividerText={i18n.t('ORQUICKCHECKOUT')}
        />
      )}
    </div>
  );
};

export default CheckoutEmail;
