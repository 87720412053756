import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import getConfig from 'config';
import { isGdamTheme } from 'common/lib/helpers';

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff',
  },
}));

const BasketLargeEmpty = ({ className }: Props): JSX.Element => {
  const classes = useStyles();

  if (getConfig().theme === 'morphy-richards')
    return (
      <svg
        className={clsx(classes.root, className)}
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.625 29.375H19.375V63.125H60.625V29.375H54.375V37.5H50.625V29.375H29.375V37.5H25.625V29.375ZM29.5399 25.625H50.4601C49.5745 20.6512 45.2282 16.875 40 16.875C34.7718 16.875 30.4255 20.6512 29.5399 25.625ZM54.2538 25.625H62.5H64.375V27.5V65V66.875H62.5H17.5H15.625V65V27.5V25.625H17.5H25.7462C26.6649 18.5718 32.6964 13.125 40 13.125C47.3036 13.125 53.3351 18.5718 54.2538 25.625Z"
          fill="#262626"
        />
      </svg>
    );

  if (getConfig().theme === 'robertsradio')
    return (
      <svg
        className={clsx(classes.root, className)}
        width="70"
        height="56"
        viewBox="0 0 70 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 52L9.14286 17.7143H14.2857H55.4286H60.5714L53.7143 52H16Z" fill="#2D2D2D" />
        <path
          d="M4 17.7143H9.14286M65.7143 17.7143H60.5714M9.14286 17.7143L16 52H53.7143L60.5714 17.7143M9.14286 17.7143H14.2857M60.5714 17.7143H55.4286M55.4286 17.7143L45.1429 4M55.4286 17.7143H14.2857M14.2857 17.7143L24.5714 4"
          stroke="#2D2D2D"
          strokeWidth="6.85714"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.3866 38.7369V32.8123C41.3866 28.698 39.0277 25.4889 34.8586 25.4889C30.4974 25.4889 28.3306 28.9449 28.3306 32.8123V38.7369C28.3306 43.0431 30.3328 46.4991 34.8586 46.4991C39.5488 46.4991 41.3866 42.7963 41.3866 38.7369ZM37.9031 33.114V38.6546C37.9031 41.3974 37.2174 43.2077 34.8586 43.2077C32.39 43.2077 31.814 41.178 31.814 38.6546V33.114C31.814 30.5906 32.7191 28.7803 34.8586 28.7803C37.1077 28.7803 37.9031 30.7551 37.9031 33.114Z"
          fill="white"
        />
      </svg>
    );

  if (isGdamTheme()) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="none" viewBox="0 0 80 80">
        <path
          // Dark color when on mobile; see designs
          fill="#000"
          d="M30.77 64.615a3.077 3.077 0 11-6.155 0 3.077 3.077 0 016.154 0zM52.308 64.615a3.077 3.077 0 11-6.154 0 3.077 3.077 0 016.154 0z"
        />
        <path
          // Dark color when on mobile; see designs
          fill="#000"
          fillRule="evenodd"
          d="M6.154 15.384c0-1.699 1.378-3.076 3.077-3.076h6.418a3.077 3.077 0 013.014 2.453l.766 3.7h46.725a3.077 3.077 0 012.948 3.962L59.87 53.191a3.077 3.077 0 01-2.948 2.193H25.295l-1.026 3.077h31.116a3.077 3.077 0 110 6.153H20a3.077 3.077 0 01-2.919-4.05L19.9 52.11l-5.992-29.958-.764-3.69H9.231a3.077 3.077 0 01-3.077-3.078z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          d="M40.105 46.865c-1.4 0-2.65-.35-3.75-1.05-1.1-.716-1.967-1.75-2.6-3.1-.634-1.366-.95-2.983-.95-4.85 0-1.866.316-3.474.95-4.825.633-1.366 1.5-2.4 2.6-3.1 1.1-.716 2.35-1.075 3.75-1.075s2.65.359 3.75 1.076c1.116.7 1.991 1.733 2.625 3.1.633 1.35.95 2.958.95 4.825 0 1.866-.317 3.483-.95 4.85-.634 1.35-1.509 2.383-2.625 3.1-1.1.7-2.35 1.05-3.75 1.05zm0-2.825c1.25 0 2.233-.516 2.95-1.55.733-1.033 1.1-2.575 1.1-4.625s-.367-3.591-1.1-4.625c-.717-1.033-1.7-1.55-2.95-1.55-1.234 0-2.217.517-2.95 1.55-.717 1.034-1.075 2.575-1.075 4.625s.358 3.592 1.075 4.625c.733 1.034 1.716 1.55 2.95 1.55z"
        />
      </svg>
    );
  }
  return (
    <svg
      className={clsx(classes.root, className)}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4126 12.3076H66.4046C67.0151 12.3076 67.5086 12.8022 67.5086 13.4116V66.4036C67.5086 67.0141 67.0151 67.5076 66.4046 67.5076H13.4126C12.8032 67.5076 12.3086 67.0141 12.3086 66.4036V13.4116C12.3086 12.8022 12.8032 12.3076 13.4126 12.3076ZM65.3009 43.8665V14.5156H14.517V59.3225L30.2964 43.5431C30.7281 43.1114 31.4258 43.1114 31.8575 43.5431L41.0869 52.7725C41.141 52.6279 41.2205 52.491 41.3364 52.3751L56.7924 36.9191C57.2241 36.4874 57.9218 36.4874 58.3535 36.9191L65.3009 43.8665ZM14.517 62.4447L31.077 45.8847L50.4919 65.2996H14.517V62.4447ZM53.6135 65.2997H65.3004V46.5317C65.0178 46.5317 64.7351 46.4235 64.5199 46.2082L57.5724 39.2607L42.8969 53.9362C42.781 54.0532 42.6441 54.1316 42.4995 54.1857L53.6135 65.2997Z"
        fill="#6D6D6D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8037 28.8676C38.8037 33.1291 35.3371 36.5956 31.0757 36.5956C26.8142 36.5956 23.3477 33.1291 23.3477 28.8676C23.3477 24.6062 26.8142 21.1396 31.0757 21.1396C35.3371 21.1396 38.8037 24.6062 38.8037 28.8676ZM36.596 28.8676C36.596 25.8239 34.1197 23.3476 31.076 23.3476C28.0323 23.3476 25.556 25.8239 25.556 28.8676C25.556 31.9114 28.0323 34.3876 31.076 34.3876C34.1197 34.3876 36.596 31.9114 36.596 28.8676Z"
        fill="#6D6D6D"
      />
    </svg>
  );
};

export default BasketLargeEmpty;
