import React, { forwardRef, MutableRefObject } from 'react';
import { makeStyles } from '@material-ui/core';
import ItemCount from 'common/components/ItemCount';
import { priceFromCents } from 'common/lib/commercetools';
import Txt from 'common/components/Txt';
import i18n from 'common/providers/i18n';
import { useCart } from 'common/hooks/useCart';
import clsx from 'clsx';
import UpChevron from 'common/icons/ChevronUp';
import ChevronDown from 'common/icons/ChevronDown';

interface Props {
  className?: string;
  handleClick: () => void;
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'flex-end',
    overflow: 'hidden',
  },
  headerText: {
    color: theme.palette.secondary.contrastText,
    paddingBottom: 0,
  },
  headerMargin: {
    marginRight: theme.spacing(2),
  },
  priceContainer: {
    display: 'flex',
    marginLeft: 'auto',
  },
  arrow: {
    '& path': {
      stroke: theme.palette.secondary.contrastText,
    },
  },
}));

/**
 * Reusable header for the Mobile Order Summary, has onClick functionality to open and close a dialog
 */
const MobileOrderSummaryHeader = forwardRef(
  (props: Props, ref: ((instance: HTMLElement | null) => void) | MutableRefObject<HTMLElement | null> | null) => {
    const { className, handleClick, open } = props;
    const classes = useStyles();
    const { cart } = useCart();
    const totalPrice = cart!.taxedPrice?.totalGross ?? cart!.totalPrice;

    return (
      <header
        role="button"
        onClick={handleClick}
        className={clsx(classes.root, className)}
        data-testid="mobile-order-summary-header"
        ref={ref}
      >
        <Txt variant="regular" fontWeight="bold" className={clsx(classes.headerText, classes.headerMargin)}>
          {i18n.t('OrderSummary')}
        </Txt>
        <ItemCount variant="regular" className={classes.headerText} />
        <div className={classes.priceContainer}>
          <Txt
            variant="regular"
            fontWeight="bold"
            className={classes.headerText}
            data-testid="mobile-order-summary-header-price"
          >
            {priceFromCents(totalPrice.centAmount)}
          </Txt>

          {open ? <ChevronDown className={classes.arrow} /> : <UpChevron className={classes.arrow} />}
        </div>
      </header>
    );
  }
);

MobileOrderSummaryHeader.displayName = 'MobileOrderSummaryHeader';

export default MobileOrderSummaryHeader;
