export type PaymentMethods = 'applePay' | 'googlePay' | 'paypal';
export type PaymentMethodsConfig = Record<PaymentMethods, boolean>;
export type TaxDisplay = 'NONE' | 'SIMPLE' | 'BREAKDOWN' | 'BREAKDOWN_COLLAPSED';

export type Theme =
  | 'robertsradio'
  | 'morphy-richards'
  | 'whitelabel'
  | 'convectair'
  | 'cadet'
  | 'dimplex'
  | 'faber'
  | 'nectre'
  | 'gdam';
export const gdamThemes = ['convectair', 'cadet', 'dimplex', 'faber', 'nectre', 'gdam'];
export const themes = ['robertsradio', 'morphy-richards', 'whitelabel', ...gdamThemes];
export const isTheme = (str: string): str is Theme => {
  return themes.includes(str);
};

export interface Features {
  minimumStockQuantity: number; // addToCart shows as active when stock is above this level
  displayTaxes: TaxDisplay;
  displayProductBrand: boolean;
  externalTax: boolean;
  enableCartOptimisticResponse: boolean;
  showOutOfStock: boolean; // show out of stock when out of stock on the Price component, or just show price
  use3DS: boolean; // whether 3ds secure should be used for checkout
  allowPOBoxes: boolean; // whether POBoxes are allowed as shipping addresses
  displayLinkToPDP: boolean; // show links to PDP for line items in quick view, cart and checkout summary
  crossSelling: boolean; // show cross-sell add-on component
  marketingOptInExplicit: boolean; // whether marketing opt-in has both "yes" and "no" options, or just "yes"
  termsAndConditionsCheckbox: boolean; // whether T&C displays as a checkbox or not
}

export interface TextKeys {
  phoneContext?: string;
  deliveryCountryContext?: string;
  deliveryPostalCodeContext?: string;
  findRetailer?: string;
  noSupplyChannelError?: string;
}
export interface BaseConfig {
  authHost: string;
  paymentEnvironment: 'test' | 'production';
  paymentMethods: PaymentMethodsConfig;
  host: string;
  locale: string;
  loqateApiKey: string;
  bazaarvoiceUrl: string;
  sentryDSN?: string;
  cookieDomain?: string;
  theme: Theme;
  urls: {
    basket: string;
    catalog: string;
    checkout: string;
    confirmation: string;
    getInTouch: string;
    privacyPolicy: string;
    shippingAndReturns: string;
    termsConditions: string;
    retailer: string;
    upsellOverview: string;
  };
  features: Features;
  textKeys: TextKeys;
  showCrossSellCta: boolean;
}
export interface CommercetoolsConfig {
  clientId: string;
  clientSecret: string;
  store: string;
  projectKey: string;
}

export interface Config extends BaseConfig {
  api: string;
  brandName: string;
  country: string;
  supportedCountries: string[];
  state?: string | null;
  commercetools: CommercetoolsConfig;
  currency: string;
  googleMerchantId?: string;
  countryMapping?: {
    [key: string]: {
      locale?: string;
      currency: string;
      supportedCountries: string[];
      commercetools: CommercetoolsConfig;
      features?: Features;
      textKeys?: TextKeys;
    };
  };
}

class ConfigWrapper implements Config {
  constructor(private _source: Config) {}
  get country() {
    return this._source.country;
  }
  get api() {
    return this._source.api;
  }
  get brandName() {
    return this._source.brandName;
  }
  get state() {
    return this._source.state;
  }
  get googleMerchantId() {
    return this._source.googleMerchantId;
  }
  get countryMapping() {
    return this._source.countryMapping;
  }
  get authHost() {
    return this._source.authHost;
  }
  get paymentEnvironment() {
    return this._source.paymentEnvironment;
  }
  get paymentMethods() {
    return this._source.paymentMethods;
  }
  get host() {
    return this._source.host;
  }
  get loqateApiKey() {
    return this._source.loqateApiKey;
  }
  get bazaarvoiceUrl() {
    return this._source.bazaarvoiceUrl;
  }
  get sentryDSN() {
    return this._source.sentryDSN;
  }
  get cookieDomain() {
    return this._source.cookieDomain;
  }
  get theme() {
    return this._source.theme;
  }
  get urls() {
    return this._source.urls;
  }
  get showCrossSellCta() {
    return this._source.showCrossSellCta;
  }

  private get countryConfig() {
    const mapping = this._source.countryMapping;
    if (!mapping) {
      return undefined;
    }
    return mapping[this.country];
  }

  public get locale() {
    return this.countryConfig?.locale || this._source.locale;
  }

  public set locale(val: string) {
    this._source.locale = val;
  }

  public get currency() {
    return this.countryConfig?.currency || this._source.currency;
  }

  public set currency(val: string) {
    this._source.currency = val;
  }

  public get supportedCountries() {
    return this.countryConfig?.supportedCountries || this._source.supportedCountries;
  }

  public set supportedCountries(val: string[]) {
    this._source.supportedCountries = val;
  }

  public get features() {
    return this.countryConfig?.features || this._source.features;
  }

  public set features(val: Features) {
    this._source.features = val;
  }

  public get textKeys() {
    return this.countryConfig?.textKeys || this._source.textKeys;
  }

  public set textKeys(val: TextKeys) {
    this._source.textKeys = val;
  }

  public get commercetools() {
    return this.countryConfig?.commercetools || this._source.commercetools;
  }

  public set commercetools(val: CommercetoolsConfig) {
    this._source.commercetools = val;
  }
}

let configWrapper: ConfigWrapper;

export function initConfig(config: Config, globalProps: Record<string, any>) {
  config.urls.shippingAndReturns = globalProps.shippingAndReturnsUrl || config.urls.shippingAndReturns;
  config.urls.getInTouch = globalProps.getInTouchUrl || config.urls.getInTouch;
  config.urls.privacyPolicy = globalProps.privacyPolicyUrl || config.urls.privacyPolicy;
  config.urls.termsConditions = globalProps.termsConditionsUrl || config.urls.termsConditions;
  config.urls.basket = globalProps.basketUrl || config.urls.basket;
  config.urls.checkout = globalProps.checkoutUrl || config.urls.checkout;
  config.urls.confirmation = globalProps.confirmationUrl || config.urls.confirmation;
  config.urls.catalog = globalProps.catalogUrl || config.urls.catalog;
  config.urls.retailer = globalProps.retailerUrl || config.urls.retailer;
  config.urls.upsellOverview = globalProps.upsellOverview || config.urls.upsellOverview;

  config.cookieDomain = globalProps.cookieDomain || config.cookieDomain;
  config.locale = globalProps.locale || config.locale;
  config.brandName = globalProps.brandName || config.brandName;
  config.country = globalProps.country || config.country;
  config.state = globalProps.state || config.state;
  config.showCrossSellCta =
    (globalProps.showCrossSellCta ? globalProps.showCrossSellCta === 'true' : false) || config.showCrossSellCta;

  if (globalProps.theme) {
    if (isTheme(globalProps.theme)) {
      config.theme = globalProps.theme;
      console.info(`Theme: ${config.theme}`);
    } else {
      console.error(`Invalid theme '${globalProps.theme}'. Choose one of the following:\n- ${themes.join('\n- ')}`);
    }
  }

  if (!config.country || config.country === '') {
    throw new Error('Application could not be loaded: country must be specified.');
  }

  if (process.env.NODE_ENV === 'development') {
    console.debug('Loading GlenDimplex frontend app with the following context');
    console.debug(`  Project: ${config.commercetools.projectKey}`);
    console.debug(`  Store: ${config.commercetools.store}`);
    console.debug(`  Locale: ${config.locale}`);
    console.debug(`  Country: ${config.country}`);
    console.debug(`  State: ${config.state}`);
    console.debug(`  Currency: ${config.currency}`);
    console.debug(`  PaymentMethods: ${JSON.stringify(config.paymentMethods)}`);
    console.debug(`  Theme: ${config.theme}`);
  }

  configWrapper = new ConfigWrapper(config);
  return configWrapper;
}

export default () => configWrapper;
