import React, { useState, FormEvent, ChangeEvent, useEffect, useRef } from 'react';
import Txt from '../Txt';
import i18n from 'common/providers/i18n';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Input from '../formComponents/Input';
import { UPDATE_ACTIVE_CART } from 'common/queries';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import FormField from '../formComponents/FormField';
import ArrowRightIcon from 'common/icons/ArrowRightIcon';
import { analytics } from 'common/analytics/actions';
import { useCart } from 'common/hooks/useCart';
import { updateActiveCart } from 'common/interfaces/generated/updateActiveCart';

interface Props {}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    cursor: 'pointer',
    height: theme.typography.body1.lineHeight,
    position: 'absolute',
    right: theme.spacing(3),
  },
}));

const OrderSummaryPromoCode = (props: Props): JSX.Element => {
  const { cart } = useCart();
  const { id: cartId } = cart!;
  const [updateMyCart, { data }] = useGraphqlMutation<updateActiveCart>(UPDATE_ACTIVE_CART);
  const [promoCode, setPromoCode] = useState('');
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (data) setIsValid(true);
  }, [data]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    innerSubmit();
  };
  const innerSubmit = () => {
    updateMyCart({
      variables: {
        actions: [
          {
            addDiscountCode: {
              code: promoCode,
              validateDuplicates: true,
            },
          },
        ],
      },
      update: () => {
        analytics.setPromoCodeSuccess(cartId, promoCode);
      },
    }).catch((e) => {
      setIsValid(false);
      analytics.setPromoCodeFailed(cartId, promoCode);
    });
  };

  return (
    <div className={classes.root} data-testid="order-summary-promo-code">
      {open ? (
        <form data-testid="order-summary-promo-code-open" onSubmit={onSubmit}>
          <FormField
            label={i18n.t('IHaveAPromoCode')!}
            required
            valid={isValid}
            error={isValid === false ? i18n.t('errors.invalidPromoCode')! : ''}
            icon={
              <ArrowRightIcon
                className={classes.icon}
                data-testid="order-summary-promo-code-submit-arrow"
                onClick={innerSubmit}
              />
            }
          >
            <Input
              autoFocus={true}
              data-testid="order-summary-promo-code-input"
              value={promoCode}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPromoCode(e.target.value);
                setIsValid(undefined);
              }}
            />
          </FormField>
        </form>
      ) : (
        <div data-testid="order-summary-promo-code-closed" onClick={() => setOpen(true)}>
          <Txt variant="link" className={clsx(classes.link)}>
            {i18n.t('IHaveAPromoCode')}
          </Txt>
        </div>
      )}
    </div>
  );
};

export default OrderSummaryPromoCode;
