import { useEffect, useState } from 'react';

const useScript = (url: string, attributes?: object) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.addEventListener('load', () => {
      setLoaded(true);
    });
    if (attributes) {
      Object.entries(attributes).forEach(([k, v]) => {
        script.setAttribute(k, v);
      });
    }
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return loaded;
};

export default useScript;
