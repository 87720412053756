// eslint-disable-file eslint(import/no-named-as-default-member)
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useCart } from 'common/hooks/useCart';
import { useNavigate } from 'react-router-dom';
import { checkoutPaths, useLoading } from 'modules/Checkout/Checkout';
import { makeStyles } from '@material-ui/core';
import CheckoutPaymentAddress from './components/CheckoutPaymentAddress';
import CheckoutPaymentCreditcard from './components/CheckoutPaymentCreditcard';
import { AddressInput } from 'common/interfaces/generated/globalTypes';
import TermsConditions from './components/TermsConditions';
import { analytics } from 'common/analytics/actions';
import { Clients } from './BraintreeHelpers';
import Loader from 'common/components/Loader';
import SubmitBraintreeButton from './components/SubmitBraintreeButton';
import { getAnalyticsInfoFromLineItems } from 'common/lib/cart';
import { getActiveCart_inStore_me_activeCart } from 'common/interfaces/generated/getActiveCart';
import PayButtons from './components/PayButtons';
import useUpdateBillingAddress from 'common/hooks/useUpdateBillingAddress';
import { useCheckPrices } from 'common/hooks/useCheckPrices';
import i18n from 'common/providers/i18n';
import { getIsQuickCheckout } from 'common/lib/helpers';
import getConfig from 'config';
import TermsConditionsCheckbox from './components/TermsConditionsCheckbox';

interface Props {
  braintreeClients?: Clients;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 450,
    position: 'relative',
  },
  termsConditions: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),
  },
  termsConditionsCheckbox: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(3),
  },
}));

export interface BraintreeValidationError {
  cvv: string;
  cardNumber: string;
  expirationDate: string;
}

const CheckoutPayment = (props: Props): JSX.Element => {
  const { braintreeClients } = props;
  const { loading, setLoading } = useLoading();
  const classes = useStyles();
  const { cart } = useCart();
  const { id: cartId, lineItems } = cart!;
  const navigate = useNavigate();
  const addressFormEl = useRef(null);
  const [isAddressFormValid, setIsAddressFormValid] = useState(true);
  const showTermsAndConditionsCheckbox = getConfig().features.termsAndConditionsCheckbox;
  const [termsAccepted, setTermsAccepted] = useState(!showTermsAndConditionsCheckbox);
  const isQuickCheckout = getIsQuickCheckout();
  const checkPrices = useCheckPrices();

  const updateBillingAddress = useUpdateBillingAddress();
  const [submitCreditCardPayment, setSubmitCreditCardPayment] = useState<
    ((cart: getActiveCart_inStore_me_activeCart) => void) | undefined
  >(undefined);

  if (!cart?.shippingAddress || !cart?.shippingInfo) navigate(checkoutPaths.delivery, { replace: true });

  useEffect(() => {
    checkPrices();
    setLoading(true);
    analytics.viewElement(cartId, 'checkoutPayment', getAnalyticsInfoFromLineItems(lineItems));
    if (window.scrollTo) window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (braintreeClients) {
      setLoading(false);
    }
  }, [braintreeClients]);

  const setSubmit = (submit: (cart: getActiveCart_inStore_me_activeCart) => void) => {
    setSubmitCreditCardPayment(() => submit);
  };

  const onSubmitAddressForm = useCallback(
    async (newAddress?: AddressInput) => {
      setLoading(true);
      const res = await updateBillingAddress(newAddress);
      if (submitCreditCardPayment && res?.updateMyCart) {
        submitCreditCardPayment(res?.updateMyCart);
      }
    },
    [submitCreditCardPayment]
  );

  return (
    <form
      className={classes.root}
      data-testid="checkout-payment"
      onSubmit={(e) => {
        e.preventDefault();
        return onSubmitAddressForm();
      }}
    >
      {loading && <Loader />}
      <CheckoutPaymentCreditcard
        clients={braintreeClients}
        isAddressFormValid={isAddressFormValid}
        termsAccepted={termsAccepted}
        setSubmitCreditCardPayment={setSubmit}
      />
      <CheckoutPaymentAddress
        formRef={addressFormEl}
        onValidate={setIsAddressFormValid}
        onSubmit={onSubmitAddressForm}
      />
      {showTermsAndConditionsCheckbox ? (
        <TermsConditionsCheckbox
          className={classes.termsConditionsCheckbox}
          termsAccepted={termsAccepted}
          setTermsAccepted={setTermsAccepted}
        />
      ) : (
        <TermsConditions className={classes.termsConditions} />
      )}
      <SubmitBraintreeButton />
      {isQuickCheckout && (
        <PayButtons braintreeClients={braintreeClients} variant="fullCheckout" dividerText={i18n.t('OR')} />
      )}
    </form>
  );
};

export default CheckoutPayment;
