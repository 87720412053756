import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import i18n from 'common/providers/i18n';
import clsx from 'clsx';
import { useCart } from 'common/hooks/useCart';
import { Clients, useSubmitNonce } from '../BraintreeHelpers';
import getConfig from 'config';
import { useErrorDialog } from 'common/hooks/useErrorDialog';
import { analytics, PaymentMethod } from 'common/analytics/actions';
import { useLoading } from 'modules/Checkout/Checkout';
import useUpdateBillingAddress from 'common/hooks/useUpdateBillingAddress';
import useUpdateEamilShippingBilling from 'common/hooks/useUpdateEmailShippingBilling';
import { PaymentVariant } from 'common/interfaces/enums';
import { applePay } from 'braintree-web';
import { getHub } from 'common/interfaces/sentry-hub';

interface Props {
  className?: string;
  clients?: Clients;
  variant: PaymentVariant;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#000',
    height: 40,
    marginBottom: theme.spacing(3),
    '@supports (-webkit-appearance: -apple-pay-button)': {
      '& .apple-pay-button-with-text': {
        width: '100% !important',
        height: 40,
        borderRadius: '0px !important',
        maxWidth: '450px !important',
        display: 'inline-block',
        '-webkit-appearance': '-apple-pay-button',
        '-apple-pay-button-type': 'buy',
      },
      '& .apple-pay-button-with-text > *': {
        display: 'none',
      },
      '& .apple-pay-button-black-with-text': {
        '-apple-pay-button-style': 'black',
      },
      '& .apple-pay-button-white-with-text': {
        '-apple-pay-button-style': 'white',
      },
      '& .apple-pay-button-white-with-line-with-text': {
        '-apple-pay-button-style': 'white-outline',
      },
    },
    '@supports not (-webkit-appearance: -apple-pay-button)': {
      '& .apple-pay-button-with-text': {
        width: '100% !important',
        maxWidth: '450px !important',
        '--apple-pay-scale': 1 /* (height / 32) */,
        display: 'inline-flex',
        justifyContent: 'center',
        fontSize: 12,
        borderRadius: 0,
        padding: 0,
        boxSizing: 'border-box',
        minWidth: 200,
        height: 40,
      },
      '& .apple-pay-button-black-with-text': {
        backgroundColor: 'black',
        color: 'white',
      },
      '& .apple-pay-button-white-with-text': {
        backgroundColor: 'white',
        color: 'black',
      },
      '& .apple-pay-button-white-with-line-with-text': {
        backgroundColor: 'white',
        color: 'black',
        border: '.5px solid black',
      },
      '& .apple-pay-button-with-text.apple-pay-button-black-with-text > .logo ': {
        backgroundImage: '-webkit-named-image(apple-pay-logo-white)',
        backgroundColor: 'black',
      },
      '& .apple-pay-button-with-text.apple-pay-button-white-with-text > .logo': {
        backgroundImage: '-webkit-named-image(apple-pay-logo-black)',
        backgroundColor: 'white',
      },
      '& .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text > .logo': {
        backgroundImage: '-webkit-named-image(apple-pay-logo-black)',
        backgroundColor: 'white',
      },
      '& .apple-pay-button-with-text > .text ': {
        fontFamily: '-apple-system',
        fontSize: 'calc(1em * var(--apple-pay-scale))',
        fontWeight: 300,
        alignSelf: 'center',
        marginRight: 'calc(2px * var(--apple-pay-scale))',
      },
      '& .apple-pay-button-with-text > .logo': {
        width: 'calc(35px * var(--scale))',
        height: '100%',
        backgroundSize: '100% 60%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0 50%',
        marginLeft: 'calc(2px * var(--apple-pay-scale))',
        border: 'none',
      },
    },
  },
}));

const ApplePayButton = (props: Props): JSX.Element | null => {
  const { className, clients, variant } = props;
  const isQuickCheckout = variant === 'quickCheckout';
  const classes = useStyles();
  const { cart } = useCart();
  const [appleClient, setAppleClient] = useState<any | undefined>(undefined);
  const errorDialog = useErrorDialog();
  const submitNonce = useSubmitNonce();
  const { setLoading } = useLoading();
  const updateBillingAddress = useUpdateBillingAddress();
  const setEmailShippingBilling = useUpdateEamilShippingBilling();

  useEffect(() => {
    if (!clients || appleClient) return;

    const init = async () => {
      let appClient;
      if (window.ApplePaySession && ApplePaySession?.canMakePayments()) {
        // @ts-ignore
        appClient = await applePay.create({
          client: clients.braintreeClient,
        });
      }
      if (appClient) setAppleClient(appClient);
    };

    init();
  }, [clients, appleClient]);

  useEffect(() => {
    if (
      !appleClient ||
      !window.ApplePaySession ||
      !ApplePaySession?.supportsVersion(3) ||
      !ApplePaySession?.canMakePayments()
    ) {
      const button = document.getElementById('apple-pay-container');
      if (button) button.style.display = 'none';
      return;
    }

    const paymentRequestOptions: any = {
      countryCode: getConfig().country,
      currencyCode: getConfig().currency,
      total: {
        label: getConfig().brandName,
        amount: (cart!.taxedPrice?.totalGross.centAmount / 100).toString(),
      },
      requiredBillingContactFields: ['postalAddress'],
    };
    if (isQuickCheckout) paymentRequestOptions.requiredShippingContactFields = ['email', 'postalAddress', 'name'];
    const paymentRequest = appleClient.createPaymentRequest(paymentRequestOptions);

    const onClick = () => {
      setLoading(true);
      const session = new ApplePaySession(3, paymentRequest);

      session.onvalidatemerchant = async (event: any) => {
        try {
          const merchantSession = await appleClient.performValidation({
            validationURL: event.validationURL,
            displayName: getConfig().brandName,
          });
          session.completeMerchantValidation(merchantSession);
        } catch (err) {
          getHub()?.captureException(err);
          errorDialog(i18n.t('errors.tryDifferentPaymentMethod'));
          session.abort();
          setLoading(false);
        }
      };

      session.oncancel = async () => {
        setLoading(false);
      };

      session.onpaymentauthorized = async (event: any) => {
        const appleBillingAddress = event.payment.billingContact;

        const billingAddress = {
          firstName: appleBillingAddress.givenName,
          lastName: appleBillingAddress.familyName,
          streetName: appleBillingAddress.addressLines.length && appleBillingAddress.addressLines[0],
          additionalStreetInfo:
            (appleBillingAddress.addressLines.length > 1 && appleBillingAddress.addressLines[1]) || undefined,
          postalCode: appleBillingAddress.postalCode,
          city: appleBillingAddress.locality,
          country: appleBillingAddress.countryCode,
        };

        let data;
        if (isQuickCheckout) {
          const appleShippingAddress = event.payment.shippingContact;
          const shippingAddress = {
            firstName: appleShippingAddress.givenName,
            lastName: appleShippingAddress.familyName,
            streetName: appleShippingAddress.addressLines.length && appleShippingAddress.addressLines[0],
            additionalStreetInfo:
              (appleShippingAddress.addressLines.length > 1 && appleShippingAddress.addressLines[1]) || undefined,
            postalCode: appleShippingAddress.postalCode,
            city: appleShippingAddress.locality,
            country: appleShippingAddress.countryCode,
          };
          const email = event.payment.shippingContact.emailAddress;
          const shippingCountry = shippingAddress.country.toUpperCase();
          if (!getConfig().supportedCountries.includes(shippingCountry)) {
            setLoading(false);
            errorDialog(
              i18n.t('errors.noShippingForCountry', {
                shippingCountries: getConfig()
                  .supportedCountries.map((country) => i18n.t(`countriesLong.${country}`))
                  .join(', '),
              })
            );
            return;
          }

          data = await setEmailShippingBilling(email, shippingAddress, billingAddress);
        } else {
          data = await updateBillingAddress(billingAddress);
        }

        try {
          const payload = await appleClient.tokenize({ token: event.payment.token });
          session.completePayment(ApplePaySession.STATUS_SUCCESS);
          if (data?.updateMyCart) {
            analytics.pay(data.updateMyCart.id, PaymentMethod.APPLE_PAY, variant);
            submitNonce(
              payload.nonce,
              clients,
              data.updateMyCart,
              data.updateMyCart.version,
              data.updateMyCart.billingAddress
            );
          }
        } catch (err) {
          getHub()?.captureException(err);
          session.completePayment(ApplePaySession.STATUS_FAILURE);
          errorDialog(i18n.t('errors.tryDifferentPaymentMethod'));
        }
      };
      session.begin();
    };

    const button = document.getElementById('apple-pay-container');
    if (button) button.onclick = onClick;
  }, [cart, appleClient]);

  if (!window.ApplePaySession || !ApplePaySession || !appleClient) return null;

  return (
    <div className={clsx(classes.root, className)}>
      <div id="apple-pay-container" className="apple-pay-button-with-text apple-pay-button-black-with-text">
        <span className="text">{i18n.t('BuyWith')}</span>
        <span className="logo"></span>
      </div>
    </div>
  );
};

export default ApplePayButton;
