import React from 'react';
import { StyleProvider } from './StyleProvider';
import { ThemeProvider } from '@material-ui/core';
import { ApolloProvider } from '@apollo/client';
import theme from 'common/themes';
import ErrorBoundary from './ErrorBoundary';
import ErrorDialog from './ErrorDialog';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import Snackbar from 'common/components/Snackbar';
import { getApolloClient } from 'common/lib/apollo/apolloClient';

interface Props {
  children?: React.ReactNode;
}

const Providers = ({ children }: Props): JSX.Element => {
  const client = getApolloClient();

  return (
    <ThemeProvider theme={theme}>
      <StyleProvider>
        <ErrorDialog>
          <ErrorBoundary>
            <ApolloProvider client={client}>
              <BrowserRouter>
                <Snackbar />
                {children}
              </BrowserRouter>
            </ApolloProvider>
          </ErrorBoundary>
        </ErrorDialog>
      </StyleProvider>
    </ThemeProvider>
  );
};

export default Providers;
