import { getActiveCart_inStore_me_activeCart } from 'common/interfaces/generated/getActiveCart';
import { useState } from 'react';
import { client, threeDSecure, dataCollector, Client, DataCollector } from 'braintree-web';
import { AddressInput, SnackbarType } from 'common/interfaces/generated/globalTypes';
import { analytics } from 'common/analytics/actions';
import getConfig from 'config';
import i18n from 'common/providers/i18n';
import axios from 'axios';
import { useApolloClient } from '@apollo/client';
import { GET_ACTIVE_CART } from 'common/queries';
import { useErrorDialog } from 'common/hooks/useErrorDialog';
import { checkoutPaths, useLoading } from 'modules/Checkout/Checkout';
import { redirect } from 'common/lib/helpers';
import useSnackbar from 'common/hooks/useSnackbar';
import { getHub } from 'common/interfaces/sentry-hub';

export interface Clients {
  braintreeClient: Client;
  dataCollector: DataCollector;
  threeDSecure: any;
}

export const useSubmitNonce = () => {
  const refetchCart = useRefetchCart();
  const handlePaymentErrorCodes = useHandlePaymentErrorCodes();
  const { setLoading } = useLoading();
  const client = useApolloClient();

  return async (
    nonce: string,
    clients: any,
    cart: getActiveCart_inStore_me_activeCart,
    version: number,
    billingAddress?: AddressInput | null,
    authenticationId: String = ''
  ) => {
    try {
      const deviceData = clients?.dataCollector?.deviceData;
      const res = await axios.post(
        `${getConfig().api}/payment/complete`,
        {
          payment_nonce: nonce,
          device_data: deviceData,
          cart_id: cart.id,
          cart_version: version,
          authentication_id: authenticationId,
        },
        {
          headers: { 'Content-type': 'application/json' },
          timeout: 30 * 1000,
        }
      );

      // creating order causes the activecart to be closed. remove it from cache.
      client.clearStore();

      const route = `${getConfig().urls.confirmation}?id=${res.data.order.order_number}`;
      redirect(route);
    } catch (err: any) {
      const oldCartId = cart.id;
      // during call to complete the cart has been turned into an order.
      // when an error occured, the backend created a new cart and set is as activeCart
      // we need to update the cache to reflect this change
      const newCart = await refetchCart();
      analytics.paymentFailed(oldCartId, newCart.id);
      const hub = getHub();
      if (hub) {
        hub.withScope((scope) => scope.setExtra('type', 'submit nonce failed'));
        hub.captureException(err);
      }

      setLoading(false);
      if (err?.response?.data?.code)
        handlePaymentErrorCodes(err.response.data.code || err.response.data.transaction?.error_code || 'generic_error');
    }
  };
};

export const useClients = (): [Clients?, ((token: string) => Promise<Clients>)?] => {
  const [clients, setClients] = useState<Clients | undefined>(undefined);
  const { setLoading } = useLoading();

  const initClients = async (token: string) => {
    const braintreeClient = await client.create({
      authorization: token,
    });

    const threeDSecurePromise = getConfig().features.use3DS
      ? threeDSecure.create({
          client: braintreeClient,
          version: 2,
        })
      : undefined;

    const dataCollectorPromise = dataCollector.create({
      client: braintreeClient,
      kount: true,
      paypal: false,
    });

    const [localDataCollector, localThreeDSecure] = await Promise.all([dataCollectorPromise, threeDSecurePromise]);

    const localClients = {
      braintreeClient,
      dataCollector: localDataCollector,
      threeDSecure: localThreeDSecure,
    };

    setClients(localClients);
    setLoading(false);
    return localClients;
  };
  return [clients, initClients];
};

const useRefetchCart = () => {
  const client = useApolloClient();
  return async () => {
    const res = await client.query({
      query: GET_ACTIVE_CART,
      variables: {
        storeKey: getConfig().commercetools.store,
      },
    });
    return res.data?.inStore?.me?.activeCart;
  };
};

const useHandlePaymentErrorCodes = () => {
  const errorDialog = useErrorDialog();
  const snackbar = useSnackbar();

  return (errorCode: string) => {
    let clusteredErrorCode;
    if (errorCode.startsWith('processor_')) {
      const number = +errorCode.replace('processor_', '');
      if (number > 2100 && number < 3001) {
        clusteredErrorCode = 'processor_2999';
      } else {
        clusteredErrorCode = errorCode;
      }
    } else {
      clusteredErrorCode = errorCode;
    }

    switch (clusteredErrorCode) {
      case 'processor_2000': // Do Not Honor
      case 'processor_2001': // Insufficient Funds
      case 'processor_2002': // Limit Exceeded
      case 'processor_2003': // Cardholder's Activity Limit Exceeded
      case 'processor_2004': // Expired Card
      case 'processor_2007': // No Account
      case 'processor_2009': // No Such Issuer
      case 'processor_2011': // Voice Authorization Required
      case 'processor_2012': // Processor Declined – Possible Lost Card
      case 'processor_2013': // Processor Declined – Possible Stolen Card
      case 'processor_2014': // Processor Declined – Fraud Suspected
      case 'processor_2015': // Transaction Not Allowed
      case 'processor_2016': // Duplicate Transaction
      case 'processor_2019': // Invalid Transaction
      case 'processor_2020': // Violation
      case 'processor_2021': // Security Violation
      case 'processor_2022': // Declined – Updated Cardholder Available
      case 'processor_2023': // Processor Does Not Support This Feature
      case 'processor_2024': // Card Type Not Enabled
      case 'processor_2025': // Set Up Error – Merchant
      case 'processor_2026': // Invalid Merchant ID
      case 'processor_2027': // Set Up Error – Amount
      case 'processor_2028': // Set Up Error – Hierarchy
      case 'processor_2029': // Set Up Error – Card
      case 'processor_2030': // Set Up Error – Terminal
      case 'processor_2031': // Encryption Error
      case 'processor_2032': // Surcharge Not Permitted
      case 'processor_2033': // Inconsistent Data
      case 'processor_2035': // Partial Approval For Amount In Group III Version
      case 'processor_2036': // Authorization could not be found
      case 'processor_2038': // Processor Declined
      case 'processor_2040': // Invalid Store
      case 'processor_2041': // Declined – Call For Approval
      case 'processor_2042': // Invalid Client ID
      case 'processor_2043': // Error – Do Not Retry, Call Issuer
      case 'processor_2044': // Declined – Call Issuer
      case 'processor_2045': // Invalid Merchant Number
      case 'processor_2046': // Declined
      case 'processor_2047': // Call Issuer.Pick Up Card
      case 'processor_2048': // Invalid Amount
      case 'processor_2050': // Invalid Credit Plan
      case 'processor_2051': // Credit Card Number does not match method of payment
      case 'processor_2053': // Card reported as lost or stolen
      case 'processor_2055': // Invalid Transaction Division Number
      case 'processor_2056': // Transaction amount exceeds the transaction division limit
      case 'processor_2057': // Issuer or Cardholder has put a restriction on the card
      case 'processor_2058': // Merchant not Mastercard SecureCode enabled
      case 'processor_2059': // Address Verification Failed
      case 'processor_2060': // Address Verification and Card Security Code Failed
      case 'processor_2061': // Invalid Transaction Data
      case 'processor_2062': // Invalid Tax Amount
      case 'processor_2063': // PayPal Business Account
      case 'processor_2064': // Invalid Currency Code
      case 'processor_2066': // PayPal Business Account Restricted
      case 'processor_2068': // PayPal Business Account Locked or Closed
      case 'processor_2069': // PayPal Blocking Duplicate Order IDs
      case 'processor_2071': // PayPal Payee Account Invalid Or Does Not Have a Confirmed Email
      case 'processor_2073': // PayPal Validation Error
      case 'processor_2074': // Funding Instrument In The PayPal Account Was Declined By The Processor Or Bank, Or It Can't Be Used For This Payment
      case 'processor_2075': // Payer Account Is Locked Or Closed
      case 'processor_2076': // Payer Cannot Pay For This Transaction With PayPal
      case 'processor_2077': // Transaction Refused Due To PayPal Risk Model
      case 'processor_2079': // PayPal Merchant Account Configuration Error
      case 'processor_2081': // PayPal pending payments are not supported
      case 'processor_2082': // PayPal Domestic Transaction Required
      case 'processor_2083': // PayPal Phone Number Required
      case 'processor_2084': // PayPal Tax Info Required
      case 'processor_2085': // PayPal Payee Blocked Transaction
      case 'processor_2086': // PayPal Transaction Limit Exceeded
      case 'processor_2087': // PayPal reference transactions not enabled for your account
      case 'processor_2088': // Currency not enabled for your PayPal seller account
      case 'processor_2089': // PayPal payee email permission denied for this request
      case 'processor_2091': // Currency of this transaction must match currency of your PayPal account
      case 'processor_2092': // No Data Found - Try Another Verification Method
      case 'processor_2094': // PayPal payment has already been completed
      case 'processor_2096': // PayPal buyer account can't be the same as the seller account
      case 'processor_2097': // PayPal authorization amount limit exceeded
      case 'processor_2098': // PayPal authorization count limit exceeded
      case 'processor_2100': // PayPal channel initiated billing not enabled for your account
      case 'processor_2999': // Clustering for many processor unavailable codes
      case 'processor_3000': // Processor Network Unavailable – Try Again
      case 'gateway_application_incomplete':
      case 'gateway_avs':
      case 'gateway_avs_and_cvv':
      case 'gateway_duplicate':
      case 'gateway_fraud':
      case 'gateway_risk_threshold':
      case 'gateway_three_d_secure':
      case 'gateway_token_issuance':
      case 'ct_resource_not_found':
      case 'ct_feature_removed':
      case 'ct_invalid_json_input':
      case 'ct_invalid_operation':
      case 'ct_invalid_field':
      case 'ct_extension_bad_response':
      case 'ct_extension_no_response':
      case 'ct_extension_update_actions_failed':
      case 'ct_discount_code_non_applicable':
      case 'ct_shipping_method_does_not_match_cart':
      case 'ct_invalid_item_shipping_details':
      case 'ct_matching_price_not_found':
      case 'ct_concurrent_modification':
      case 'ct_missing_tax_rate_for_country': {
        redirect(
          `${getConfig().urls.confirmation}?errorMsg=${i18n.t('errors.tryDifferentPaymentMethod')}&returnUrl=${
            getConfig().urls.checkout
          }%23${checkoutPaths.payment}`
        );
        return;
      }

      case 'processor_2005': // Invalid Credit Card Number
      case 'processor_2006': // Invalid Expiration Date
      case 'processor_2008': // Card Account Length Error
      case 'processor_2010': // Card Issuer Declined CVV
      case 'processor_2034': // No Action Taken
      case 'processor_2039': // Invalid Authorization Code
      case 'processor_2067': // Authorization Expired
      case 'processor_2072': // PayPal Payee Email Incorrectly Formatted
      case 'processor_2093': // PayPal payment method is invalid
      case 'processor_2099': // Cardholder Authentication Required
      case 'gateway_cvv': {
        snackbar({ message: i18n.t('errors.fieldInputTryAgain'), type: SnackbarType.ERROR });
        return;
      }

      case 'ct_duplicate_field_with_conflicting_resource':
      case 'ct_duplicate_field':
      case 'ct_invalid_input':
      case 'ct_internal_constraint_violated':
      case 'ct_required_field': {
        errorDialog(i18n.t('errors.checkData'));
        return;
      }

      case 'processor_2049': {
        // Invalid SKU Number
        redirect(`${getConfig().urls.confirmation}?errorMsg=${i18n.t('errors.noLongerAvailable')}}`);
        return;
      }

      case 'ct_out_of_stock': {
        errorDialog(i18n.t('errors.outOfStock'));
        return;
      }

      case 'ct_price_changed': {
        errorDialog(i18n.t('errors.priceChanged'));
        return;
      }

      case 'ct_anonymous_id_already_in_use': {
        redirect(`${getConfig().urls.confirmation}?errorMsg=${i18n.t('errors.tryDifferentBrowser')}}`);
        return;
      }

      default: {
        errorDialog(i18n.t('errors.tryAgain'));
        return;
      }
    }
  };
};
