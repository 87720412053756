import { AddressInput } from 'common/interfaces/generated/globalTypes';
import { updateActiveCart } from 'common/interfaces/generated/updateActiveCart';
import { UPDATE_ACTIVE_CART } from 'common/queries';
import { useCart } from './useCart';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { analytics } from 'common/analytics/actions';

const useUpdateBillingAddress = () => {
  const [updateCart] = useGraphqlMutation<updateActiveCart>(UPDATE_ACTIVE_CART);
  const { cart } = useCart();
  const { id: cartId } = cart!;

  return async (newAddress?: AddressInput): Promise<updateActiveCart | undefined | null> => {
    const { data } = await updateCart({
      variables: {
        actions: [
          {
            setBillingAddress: newAddress ? { address: newAddress } : {},
          },
        ],
      },
      update: () => {
        if (newAddress) analytics.setBillingAddress(cartId);
      },
    });
    return data;
  };
};
export default useUpdateBillingAddress;
