import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import ResizeObserver from 'resize-observer-polyfill';

interface Props {
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minHeight: 'fit-content',
  },
  sticky: {
    width: '100%',
  },
}));

const Sticky = (props: Props): JSX.Element => {
  const { children, className } = props;
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const [tops, setTops] = useState<{ fixed: number | undefined; absolute: number | undefined } | undefined>(undefined);
  const resizeObserver = new ResizeObserver(() => handleScroll());

  useEffect(() => {
    setTops({ fixed: ref.current?.getBoundingClientRect().top, absolute: ref.current?.offsetTop });
    if (ref.current?.parentElement)
      ref.current.parentElement.style.minHeight = ref.current?.getBoundingClientRect().height + 'px';
  }, []);

  useEffect(() => {
    if (ref.current) resizeObserver.observe(ref.current);
    if (ref.current?.parentElement) resizeObserver.observe(ref.current.parentElement);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [tops]);

  const handleScroll = () => {
    if (!ref.current || tops?.fixed === undefined || tops?.absolute === undefined) return;

    const objectHeight = ref.current.getBoundingClientRect().height;

    const containerHeight = ref.current?.parentElement?.getBoundingClientRect().height ?? 0;
    const containerTop = ref.current?.parentElement?.getBoundingClientRect().top ?? 0;
    const containerSpacingTop = ref.current?.parentElement?.offsetTop ?? 0;
    const containerBottom = containerHeight + containerSpacingTop;

    // only scroll of the container is a bit bigger than the object, else it looks silly
    const minimumExceededSpace = 50;

    if (objectHeight + minimumExceededSpace >= containerHeight) {
      ref.current.style.position = 'static';
      return;
    }

    if (
      window.scrollY + tops.absolute + objectHeight >= containerBottom &&
      containerHeight > objectHeight + minimumExceededSpace
    ) {
      ref.current.style.position = 'absolute';
      ref.current.style.top = containerBottom - objectHeight + 'px';
      return;
    }

    if (window.scrollY <= containerTop - tops.fixed) {
      ref.current.style.position = 'absolute';
      ref.current.style.top = tops.absolute + 'px';
    }

    if (window.scrollY > containerTop - tops.fixed && containerHeight >= objectHeight + minimumExceededSpace) {
      ref.current.style.position = 'fixed';
      ref.current.style.top = tops.fixed + 'px';
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div ref={ref} className={classes.sticky}>
        {children}
      </div>
    </div>
  );
};

export default Sticky;
