import { init as trackerInit } from 'common/analytics/utils';
import { setComponentSelector, populate, register } from 'react-abode';
import AddToCart from './modules/AddToCart/AddToCart';
import AddToCartIcon from './modules/AddToCartIcon/AddToCartIcon';
import Quickview from './modules/Quickview/Quickview';
import Cart from './modules/Cart/Cart';
import Checkout from './modules/Checkout/Checkout';
import Price from './modules/Price/Price';
import CartPage from './modules/CartPage/CartPage';
import Confirmation from './modules/Confirmation/Confirmation';
import Upsell from './modules/Upsell/Upsell';

// initialize the datalayer to which we can send analytics events
trackerInit();

setComponentSelector('data-gdc-component');

register('AddToCart', () => AddToCart, {
  propParsers: { sku: (prop: string) => String(prop) },
});
register('AddToCartIcon', () => AddToCartIcon, {
  propParsers: { sku: (prop: string) => String(prop) },
});
register('Quickview', () => Quickview);
register('Cart', () => Cart);
register('Checkout', () => Checkout);
register('Price', () => Price, {
  propParsers: { sku: (prop: string) => String(prop) },
});
register('CartPage', () => CartPage);
register('Confirmation', () => Confirmation);
register('Upsell', () => Upsell, {
  propParsers: { sku: (prop: string) => String(prop) },
});

populate();
