import { makeStyles } from '@material-ui/core';
import Txt from 'common/components/Txt';
import Checkbox from 'common/components/formComponents/Checkbox';
import { useCart } from 'common/hooks/useCart';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { AddressInput } from 'common/interfaces/generated/globalTypes';
import i18n from 'common/providers/i18n';
import { UPDATE_ACTIVE_CART } from 'common/queries';
import getConfig from 'config';
import { AddressForm, AddressType } from 'modules/Checkout/components/AddressForm';
import React, { MutableRefObject, useEffect, useState } from 'react';

interface Props {
  formRef: MutableRefObject<any>;
  onValidate: (isValid: boolean) => void;
  onSubmit: (address?: AddressInput) => void;
}

const useStyles = makeStyles((theme) => ({
  address: {
    marginTop: theme.spacing(8),
  },
}));

const CheckoutPaymentAddress = (props: Props): JSX.Element => {
  const { cart } = useCart();
  const { billingAddress } = cart!;
  const { formRef, onValidate, onSubmit } = props;
  const [isOpen, setIsOpen] = useState(!!billingAddress);
  const classes = useStyles();
  const [updateCart] = useGraphqlMutation(UPDATE_ACTIVE_CART);

  const onAddressChange = (address: AddressInput, updatedFields: AddressInput) => {
    updateCart({
      variables: {
        actions: [
          {
            setBillingAddress: {
              address: {
                ...address,
                ...updatedFields,
              },
            },
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (!isOpen) onValidate(true);
    if (isOpen && billingAddress === null) {
      updateCart({
        variables: {
          actions: [
            {
              setBillingAddress: {
                address: { country: getConfig().country },
              },
            },
          ],
        },
      });
    }
  }, [isOpen]);

  // Seems to be used as a bypass since the form onSubmit handler itself is not being called
  // maybe blocked by formik validation (?)
  useEffect(() => {
    const submit = () => {
      onSubmit();
    };

    (formRef.current as HTMLFormElement)?.addEventListener('submit', submit);

    return () => {
      (formRef.current as HTMLFormElement)?.removeEventListener('submit', submit);
    };
  }, [formRef, onSubmit]);

  return (
    <div>
      <Checkbox
        id="deliveryAsBilling"
        onChange={(e) => {
          if (isOpen === true && billingAddress) {
            updateCart({
              variables: {
                actions: [
                  {
                    setBillingAddress: {},
                  },
                ],
              },
            });
          }
          setIsOpen(!isOpen);
        }}
        checked={!isOpen}
        data-testid="deliveryAsBilling"
      >
        <Txt fontWeight="light">{i18n.t('useDeliveryAddressAsBillingAddress')}</Txt>
      </Checkbox>
      {isOpen && (
        <div className={classes.address}>
          <AddressForm
            formRef={formRef}
            address={billingAddress}
            onSubmit={onSubmit}
            onAddressChange={onAddressChange}
            onValidate={onValidate}
            addressType={AddressType.Billing}
          />
        </div>
      )}
    </div>
  );
};

export default CheckoutPaymentAddress;
