import { AddressInput } from 'common/interfaces/generated/globalTypes';
import { updateActiveCart } from 'common/interfaces/generated/updateActiveCart';
import { UPDATE_ACTIVE_CART } from 'common/queries';
import { useCart } from './useCart';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { analytics } from 'common/analytics/actions';

const useUpdateEamilShippingBilling = () => {
  const [updateCart] = useGraphqlMutation<updateActiveCart>(UPDATE_ACTIVE_CART);
  const { cart } = useCart();
  const { id: cartId } = cart!;

  return async (
    email: string,
    shippingAddress: AddressInput,
    billingAddress?: AddressInput
  ): Promise<updateActiveCart | undefined | null> => {
    const { data } = await updateCart({
      variables: {
        actions: [
          { setCustomerEmail: { email: email } },
          {
            setShippingAddress: {
              address: shippingAddress,
            },
          },
          {
            setBillingAddress: billingAddress ? { address: billingAddress } : {},
          },
        ],
      },
      update: () => {
        analytics.setEmail(cartId);
        analytics.setShippingAddress(cartId);
        if (billingAddress) analytics.setBillingAddress(cartId);
      },
    });
    return data;
  };
};
export default useUpdateEamilShippingBilling;
