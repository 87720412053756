import React from 'react';
import Providers from 'common/providers/Providers';
import Txt from 'common/components/Txt';
import { makeStyles, useTheme } from '@material-ui/core';
import { priceFromCents } from 'common/lib/commercetools';
import i18n from 'common/providers/i18n';
import HorizontalDivider from 'common/components/HorizontalDivider';
import { useCart } from 'common/hooks/useCart';
import { useProductPriceAndStock } from 'common/hooks/useProduct';
import getConfig from 'config';

export interface Props {
  sku?: string;
  productKey?: string;
  variant?: 'light' | 'dark';
  showInStock?: boolean | string;
  showOutOfStock?: boolean | string;
}

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },
  oldPrice: {
    marginLeft: theme.spacing(2),
    textDecoration: 'line-through',
    color: theme.palette.error.main,
  },
  light: {
    color: theme.palette.primary.contrastText,
  },
}));

export const Price = (props: Props): JSX.Element | null => {
  const classes = useStyles();
  const { sku, productKey, variant, showInStock, showOutOfStock: localShowOutOfStock } = props;
  const isLight = variant === 'light';
  const { cart, loading: isLoadingCart } = useCart();
  const theme = useTheme();

  const {
    available,
    prices,
    loading: isLoadingPrices,
  } = useProductPriceAndStock({
    sku,
    productKey,
    cart,
  });
  const loading = isLoadingPrices || isLoadingCart;
  const priceValue = prices ? prices[0] : undefined;

  const price = priceValue?.value.centAmount;
  const discountedPrice = priceValue?.discounted?.value.centAmount;

  const showOutOfStock = localShowOutOfStock === undefined ? getConfig().features.showOutOfStock : localShowOutOfStock;

  let displayPrice = discountedPrice ? priceFromCents(discountedPrice) : price && priceFromCents(price);

  if (prices && prices?.length > 1) {
    displayPrice = `${i18n.t('fromPrice')} ${displayPrice}`;
  }

  if (loading) {
    return (
      <div className={classes.root} data-testid="price">
        <Txt variant="headingFive">&nbsp;</Txt>
      </div>
    );
  }
  return (
    <div className={classes.root} data-testid="price">
      {displayPrice && (
        <Txt
          fontWeight="bold"
          variant="headingFive"
          className={isLight ? classes.light : ''}
          data-testid="price-active"
        >
          {displayPrice}
        </Txt>
      )}
      {displayPrice && discountedPrice && available && (
        <Txt variant="headingFive" fontWeight="light" className={classes.oldPrice} data-testid="price-old">
          {price && priceFromCents(price)}
        </Txt>
      )}

      {showOutOfStock && displayPrice && !available && (
        <HorizontalDivider color={isLight ? theme.palette.primary.contrastText : theme.palette.primary.main} />
      )}
      {showOutOfStock && !available && !loading && (
        <Txt
          variant="headingFive"
          fontWeight="light"
          className={isLight ? classes.light : ''}
          data-testid="out-of-stock"
        >
          {i18n.t('OutOfStock')}
        </Txt>
      )}
      {displayPrice && available && showInStock && (
        <HorizontalDivider color={isLight ? theme.palette.primary.contrastText : theme.palette.primary.main} />
      )}
      {showInStock && available && (
        <Txt
          variant="headingFive"
          fontWeight="light"
          className={`${isLight ? classes.light : ''}`}
          data-testid="in-stock"
        >
          {i18n.t('InStock')}
        </Txt>
      )}
    </div>
  );
};

const ProvidedAddToCart = (props: Props) => {
  return (
    <Providers>
      <Price {...props} />
    </Providers>
  );
};

export default ProvidedAddToCart;
