import { DocumentNode, OperationDefinitionNode } from 'graphql';
import {
  useMutation,
  MutationHookOptions,
  MutationFunctionOptions,
  OperationVariables,
  MutationTuple,
  useApolloClient,
} from '@apollo/client';
import { GET_ACTIVE_CART } from 'common/queries';
import getConfig from 'config';
import { getActiveCart } from 'common/interfaces/generated/getActiveCart';

const useGraphqlMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
  const [fn, result] = useMutation<TData, TVariables>(mutation, options);
  const client = useApolloClient();

  const isUpdateMyCart = hasSelectionName(mutation, 'updateMyCart');

  let func;
  if (isUpdateMyCart) {
    const cart = client.readQuery<getActiveCart>({
      query: GET_ACTIVE_CART,
      variables: {
        storeKey: getConfig().commercetools.store,
      },
    })?.inStore.me.activeCart;
    func = (options?: MutationFunctionOptions<TData, TVariables>) => {
      // if the mutation is for updateMyCart, we want to pass in version and cart ID by default
      const opts = {
        ...options,
        variables: {
          id: cart?.id,
          version: cart?.version,
          ...options?.variables,
        },
      };

      // @ts-ignore
      return fn(opts);
    };
  } else {
    func = fn;
  }

  return [func, result];
};

const hasSelectionName = (mutation: DocumentNode, name: string) => {
  const operationDefinition = mutation.definitions.find(
    (e) => e.kind === 'OperationDefinition'
  ) as OperationDefinitionNode;

  const selectionSet = operationDefinition && operationDefinition.selectionSet;
  return selectionSet && selectionSet.selections.some((e: any) => e.name.value === name);
};

export default useGraphqlMutation;
