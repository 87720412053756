import { useQuery } from '@apollo/client';
import {
  getProductsPriceAndStock,
  getProductsPriceAndStock_products_results_masterData_current_allVariants,
  getProductsPriceAndStock_products_results_masterData_current_slugAllLocales,
  getProductsPriceAndStockVariables,
} from 'common/interfaces/generated/getProductsPriceAndStock';
import { GET_PRODUCTS_PRICE_AND_STOCK } from 'common/queries';
import getConfig from 'config';
import { useCart } from './useCart';
import { useChannels } from './useChannels';
import { productHasStock } from 'common/lib/commercetools';
import { isDefined } from 'common/lib/helpers';

export interface AvailableItem {
  variant: getProductsPriceAndStock_products_results_masterData_current_allVariants;
  slugs?: getProductsPriceAndStock_products_results_masterData_current_slugAllLocales[];
}

export const useAvailableProducts = (skus?: string[], excludeProductsInCart?: boolean) => {
  const { channels, loading: channelsLoading } = useChannels();
  const noChannels = !channelsLoading && !channels?.distributionChannelId;
  const { cart } = useCart();
  const productsInCart = cart?.lineItems.map((i) => i.variant?.sku);

  const { data, ...rest } = useQuery<getProductsPriceAndStock, getProductsPriceAndStockVariables>(
    GET_PRODUCTS_PRICE_AND_STOCK,
    {
      variables: {
        skus,
        distributionChannelId: channels.distributionChannelId!,
        // Use distributionChannelId as fallback;
        // If this does not have a inventory supply role, there will be no availability, which is what we want/expect
        supplyChannelId: channels.supplyChannelId || channels.distributionChannelId!,
        locale: getConfig().locale,
        currency: getConfig().currency,
        country: getConfig().country,
      },
      skip: channelsLoading || noChannels || !skus?.length,
    }
  );

  // Filter out products that are not available
  if (!skus || !data?.products.results) {
    return {
      availableProducts: [],
      ...rest,
    };
  }

  const availableProducts: AvailableItem[] = skus
    .map((sku) => {
      for (const product of data?.products.results) {
        for (const variant of product.masterData.current?.allVariants ?? []) {
          if (variant.sku === sku) {
            return {
              variant,
              slugs: product.masterData.current?.slugAllLocales,
            };
          }
        }
      }
    })
    .filter((e) => {
      if (!e) {
        return undefined;
      }
      const hasPriceAndStock = e.variant.price && productHasStock(e.variant, cart);
      const notInCart = excludeProductsInCart ? !productsInCart?.includes(e.variant.sku) : true;
      if (hasPriceAndStock && notInCart) {
        return {
          variant: e.variant,
          slugs: e.slugs,
        };
      }
    })
    .filter(isDefined);

  return { availableProducts, ...rest };
};
