import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import i18n from 'common/providers/i18n';
import Txt from '../Txt';
import { useCart } from 'common/hooks/useCart';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { UPDATE_ACTIVE_CART } from 'common/queries';
import { getDiscountsFromCart, getSubtotalsForLineItemsFromCart } from 'common/lib/cart';
import getConfig from 'config';
import clsx from 'clsx';
import { priceFromCents } from 'common/lib/commercetools';
import { TaxMode } from 'common/interfaces/generated/globalTypes';

interface Props {
  className?: string;
  spacing?: 'large' | 'small';
}

const OrderSummaryTotals = (props: Props): JSX.Element => {
  const { className, spacing = 'large' } = props;
  const cartData = useCart();
  const cart = cartData.cart!;
  const [updateCart] = useGraphqlMutation(UPDATE_ACTIVE_CART);
  const classes = useStyles({ spacing });

  const { subtotalGrossCentAmount, subtotalNetCentAmount } = getSubtotalsForLineItemsFromCart(cart);

  const showTaxes = getConfig().features.displayTaxes !== 'NONE';

  const hasTaxPortions = !!cart.taxedPrice?.taxPortions?.length;
  const taxPortions = cart.taxedPrice?.taxPortions?.filter((portion) => portion.rate) || [];
  const totalPrice = cart.taxedPrice?.totalGross ?? cart.totalPrice;

  const [taxBreakdownOpen, setTaxBreakdownOpen] = useState(
    getConfig().features.displayTaxes === 'BREAKDOWN_COLLAPSED' ? true : false
  );
  let showBreakdownToggle = getConfig().features.displayTaxes == 'BREAKDOWN' && taxPortions.length > 0;

  const taxesCentAmount = cart.taxedPrice?.totalGross.centAmount - cart.taxedPrice?.totalNet.centAmount;
  let vatDisplay = priceFromCents(taxesCentAmount);
  if (cart.taxMode === TaxMode.External && !hasTaxPortions) {
    vatDisplay = i18n.t('TaxCalculatedAtCheckout');
  } else if (getConfig().features.displayTaxes === 'BREAKDOWN_COLLAPSED') {
    vatDisplay = '';
  }

  const subTotalCentAmount = showTaxes ? subtotalNetCentAmount : subtotalGrossCentAmount;
  const [productDiscounts, shippingDiscounts] = getDiscountsFromCart(cart);

  let shippingCosts = 0;

  // To calculate shipping costs, we first try to use `taxedPrice`,
  // But if `taxedPrice` is not available, we fallback to `price`.
  if (cart.shippingInfo?.taxedPrice) {
    const shippingGrossCentAmount = cart.shippingInfo?.taxRate?.includedInPrice
      ? cart.shippingInfo.taxedPrice.totalGross.centAmount
      : cart.shippingInfo.taxedPrice.totalNet.centAmount;
    shippingCosts = shippingGrossCentAmount + shippingDiscounts.reduce((a, c) => a + c.centAmount, 0);
  } else if (cart.shippingInfo?.price) {
    shippingCosts = cart.shippingInfo.price.centAmount;
  }

  // very fucking ugly hack to set max width to multiline spans. If you know a way to make it better, please do so and let me know.
  useEffect(() => {
    const nodes = Array.from(document.getElementsByClassName('discount-label-selector'));

    nodes.forEach((node) => {
      const textLength = node.innerHTML.length;
      const style = node.getAttribute('style');
      if (textLength > 25 && !style) {
        node.setAttribute('style', 'max-width: 200px');
      }
    });
  });

  return (
    <div className={clsx(classes.root, className)} data-testid="order-summary-totals">
      <div className={classes.subtotal}>
        <div className={classes.line} data-testid="order-summary-subtotal">
          <Txt variant="headingFive" data-testid="order-summary-subtotal-label">
            {i18n.t('YourSubtotal')}
          </Txt>
          <Txt
            variant="headingFive"
            fontWeight="bold"
            data-testid="order-summary-subtotal-price"
            className={classes.price}
          >
            {priceFromCents(subTotalCentAmount)}
          </Txt>
        </div>

        {productDiscounts.map((e) => (
          <div key={e.id} className={classes.line} data-testid="order-summary-discount">
            <div className={classes.discountLabelContainer}>
              <Txt
                variant="headingFive"
                className={clsx(classes.discount, classes.discountLabel, 'discount-label-selector')}
                data-testid="order-summary-discount-label"
              >
                {e.name}
              </Txt>
              <a
                className={classes.remove}
                onClick={() => {
                  updateCart({
                    variables: {
                      actions: [
                        {
                          removeDiscountCode: {
                            discountCode: {
                              id: e.discountCodeId,
                              typeId: 'discount-code',
                            },
                          },
                        },
                      ],
                    },
                  });
                }}
                data-testid="order-summary-remove-discount"
              >
                <Txt variant="small" className={classes.removeTxt}>
                  {i18n.t('Remove')}
                </Txt>
              </a>
            </div>
            <Txt
              variant="headingFive"
              className={clsx(classes.discount, classes.price)}
              fontWeight="bold"
              data-testid="order-summary-discount-price"
            >
              {'-'}
              {priceFromCents(e.centAmount)}
            </Txt>
          </div>
        ))}
        <div className={classes.line} data-testid="order-summary-shipping">
          <Txt variant="headingFive" data-testid="order-summary-shipping-label">
            {i18n.t('Shipping')}
          </Txt>
          <Txt
            variant="headingFive"
            fontWeight="bold"
            data-testid="order-summary-shipping-price"
            className={classes.price}
          >
            {shippingCosts ? priceFromCents(shippingCosts) : i18n.t('FREE')}
          </Txt>
        </div>
        {shippingDiscounts.map((e) => (
          <div key={e.id} className={classes.line} data-testid="order-summary-discount">
            <div className={classes.discountLabelContainer}>
              <Txt variant="headingFive" className={classes.discount} data-testid="order-summary-discount-label">
                {e.name}
              </Txt>
              <a
                className={classes.remove}
                onClick={() => {
                  updateCart({
                    variables: {
                      actions: [
                        {
                          removeDiscountCode: {
                            discountCode: {
                              id: e.discountCodeId,
                              typeId: 'discount-code',
                            },
                          },
                        },
                      ],
                    },
                  });
                }}
                data-testid="order-summary-remove-shipping-discount"
              >
                <Txt variant="small" className={classes.removeTxt}>
                  {i18n.t('Remove')}
                </Txt>
              </a>
            </div>
            <Txt
              variant="headingFive"
              className={clsx(classes.discount, classes.price)}
              fontWeight="bold"
              data-testid="order-summary-discount-price"
            >
              {'-'}
              {priceFromCents(e.centAmount)}
            </Txt>
          </div>
        ))}
        {showTaxes && (
          <div>
            {vatDisplay && (
              <div className={classes.line} data-testid="order-summary-taxes">
                <Txt variant="headingFive" data-testid="order-summary-taxes-label">
                  {i18n.t('TaxAbbreviation')}
                  {showBreakdownToggle && (
                    <span className={classes.taxToggle} onClick={() => setTaxBreakdownOpen(!taxBreakdownOpen)}>
                      <Txt variant="linkSubtle" className={classes.taxToggleLink}>
                        {i18n.t(taxBreakdownOpen ? 'TaxHideDetails' : 'TaxShowDetails')}
                      </Txt>
                    </span>
                  )}
                </Txt>
                <Txt
                  variant="headingFive"
                  fontWeight="bold"
                  data-testid="order-summary-taxes-price"
                  className={classes.price}
                >
                  {vatDisplay}
                </Txt>
              </div>
            )}
            {taxBreakdownOpen &&
              taxPortions.map((portion) => {
                const groups = portion.name?.match(/([^\(]*)(?:\((.*)\))?$/) || [];
                const name = groups[1] ?? portion.name;
                const subLine = groups[2] ?? '';

                return (
                  <div key={portion.name} className={classes.line}>
                    <Txt variant="headingFive">{name}</Txt>
                    <Txt
                      variant="headingFive"
                      fontWeight="bold"
                      data-testid="order-summary-taxes-price"
                      className={classes.price}
                    >
                      {priceFromCents(portion.amount.centAmount)}
                    </Txt>
                    {subLine && (
                      <Txt variant="small" className={classes.taxSubLine}>
                        {subLine}
                      </Txt>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <div className={classes.total}>
        <div className={classes.line} data-testid="order-summary-total">
          <Txt variant="headingFive" data-testid="order-summary-total-label">
            {i18n.t('YourTotal')}
          </Txt>
          <Txt
            variant="headingFive"
            fontWeight="bold"
            data-testid="order-summary-total-price"
            className={classes.price}
          >
            {priceFromCents(totalPrice.centAmount)}
          </Txt>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    line: (props: any) => ({
      marginBottom: props.spacing === 'small' ? theme.spacing(3) : theme.spacing(4),
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    }),
    taxToggle: {
      paddingLeft: '20px',
    },
    taxToggleLink: {
      verticalAlign: 'super',
    },
    price: {
      flexShrink: 0,
    },
    taxSubLine: {
      width: '100%',
    },
    subtotal: (props: any) => ({
      paddingBottom: props.spacing === 'small' ? theme.spacing(2) : theme.spacing(4),
      borderBottom: `0.5px solid ${theme.palette.grey['700']}`,
    }),
    total: (props: any) => ({
      marginTop: props.spacing === 'small' ? theme.spacing(2) : theme.spacing(8),
    }),
    discount: {
      color: theme.palette.error.main,
    },
    discountLabelContainer: {
      display: 'flex',
    },
    discountLabel: {},
    remove: {
      width: 45,
      margin: theme.spacing(2),
      '&:hover': {
        cursor: 'pointer',
        textDecoration: `underline ${theme.palette.grey['800']}`,
      },
    },
    removeTxt: {
      color: '#C1C6C8',
      '&:hover': {
        color: theme.palette.grey[800],
      },
    },
  }),
  { name: 'MuiOrderSummaryTotals' }
);

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey {
    MuiOrderSummaryTotals: keyof ReturnType<typeof useStyles>;
  }
}

export default OrderSummaryTotals;
