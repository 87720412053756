import React from 'react';
import Txt from 'common/components/Txt';
import i18n from 'common/providers/i18n';
import getConfig from 'config';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Checkbox from 'common/components/formComponents/Checkbox';

interface Props {
  className?: string;
  termsAccepted: boolean;
  setTermsAccepted: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  link: {
    marginLeft: theme.spacing(1),
  },
}));

const TermsConditionsCheckbox = (props: Props): JSX.Element => {
  const { className, termsAccepted, setTermsAccepted } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} data-testid="terms-conditions">
      <Checkbox
        id="termsAndConditions"
        onChange={(_) => {
          setTermsAccepted(!termsAccepted);
        }}
        checked={termsAccepted}
        data-testid="termsAndConditions"
      >
        <Box>
          <Txt data-testid="terms-conditions-checkbox-text">
            {i18n.t('TermsAndConditionsCheckbox', { brand: getConfig().brandName })}
          </Txt>
          <a target="_blank" rel="noopener noreferrer" href={getConfig().urls.termsConditions}>
            <Txt className={classes.link} variant="link">
              {i18n.t('TermsAndConditions')}
            </Txt>
          </a>
          <Txt>.</Txt>
        </Box>
      </Checkbox>
    </div>
  );
};

export default TermsConditionsCheckbox;
