import i18n from 'common/providers/i18n';

export interface State {
  code: string;
  name: string;
  enabled: boolean;
}

const US_STATES = [
  'AL', // Alabama
  'AZ', // Arizona
  'AR', // Arkansas
  'CA', // California
  'CO', // Colorado
  'CT', // Connecticut
  'DE', // Delaware
  'FL', // Florida
  'GA', // Georgia
  'ID', // Idaho
  'IL', // Illinois
  'IN', // Indiana
  'IA', // Iowa
  'KS', // Kansas
  'KY', // Kentucky
  'LA', // Louisiana
  'ME', // Maine
  'MD', // Maryland
  'MA', // Massachusetts
  'MI', // Michigan
  'MN', // Minnesota
  'MS', // Mississippi
  'MO', // Missouri
  'MT', // Montana
  'NE', // Nebraska
  'NV', // Nevada
  'NH', // New Hampshire
  'NJ', // New Jersey
  'NM', // New Mexico
  'NY', // New York
  'NC', // North Carolina
  'ND', // North Dakota
  'OH', // Ohio
  'OK', // Oklahoma
  'OR', // Oregon
  'PA', // Pennsylvania
  'RI', // Rhode Island
  'SC', // South Carolina
  'SD', // South Dakota
  'TN', // Tennessee
  'TX', // Texas
  'UT', // Utah
  'VT', // Vermont
  'VA', // Virginia
  'WA', // Washington
  'WV', // West Virginia
  'WI', // Wisconsin
  'WY', // Wyoming
  // Unsupported:
  'AK', // Alaska - unsupported
  'HI', // Hawaii - unsupported
  'AS', // American Samoa
  'GU', // Guam
  'MP', // Northern Mariana Islands
  'PR', // Puerto Rico
  'VI', // Virgin Islands
];

const US_UNSUPPORTED_STATES = [
  'AK', // Alaska
  'HI', // Hawaii
  'AS', // American Samoa
  'GU', // Guam
  'MP', // Northern Mariana Islands
  'PR', // Puerto Rico
  'VI', // Virgin Islands
];

const CA_STATES = [
  'AB', // Alberta
  'BC', // British Columbia
  'MB', // Manitoba
  'NB', // New Brunswick
  'NL', // Newfoundland
  'NS', // Nova Scotia
  'ON', // Ontario
  'PE', // Prince Edward Island
  'QC', // Quebec
  'SK', // Saskatchewan
  // Unsupported:
  'NU', // Nunavut - unsupported
  'NW', // Northwestterritories - unsupported
  'YK', // Yukon - unsupported
];

const CA_UNSUPPORTED_STATES = [
  'NU', // Nunavut - unsupported
  'NW', // Northwestterritories - unsupported
  'YK', // Yukon - unsupported
];

const getUnsupportedStates = (country: string) => {
  switch (country) {
    case 'US':
      return US_UNSUPPORTED_STATES;
    case 'CA':
      return CA_UNSUPPORTED_STATES;
  }

  return [];
};

export const stateSupported = (state: string, country: string) => {
  const unsupported_states = getUnsupportedStates(country);
  return !unsupported_states.includes(state);
};

export const getStates = (country: string): State[] => {
  let states: string[] = [];
  const unsupported_states = getUnsupportedStates(country);

  switch (country) {
    case 'US':
      states = US_STATES;
      break;
    case 'CA':
      states = CA_STATES;
      break;
    default:
      states = [];
  }

  return states
    .map((code: string) => ({
      code,
      name: i18n.t(`states.${country}.${code}`),
      enabled: !unsupported_states.includes(code),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const hasStates = (country: string): boolean => {
  // Convenience method
  return getStates(country).length > 0;
};

export const getState = (state: string, country: string) => {
  const stateMatchesCountry = getStates(country).find((stateObj) => stateObj.code === state);
  if (stateMatchesCountry) {
    return state;
  }
  return '';
};
