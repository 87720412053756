import {
  getProductsPriceAndStock_products_results_masterData_current_allVariants,
  getProductsPriceAndStock_products_results_masterData_current_slugAllLocales,
} from 'common/interfaces/generated/getProductsPriceAndStock';
import getConfig from 'config';
import { getVariantAttribute } from 'common/lib/commercetools';
import { useProductVariant } from './useProduct';
import { useAvailableProducts } from './useAvailableProducts';

export interface UpsellItem {
  variant: getProductsPriceAndStock_products_results_masterData_current_allVariants;
  slugs?: getProductsPriceAndStock_products_results_masterData_current_slugAllLocales[];
}

export const useUpsellProducts = (sku: string) => {
  const { product } = useProductVariant({ sku });

  const skus: string[] = getVariantAttribute(product?.masterData.current?.variant, 'upsell')
    ?.map((sku: any) => sku?.[getConfig().locale])
    ?.filter((sku: string | undefined) => sku);

  const { availableProducts, ...rest } = useAvailableProducts(skus, false);

  return { data: availableProducts.slice(0, 3), ...rest };
};
