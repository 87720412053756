import { makeStyles } from '@material-ui/core';
import { getProductsPriceAndStock_products_results_masterData_current_allVariants } from 'common/interfaces/generated/getProductsPriceAndStock';
import { getProductImages, getProductName } from 'common/lib/commercetools';
import { isGdamTheme } from 'common/lib/helpers';
import React from 'react';

const UpsellProductImage = ({
  item,
}: {
  item: getProductsPriceAndStock_products_results_masterData_current_allVariants;
}) => {
  const classes = useStyles();
  const name = getProductName(item);
  const { imageUrlSmall, webpUrlSmall } = getProductImages(item);

  return (
    <picture className={classes.root}>
      <source type="image/webp" srcSet={webpUrlSmall}></source>
      <img className={classes.image} src={imageUrlSmall} alt={name} />
    </picture>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '1/1',
    height: 80,
    width: 80,
    backgroundColor: isGdamTheme() ? '#FFFFFF' : theme.palette.grey['300'],
  },
  image: {
    maxHeight: '100%',
    maxWidth: 64,
  },
}));

export default UpsellProductImage;
