import React, { useState, useRef, useEffect } from 'react';
import { AddressForm, AddressType } from 'modules/Checkout/components/AddressForm';
import ShippingMethods from './components/ShippingMethods';
import MarketingOptIn from './components/MarketingOptIn';
import MarketingOptInExplicit from './components/MarketingOptInExplicit';
import { makeStyles } from '@material-ui/core';
import { useCart } from 'common/hooks/useCart';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { UPDATE_ACTIVE_CART } from 'common/queries';
import { AddressInput } from 'common/interfaces/generated/globalTypes';
import { useNavigate } from 'react-router-dom';
import { checkoutPaths } from 'modules/Checkout/Checkout';

import { getAnalyticsInfoFromLineItems } from 'common/lib/cart';
import i18n from 'common/providers/i18n';
import Button from 'common/components/Button';
import { analytics } from 'common/analytics/actions';
import { useCheckPrices } from 'common/hooks/useCheckPrices';
import { makeOptimisticResponseForAddressChange } from 'common/lib/optimistic/cart';
import { useHandleSupplyChannelChange } from 'common/hooks/useHandleSupplyChannelChange';
import getConfig from 'config';

interface Props {}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 450,
  },
  shippingMethods: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(10),
  },
  marketingOptIn: {
    marginBottom: theme.spacing(7),
  },
}));

const CheckoutDelivery = (props: Props): JSX.Element => {
  const { cart } = useCart();
  const { id: cartId, lineItems, shippingAddress } = cart!;
  const classes = useStyles();
  const [isFormValid, setIsFormValid] = useState(false);
  const hasMultipleTickboxes = getConfig().features.marketingOptInExplicit;
  const [updateCart] = useGraphqlMutation(UPDATE_ACTIVE_CART);
  const navigate = useNavigate();
  const formEl = useRef<HTMLFormElement>(null);
  const checkPrices = useCheckPrices();

  const isValidSupplyChannel = useHandleSupplyChannelChange();
  const isOptInSet =
    cart?.custom?.customFieldsRaw?.find((e) => e.name === 'marketingConsent')?.value !== undefined ||
    !hasMultipleTickboxes;

  useEffect(() => {
    if (window.scrollTo) window.scrollTo(0, 0);
    analytics.viewElement(cartId, 'checkoutAddress', getAnalyticsInfoFromLineItems(lineItems));
    checkPrices();
  }, []);

  const onSubmit = async (newAddress: AddressInput) => {
    await updateCart({
      variables: {
        actions: [
          {
            setShippingAddress: {
              address: newAddress,
            },
          },
        ],
      },
      update: () => {
        analytics.setShippingAddress(cartId);
      },
    });
    navigate(checkoutPaths.payment);
  };

  /**
   * Update address in commercetools once country and/or state changes;
   * This can influence shipping methods, tax rates, etc.
   */
  const onAddressChange = async (address: AddressInput, updatedFields: AddressInput) => {
    updateCart({
      variables: {
        actions: [
          {
            setShippingAddress: {
              address: {
                ...address,
                ...updatedFields,
              },
            },
          },
        ],
      },
      optimisticResponse: makeOptimisticResponseForAddressChange(cart!, updatedFields),
    });
  };

  const [selectedDeliveryOption, setDeliveryOption] = useState(undefined);

  const canGoToPayment =
    isFormValid &&
    isValidSupplyChannel &&
    isOptInSet &&
    cart?.shippingInfo &&
    cart?.shippingAddress &&
    selectedDeliveryOption;

  return (
    <div className={classes.root} data-testid="checkout-delivery">
      <AddressForm
        formRef={formEl}
        address={shippingAddress}
        onSubmit={onSubmit}
        onAddressChange={onAddressChange}
        onValidate={setIsFormValid}
        addressType={AddressType.Shipping}
      />
      <ShippingMethods className={classes.shippingMethods} setDeliveryOptionState={setDeliveryOption} />
      {hasMultipleTickboxes ? (
        <MarketingOptInExplicit className={classes.marketingOptIn} />
      ) : (
        <MarketingOptIn className={classes.marketingOptIn} />
      )}
      <Button
        fullWidth
        disabled={!canGoToPayment}
        onClick={() => {
          formEl.current?.dispatchEvent(
            new Event('submit', {
              bubbles: true,
              cancelable: true,
            })
          );
        }}
        type="submit"
        data-testid="go-to-payment"
      >
        {i18n.t('ContinueToPayment')}
      </Button>
    </div>
  );
};

export default CheckoutDelivery;
