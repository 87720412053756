import { Resolvers, gql, InMemoryCache } from '@apollo/client';
import { getUpsellSkus } from 'common/interfaces/generated/getUpsellSkus';
import { GET_MOST_RECENT_SKU, GET_UPSELL_SKUS, GET_QUICKVIEW_STATE, GET_SNACKBAR } from './../../queries';

/* eslint-disable */
export const typeDefs = gql`
  enum SnackbarType {
    INFO
    ERROR
  }

  type Snackbar {
    message: String!
    type: SnackbarType!
  }

  input SnackbarInput {
    message: String!
    type: SnackbarType!
  }

  extend type Query {
    isQuickviewOpen: Boolean!
    mostRecentSku: String
    upsellSkus(mainSku: String!): [String!]!
    snackbar: Snackbar
  }

  extend type Mutation {
    setQuickviewOpen(open: Boolean!): Boolean
    setMostRecentSku(sku: String): String
    toggleUpsellSku(mainSku: String!, sku: String!): [String]!
    setSnackbar(snackbar: SnackbarInput): Snackbar
  }
`;

export const resolvers: Resolvers = {
  Mutation: {
    setQuickviewOpen: (_parent, { open }, { cache }) => {
      cache.writeQuery({ query: GET_QUICKVIEW_STATE, data: { isQuickviewOpen: open } });
      return open;
    },
    setMostRecentSku: (_parent, { sku }, { cache }) => {
      cache.writeQuery({ query: GET_MOST_RECENT_SKU, data: { mostRecentSku: sku } });
      return sku;
    },
    toggleUpsellSku: (_parent, { mainSku, sku }, { cache }: { cache: InMemoryCache }) => {
      const variables = { mainSku };
      let data: getUpsellSkus = {
        upsellSkus: [],
      };

      const previous = cache.readQuery({ query: GET_UPSELL_SKUS, variables }) as getUpsellSkus;
      if (!previous) {
        data.upsellSkus = [sku];
      } else if (previous.upsellSkus.includes(sku)) {
        data.upsellSkus = previous.upsellSkus.filter((e: string) => e !== sku);
      } else {
        data.upsellSkus = [...previous.upsellSkus, sku];
      }

      cache.writeQuery({ query: GET_UPSELL_SKUS, data, variables });
    },
    setSnackbar: (_parent, { snackbar }, { cache }) => {
      if (!snackbar) {
        cache.writeQuery({ query: GET_SNACKBAR, data: { snackbar: null } });
      } else {
        cache.writeQuery({ query: GET_SNACKBAR, data: { snackbar } });
      }
    },
  },
};
