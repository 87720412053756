import React, { useLayoutEffect, useRef, useState } from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import Providers from 'common/providers/Providers';
import i18n from 'common/providers/i18n';
import { useCart } from 'common/hooks/useCart';
import { useAddToCart } from 'common/hooks/useAddToCart';
import { useProductPriceAndStock } from 'common/hooks/useProduct';
import BasketIcon from 'common/icons/BasketIcon';
import Txt from 'common/components/Txt';
import { ieDetection } from 'common/lib/helpers';
import clsx from 'clsx';

export interface Props {
  sku: string;
  extraDescription?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    borderColor: 'transparent',
    borderWidth: 0,
    borderRadius: '20px',
    boxShadow: theme.shadows[2],
    '&::before': {
      transition: '0.2s ease-out',
      content: "''",
      width: 0,
      height: '100%',
    },
    '&:focus:not(:focus-visible)': {
      outline: 0,
    },
    '&:focus': {
      outlineStyle: 'solid',
      outlineColor: 'rgb(0, 95, 204)',
      outlineWidth: '2px',
    },
    [theme.breakpoints.up('lg')]: {
      '& span': {
        opacity: 0,
        transition: '.2s ease-out',
        position: 'absolute',
        right: '0px',
        paddingLeft: '16px',
        paddingRight: '44px',
        whiteSpace: 'nowrap',
      },
      '&:hover': {
        '&::before': {
          content: "''",
          fontFamily: theme.typography.fontFamily,
          ...theme.typography.bodyBold,
          color: '#000000',
          width: (props: { widthTxt?: number }) => `${props.widthTxt ?? 92}px`,
          transition: '0.2s ease-in',
        },
        '& span': {
          opacity: 1,
          transition: '.2s ease-in',
          whiteSpace: 'nowrap',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      '&:hover': {
        transform: 'scale(1.05,1.05)',
      },
    },
    '-webkit-appearance': 'none',
  },
  ie: {
    [theme.breakpoints.up('lg')]: {
      '& span': {
        bottom: '8%',
      },
    },
  },
  label: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export const AddToCartIcon = (props: Props): JSX.Element | null => {
  const isInitialized = useRef(false);
  const [widthTxt, setWidthTxt] = useState(92);

  useLayoutEffect(() => {
    const spanEle = document.querySelector('.js-add-to-cart-icon span');
    if (spanEle && !isInitialized.current) {
      isInitialized.current = true;
      // subtract width of BasketIcon
      setWidthTxt(spanEle?.clientWidth - 40);
    }
  });

  const classes = useStyles({ widthTxt });
  const { cart } = useCart();
  const { sku, extraDescription } = props;

  const { available, loading } = useProductPriceAndStock({
    sku,
    cart,
  });

  const addToCart = useAddToCart({ sku, extraDescription });

  if (!sku) {
    console.error('data-prop-sku not set');
    return null;
  }

  const disabled = loading || !available;

  return disabled ? null : (
    <ButtonBase
      className={clsx(classes.root, ieDetection() && classes.ie, 'js-add-to-cart-icon')}
      onClick={async (e) => {
        e.stopPropagation();
        e.preventDefault();
        await addToCart();
      }}
      disableRipple
      data-testid="add-to-cart"
    >
      <Txt align="right" className={classes.label}>
        {i18n.t('AddToBasket')}
      </Txt>
      <BasketIcon />
    </ButtonBase>
  );
};

const ProvidedAddToCartIcon = (props: Props) => {
  return (
    <Providers>
      <AddToCartIcon {...props} />
    </Providers>
  );
};

export default ProvidedAddToCartIcon;
