import React, { useState } from 'react';
import { makeStyles, ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';
import PlusIcon from 'common/icons/PlusIcon';
import MinusIcon from 'common/icons/MinusIcon';
import Txt from '../Txt';
import i18n from 'common/providers/i18n';

interface Props {
  quantity: number;
  onAdd: () => void;
  onRemove: () => void;
  className?: string;
  addDisabled: boolean;
  removeDisabled: boolean;
  alignRight?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2,
  },
  rocker: (props: any) => ({
    display: 'flex',
    alignSelf: props.alignRight ? 'flex-end' : 'flex-start',
  }),
  rockerBlock: {
    position: 'relative',
    width: 30,
    height: 30,
    borderTop: `1px solid ${theme.palette.grey['900']}`,
    borderBottom: `1px solid ${theme.palette.grey['900']}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 300,
    fontSize: 12,
    backgroundColor: 'transparent',
  },
  addOrRemove: {
    borderRight: `1px solid ${theme.palette.grey['900']}`,
    borderLeft: `1px solid ${theme.palette.grey['900']}`,
    cursor: 'pointer',
    padding: 0,
    '&:focus': {
      outline: 'none',
    },
    '&:active': {
      borderColor: theme.palette.grey['800'],
    },
    '&:disabled': {
      cursor: 'default',
      '& svg': {
        opacity: 0.3,
      },
    },
  },
  errorWrapper: {
    position: 'relative',
  },
  errorClicker: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },
  error: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    '&:empty': {
      display: 'none',
    },
  },
}));

const Rocker = (props: Props): JSX.Element => {
  const { quantity, onAdd, onRemove, className, addDisabled, removeDisabled, alignRight } = props;
  const classes = useStyles({ alignRight });
  const [error, setError] = useState('');

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.rocker} data-testid="rocker">
        <button
          disabled={removeDisabled}
          className={clsx(classes.addOrRemove, classes.rockerBlock)}
          onClick={onRemove}
          data-testid="rocker-remove"
        >
          <MinusIcon />
        </button>
        <div className={classes.rockerBlock} data-testid="rocker-quantity">
          {quantity}
        </div>
        <ClickAwayListener onClickAway={() => setError('')}>
          <div
            className={classes.errorWrapper}
            onClick={() => {
              if (addDisabled) setError(i18n.t('errors.noMoreStock')!);
            }}
          >
            <button
              disabled={addDisabled}
              className={clsx(classes.addOrRemove, classes.rockerBlock)}
              onClick={onAdd}
              data-testid="rocker-add"
            >
              <PlusIcon />
            </button>
            {addDisabled && (
              <div
                data-testid="error-clicker"
                className={classes.errorClicker}
                onClick={() => {
                  if (addDisabled) setError(i18n.t('errors.noMoreStock')!);
                }}
              ></div>
            )}
          </div>
        </ClickAwayListener>
      </div>
      <Txt className={classes.error} variant="error" data-testid="rocker-error">
        {error}
      </Txt>
    </div>
  );
};

export default Rocker;
