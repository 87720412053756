import React, { useState, useEffect } from 'react';
import { makeStyles, SwipeableDrawer, Slide } from '@material-ui/core';
import MobileOrderSummaryHeader from 'common/components/MobileOrderSummary/MobileOrderSummaryHeader';
import { StyleProvider } from 'common/providers/StyleProvider';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '90vh',
    overflow: 'auto',
  },
  header: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    // loading is 101 because paypal is 100
    zIndex: 102,
    boxShadow: theme.shadows[1],
  },
  content: {
    maxWidth: 600,
    width: '90%',
    paddingTop: theme.spacing(7),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const MobileOrderSummary = (props: Props): JSX.Element | null => {
  const { children } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [footerOpen, setFooterOpen] = useState(false);
  const [hidden, setHidden] = useState(false);

  const handleHeaderClick = () => {
    setOpen(!open);
  };

  const isAtBottom = (): boolean => window.scrollY + window.innerHeight >= document.body.scrollHeight;

  const handleScroll = () => {
    if (isAtBottom()) {
      setFooterOpen(true);
    } else {
      setFooterOpen(false);
    }
  };

  const handleOpenVirtualKeyboard = () => {
    if (/Android|iPod|iPhone|iPad/.test(navigator.userAgent) || /iPod|iPhone|iPad/.test(navigator.platform)) {
      if (document.activeElement?.tagName == 'INPUT' || document.activeElement?.tagName == 'TEXTAREA') {
        setHidden(true);
      } else {
        setHidden(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleOpenVirtualKeyboard);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleOpenVirtualKeyboard);
    };
  }, []);

  return (
    <>
      {!hidden && (
        <Slide direction="up" in={!footerOpen}>
          <MobileOrderSummaryHeader open={open} handleClick={handleHeaderClick} className={classes.header} />
        </Slide>
      )}
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => {
          // in case promo code is open, blur it
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement?.blur();
          }
          setOpen(false);
        }}
        onOpen={() => setOpen(true)}
        disableSwipeToOpen={true}
      >
        <StyleProvider>
          <div className={classes.root} data-testid="mobile-order-summary">
            <MobileOrderSummaryHeader open={open} handleClick={handleHeaderClick} />
            <div className={classes.content}>{children}</div>
          </div>
        </StyleProvider>
      </SwipeableDrawer>
    </>
  );
};

MobileOrderSummary.displayName = 'MobileOrderSummary';

export default MobileOrderSummary;
