import { SnackbarInput } from 'common/interfaces/generated/globalTypes';
import { SET_SNACKBAR } from 'common/queries';
import useGraphqlMutation from './useGraphlMutation';

export default () => {
  const [setSnackbar] = useGraphqlMutation(SET_SNACKBAR);

  return (snackbar?: SnackbarInput) => {
    setSnackbar({ variables: { snackbar } });
  };
};
