import React from 'react';

interface Props {
  className?: string;
  color?: string;
}

const CheckmarkIcon = ({ className, color }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 6L5.66667 11L15 1" stroke={color ?? 'white'} strokeWidth="2" />
    </svg>
  );
};

export default CheckmarkIcon;
