import React from 'react';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { makeStyles, CssBaseline } from '@material-ui/core';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const StyleProvider = (props: Props): JSX.Element => {
  return (
    <ScopedCssBaseline>
      <CssBaseline />
      <GlobalStyles>{props.children}</GlobalStyles>
    </ScopedCssBaseline>
  );
};

const autoFill = {
  '-webkit-box-shadow': '0 0 0px 1000px #fff inset !important;',
  '-webkit-animation-delay': '1s',
  '-webkit-animation-name': 'autofill',
  '-webkit-animation-fill-mode': 'both',
};

const useStyles = makeStyles((theme) => ({
  '@global': {
    scrollbarColor: theme.palette.secondary.main,
    scrollbarWidth: 'thin',
    boxSizing: 'border-box',
    '.gd-root *::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '.gd-root *::-webkit-scrollbar:vertical': {
      width: 3,
    },
    '.gd-root *::-webkit-scrollbar:horizontal': {
      height: 3,
    },
    '.gd-root *::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey['500'],
    },
    '.gd-root *::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.secondary.main,
    },
    '@keyframes autofill': {
      '0%,100%': { background: '#fff' },
    },
    '.gd-root input:-webkit-autofill': {
      ...autoFill,
    },
    '.gd-root input:-webkit-autofill:hover': {
      ...autoFill,
    },
    '.gd-root input:-webkit-autofill:focus': {
      ...autoFill,
    },
    '.gd-root input:-webkit-autofill:active': {
      ...autoFill,
    },
    '.gd-root input:-internal-autofill-selected': {
      ...autoFill,
    },
    '.gd-root input:-internal-autofill-previewed': {
      ...autoFill,
    },
    '.gd-root textarea:-webkit-autofill': {
      ...autoFill,
    },
    '.gd-root textarea:-webkit-autofill:hover': {
      ...autoFill,
    },
    '.gd-root textarea:-webkit-autofill:active': {
      ...autoFill,
    },
    '.gd-root fieldset': {
      padding: 0,
      border: 'none',
    },
    '.gd-root .legend': {
      position: 'absolute',
      width: 0,
      height: 0,
      overflow: 'hidden',
    },
    '.gd-root *:focus': {
      outline: 'none',
    },
  },
}));

export const GlobalStyles = (props: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const { children } = props;
  useStyles();

  return <div className="gd-root">{children}</div>;
};
