import React from 'react';
import Txt from 'common/components/Txt';
import i18n from 'common/providers/i18n';
import getConfig from 'config';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  link: {
    marginLeft: theme.spacing(1),
  },
}));

const TermsConditions = (props: Props): JSX.Element => {
  const { className } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} data-testid="terms-conditions">
      <Txt data-testid="terms-conditions-text">
        {i18n.t('TermsAndConditionsText', { brand: getConfig().brandName })}
      </Txt>
      <a target="_blank" rel="noopener noreferrer" href={getConfig().urls.termsConditions}>
        <Txt className={classes.link} variant="link">
          {i18n.t('TermsAndConditions')}
        </Txt>
      </a>
      <Txt>.</Txt>
    </div>
  );
};

export default TermsConditions;
