import React from 'react';

interface Props {
  className?: string;
}

const WarningIcon = (props: Props): JSX.Element => {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 77 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="warning-icon"
      className={props.className}
    >
      <path d="M38.9615 3L72.2702 60.6923H5.65287L38.9615 3Z" stroke="#BE6A14" strokeWidth="6" strokeLinejoin="round" />
      <rect x="35.9609" y="20.7692" width="6" height="26" rx="3" fill="#BE6A14" />
      <circle cx="38.9609" cy="51.7692" r="3" fill="#BE6A14" />
    </svg>
  );
};

export default WarningIcon;
