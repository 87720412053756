import React from 'react';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import getConfig from 'config';
import { isGdamTheme } from 'common/lib/helpers';

interface Props {
  className?: string;
  open?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff',
  },
}));

const BasketDefault = (props: Props): JSX.Element => {
  const { open, className } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));

  if (getConfig().theme === 'morphy-richards') {
    return (
      <svg
        width={isLgDown ? '26' : '32'}
        height={isLgDown ? '26' : '32'}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.25 11.75H7.75V25.25H24.25V11.75H21.75V15H20.25V11.75H11.75V15H10.25V11.75ZM11.816 10.25H20.184C19.8298 8.26049 18.0913 6.75 16 6.75C13.9087 6.75 12.1702 8.26049 11.816 10.25ZM21.7015 10.25H25H25.75V11V26V26.75H25H7H6.25V26V11V10.25H7H10.2985C10.666 7.42873 13.0786 5.25 16 5.25C18.9214 5.25 21.334 7.42873 21.7015 10.25Z"
          fill="#262626"
        />
      </svg>
    );
  }

  if (getConfig().theme === 'robertsradio') {
    return (
      <svg
        width={isLgDown ? '24' : '30'}
        height={isLgDown ? '20' : '24'}
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.5 15L2.5 5H4H16H17.5L15.5 15H4.5Z" fill={open ? theme.palette.secondary.main : '#2D2D2D'} />
        <path
          d="M1 5H2.5M19 5H17.5M2.5 5L4.5 15H15.5L17.5 5M2.5 5H4M17.5 5H16M16 5L13 1M16 5H4M4 5L7 1"
          stroke={open ? theme.palette.secondary.main : '#2D2D2D'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  // GDAM specific basket icon
  if (isGdamTheme()) {
    return (
      <svg
        width={isLgDown ? 26 : 32}
        height={isLgDown ? 26 : 32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.308 25.846a1.23 1.23 0 11-2.462 0 1.23 1.23 0 012.462 0zM20.924 25.846a1.23 1.23 0 11-2.462 0 1.23 1.23 0 012.462 0zM22.77 20.923H9.23L6.77 8.615h19.692l-3.693 12.308z"
          fill={isLgDown ? '#000' : '#fff'}
        />
        <path
          d="M3.692 6.154H6.26l.51 2.461m2.46 12.308h13.54l3.692-12.308H6.769m2.462 12.308L6.769 8.615m2.462 12.308L8 24.615h14.153"
          stroke={isLgDown ? '#000' : '#fff'}
          strokeWidth={2.462}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      className={clsx(classes.root, className)}
      width={isLgDown ? '18' : '24'}
      height={isLgDown ? '18' : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="basket-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeWidth="0.25"
        d="M9.04308 0L4.48 6.2501H0V12.4715H1.95385L3.51692 23H20.4831L22.0462 12.4715H24V6.2501H19.8277L15.2646 0L14.0918 0.839007L17.9022 6.25968H6.27321L10.1926 0.839007L9.04308 0ZM19.2203 21.6363H4.76685L3.42857 12.4613H20.5714L19.2203 21.6363ZM1.37143 11.263H22.6286V7.73415H1.37143V11.263Z"
        fill="#6D6D6D"
      />
    </svg>
  );
};

export default BasketDefault;
