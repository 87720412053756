// NOTE: the order of imports and execution in this file matters.

import { initConfig } from 'config';
import { getScriptProps } from 'react-abode';
import config from '../config/na-gdam-prd';
import { version as VERSION } from '../../package.json';
import { initSentry } from 'common/interfaces/sentry-hub';
import { initI18n } from 'common/providers/i18n';

// 2) Get properties from the habitat script tag. Only works in an entry file
const globalProps = getScriptProps();

// 3) Set global config
initConfig(config, globalProps);
initSentry();
initI18n();

console.debug(`Loading app version ${VERSION}`);

// 4) Register habitat components
import('../register-habitat-components');
