import gdamTheme from '../gdam/gdam-theme';
import GothamBook from 'common/fonts/gotham/Gotham-Book.woff';
import GothamMedium from 'common/fonts/gotham/Gotham-Medium.woff';

const gothamBook = {
  fontFamily: '"Gotham Book"',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${GothamBook}) format('woff')`,
};

const gothamMedium = {
  fontFamily: '"Gotham Medium"',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${GothamMedium}) format('woff')`,
};

const theme: any = JSON.parse(JSON.stringify(gdamTheme));

theme.overrides.MuiCssBaseline = {
  '@global': {
    ...theme.overrides.MuiCssBaseline['@global'],
    '@font-face': [gothamBook, gothamMedium],
  },
};
theme.palette.button = {
  ...theme.palette.button,
  primary: {
    main: '#2D2D2D',
    hover: '#4B4B4B',
    active: '#000000',
    contrastText: '#FFFFFF',
    disabled: '#8C8C8C',
  },
  secondary: {
    main: '#2D2D2D',
    hover: '#2D2D2D',
    active: '#000000',
    contrastText: '#FFFFFF',
    disabled: '#8C8C8C',
  },
};
theme.palette.primary = {
  ...theme.palette.primary,
  main: '#E5E5E5',
  contrastText: '#FFFFFF',
};
theme.palette.secondary = {
  ...theme.palette.secondary,
  main: '#E5E5E5',
  contrastText: '#FFFFFF',
};

theme.palette.info = { ...theme.palette.info, dark: '#0085C2' };

theme.typography.h2 = {
  ...theme.typography.h2,
  fontFamily: '"Gotham Medium"',
};
theme.typography.h3 = {
  ...theme.typography.h3,
  fontFamily: '"Gotham Medium"',
};
theme.typography.h4 = {
  ...theme.typography.h4,
  fontFamily: '"Gotham Medium"',
};
theme.typography.h5 = {
  ...theme.typography.h5,
  fontFamily: '"Gotham Book"',
  '@media (min-width:600px)': {
    ...theme.typography.h5['@media (min-width:600px)'],
    fontFamily: '"Gotham Medium"',
  },
};
theme.typography.bodyLight = {
  ...theme.typography.bodyLight,
  fontFamily: '"Gotham Book"',
  fontWeight: 400,
};
theme.typography.body1 = {
  ...theme.typography.body1,
  fontFamily: '"Gotham Book"',
  fontWeight: 400,
};
theme.typography.bodyBold = {
  ...theme.typography.bodyBold,
  fontFamily: '"Gotham Medium"',
  fontWeight: 400,
};
theme.typography.body2 = {
  // used for small and error
  ...theme.typography.body2,
  fontFamily: '"Gotham Book"',
  fontWeight: 400,
};
theme.typography.button = {
  ...theme.typography.button,
  fontFamily: '"Gotham Medium"',
  fontSize: '15px',
  lineHeight: '20px',
  letterSpacing: 0.5,
  fontWeight: 400,
};
theme.typography.fontFamily = '"Gotham Book", "Gotham Medium", Helvetica, Arial, "Lucida Grande", sans-serif';
theme.typography.fontFaces = [gothamBook, gothamMedium];

export default theme;
