import { makeStyles } from '@material-ui/core';
import { analytics } from 'common/analytics/actions';
import Txt from 'common/components/Txt';
import RadioButton from 'common/components/formComponents/RadioButton';
import { useCart } from 'common/hooks/useCart';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { useShippingMethods } from 'common/hooks/useShippingMethods';
import { getShippingMethodsByCart_shippingMethodsByCart } from 'common/interfaces/generated/getShippingMethodsByCart';
import { priceFromCents } from 'common/lib/commercetools';
import { makeOptimisticResponseForShippingMethod } from 'common/lib/optimistic/cart';
import i18n from 'common/providers/i18n';
import { UPDATE_ACTIVE_CART } from 'common/queries';
import { sum } from 'common/utils/math';
import getConfig from 'config';
import React, { useEffect } from 'react';

interface Props {
  className?: string;
  setDeliveryOptionState?: Function;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 450,
    minHeight: 90,
    marginBottom: theme.spacing(2),
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  boxed: {
    border: `1px solid ${theme.palette.grey['800']}`,
    padding: theme.spacing(3),
  },
}));

const ShippingMethods = (props: Props): JSX.Element | null => {
  const { className } = props;
  const { cart } = useCart();
  const { shippingInfo, id: cartId } = cart!;
  const classes = useStyles();
  const subTotal = sum(cart!.lineItems, (lineItem) => lineItem.totalPrice?.centAmount);

  const [updateCart] = useGraphqlMutation(UPDATE_ACTIVE_CART);
  const { methods, loading, needMoreInput } = useShippingMethods();

  const setShippingMethod = async (shippingMethod: getShippingMethodsByCart_shippingMethodsByCart) => {
    props.setDeliveryOptionState && props.setDeliveryOptionState(shippingMethod);

    await updateCart({
      variables: {
        actions: [
          {
            setShippingMethod: {
              shippingMethod: { id: shippingMethod.id },
            },
          },
        ],
      },
      optimisticResponse: getConfig().features.enableCartOptimisticResponse
        ? makeOptimisticResponseForShippingMethod(cart!, shippingMethod)
        : undefined,
    });
    await analytics.setShippingMethod(cartId, shippingMethod.id);
  };

  useEffect(() => {
    methods.forEach((method) => {
      if (props.setDeliveryOptionState && shippingInfo?.shippingMethod?.id == method.id) {
        props.setDeliveryOptionState(method);
      }
    });

    if (methods.length === 0 || shippingInfo?.shippingMethod) {
      return;
    }

    // TODO: setting the shipping method automatically results in a ConcurrentModification error (wrong version being passed).
    // For this to be fixed we should either;
    // - Find out why the ConcurrentModification handler in apolloClient doens't solve the problem
    // - See if we can update the cart.version in the getActiveCart cache upon every cart update
    //   (we receive the updated cart from commercetools after every update).
    //
    // See https://glendimplex.atlassian.net/browse/GDL-1627
    //
    // setShippingMethod(methods[0]).then(() => {});
  }, [methods]);

  if (loading) {
    return null;
  }

  let options: JSX.Element[] = [];
  if (needMoreInput) {
    options = [
      <div key="tbd" className={classes.boxed}>
        <Txt>{i18n.t('DeliveryOptionsToBeDetermined')}</Txt>
      </div>,
    ];
  } else if (methods.length === 0) {
    options = [
      <div key="tbd" className={classes.boxed}>
        <Txt>{i18n.t('DeliveryOptionsNotAvailable')}</Txt>
      </div>,
    ];
  } else {
    options = methods.map((method) => {
      const descriptionObject = method.localizedDescriptionAllLocales?.find((e) => e.locale === getConfig().locale);
      const nameObject = method.localizedNameAllLocales?.find((e) => e.locale === getConfig().locale);
      const description = descriptionObject ? descriptionObject.value : '';
      const name = nameObject ? nameObject.value : method.name;

      // Shipping price calculation
      const shippingPrice: number = method.zoneRates[0]?.shippingRates[0]?.price.centAmount;
      const freeAbovePrice = method.zoneRates[0].shippingRates[0].freeAbove?.centAmount;
      const hasShippingCosts = shippingPrice !== undefined && shippingPrice > 0;
      const hasFreeShipping = freeAbovePrice !== undefined && hasShippingCosts && freeAbovePrice <= subTotal;

      return (
        <div key={method.id} className={classes.root} data-testid="shipping-method">
          <RadioButton
            data-testid="shipping-method-radio-button"
            name="shipping-method"
            checked={shippingInfo?.shippingMethod?.id === method.id}
            id={method.id}
            value={method.id}
            onChange={async (e) => {
              e.preventDefault();
              await setShippingMethod(method);
            }}
          >
            <div className={classes.label}>
              <span>
                <Txt data-testid="shipping-method-name">{name}</Txt>
                <Txt> - </Txt>
                <Txt fontWeight="bold" data-testid="shipping-method-price">
                  {hasShippingCosts && !hasFreeShipping ? priceFromCents(shippingPrice) : i18n.t('FREE')}
                </Txt>
                {hasShippingCosts && freeAbovePrice && !hasFreeShipping && (
                  <>
                    <Txt> - </Txt>
                    <Txt>
                      {i18n.t('freeAbove')} {priceFromCents(freeAbovePrice)}
                    </Txt>
                  </>
                )}
              </span>
              <Txt data-testid="shipping-method-description">{description}</Txt>
            </div>
          </RadioButton>
        </div>
      );
    });
  }

  return (
    <fieldset className={className} data-testid="shipping-methods">
      <legend className={classes.header}>
        <Txt variant="small">{i18n.t('DeliveryOptions')}</Txt>
      </legend>
      {options}
    </fieldset>
  );
};

export default ShippingMethods;
