import Input from 'common/components/formComponents/Input';
import SearchIcon from 'common/icons/SearchIcon';
import i18n from 'common/providers/i18n';
import React from 'react';

interface Props {}
const AddressSearchInput = (props: Props): JSX.Element => {
  return (
    <Input
      placeholder={i18n.t('addressSearchPlaceholder')!}
      id="address-search-input"
      autoComplete="chrome-off"
      Svg={SearchIcon}
      {...props}
      data-testid="address-search-input"
    />
  );
};

export default AddressSearchInput;
