import { MyCartDraft, MyLineItemDraft, TaxMode } from './../interfaces/generated/globalTypes';
import {
  getActiveCart_inStore_me_activeCart,
  getActiveCart_inStore_me_activeCart_lineItems,
  getActiveCart_inStore_me_activeCart_lineItems_discountedPricePerQuantity_discountedPrice,
  getActiveCart_inStore_me_activeCart_shippingInfo_discountedPrice,
} from './../interfaces/generated/getActiveCart';
import { CREATE_CART, GET_ACTIVE_CART } from '../queries';
import { AnalyticsLineItem } from 'common/analytics/datalayer';
import getConfig from 'config';
import { retry } from './helpers';
import { Discount } from 'common/interfaces';
import { getProductBrand, getVariantAttribute } from './commercetools';
import { ApolloClient } from '@apollo/client';

export const roundingModes = {
  HalfDown: (val: number): number => {
    return -Math.round(-val);
  },
  HalfEven: (val: number): number => {
    const rounded = Math.round(val);
    return (val > 0 ? val : -val) % 1 === 0.5 ? (0 === rounded % 2 ? rounded : rounded - 1) : rounded;
  },
  HalfUp: (val: number): number => {
    return Math.round(val);
  },
};

export const ensureCart = async (client: ApolloClient<any>): Promise<getActiveCart_inStore_me_activeCart | null> => {
  const config = getConfig();
  const activeRes = await client.query({
    query: GET_ACTIVE_CART,
    variables: {
      storeKey: config.commercetools.store,
    },
  });
  if (activeRes.data && activeRes.data.inStore.me.activeCart && activeRes.data.inStore.me.activeCart.id) {
    return activeRes?.data?.inStore?.me?.activeCart;
  }

  const draftCart = {
    currency: config.currency,
    country: config.country,
    locale: config.locale,
    shippingAddress: { country: config.country },
    inventoryMode: 'ReserveOnOrder',
    taxMode: config.features.externalTax ? TaxMode.External : TaxMode.Platform,
  };

  const createCart = async () => {
    await client.mutate({
      mutation: CREATE_CART,
      errorPolicy: 'ignore',
      variables: {
        draftCart,
        storeKey: config.commercetools.store,
      },
      update: (cache, res) => {
        cache.writeQuery({
          query: GET_ACTIVE_CART,
          variables: {
            storeKey: config.commercetools.store,
          },
          data: {
            inStore: {
              __typename: 'InStore',
              me: {
                __typename: 'InStoreMe',
                activeCart: res?.data?.createMyCart ?? null,
              },
            },
          },
        });
      },
    });
  };

  await retry(createCart, 10, 100);

  const { data } = await client.query({
    query: GET_ACTIVE_CART,
    variables: {
      storeKey: config.commercetools.store,
    },
  });

  return data?.inStore?.me?.activeCart;
};

export const makeDraftCartFromCart = (cart: getActiveCart_inStore_me_activeCart): MyCartDraft => {
  const config = getConfig();
  const { country, customerEmail, shippingAddress, billingAddress, shippingMethod, taxMode, locale, discountCodes } =
    cart as any;

  const lineItems: MyLineItemDraft[] = cart.lineItems.map((lineitem) => {
    const { productId, sku, quantity, variantId, supplyChannel, distributionChannel, custom, shippingDetails } =
      lineitem as any;

    return {
      productId,
      sku,
      quantity,
      variantId,
      supplyChannel,
      distributionChannel,
      custom,
      shippingDetails,
    };
  });

  const copyCart: MyCartDraft = {
    country,
    customerEmail,
    shippingAddress,
    billingAddress,
    shippingMethod,
    taxMode,
    locale,
    discountCodes,
    lineItems,
    currency: config.currency,
  };

  return copyCart;
};

export const getDiscountsFromCart = (cart: getActiveCart_inStore_me_activeCart): Discount[][] => {
  // lineItem discounts
  const productDiscounts = getDiscountObjectsForLineItems(cart);
  const uniqueproductDiscounts = productDiscounts.filter((v, i, s) => s.findIndex((e) => e.id === v.id) === i);
  const productDiscountsWithValue = uniqueproductDiscounts
    .map((e) => {
      const centAmountForDiscount = getDiscountCentAmountForLineItems(cart.lineItems, e);
      e.centAmount = centAmountForDiscount;
      return e;
    })
    .filter((e) => e.centAmount !== 0)
    .sort((a, b) => (a?.sortOrder && b?.sortOrder && a.sortOrder > b.sortOrder ? -1 : 1));

  // shipping discounts
  const shippingDiscounts = getDiscountObjectFromDiscountedPrice(cart.shippingInfo?.discountedPrice, cart);
  const uniqueShippingDiscounts = shippingDiscounts.filter((v, i, s) => s.findIndex((e) => e.id === v.id) === i);
  const shippingDiscountsWithvalue = uniqueShippingDiscounts
    .map((e) => {
      const centAmountForDiscount = getDiscountCentAmountFromDiscountedPrice(cart.shippingInfo?.discountedPrice, e);
      e.centAmount = centAmountForDiscount;
      return e;
    })
    .filter((e) => e.centAmount !== 0)
    .sort((a, b) => (a?.sortOrder && b?.sortOrder && a.sortOrder > b.sortOrder ? -1 : 1));

  return [productDiscountsWithValue, shippingDiscountsWithvalue];
};

const getDiscountObjectsForLineItems = (cart: getActiveCart_inStore_me_activeCart): Discount[] => {
  return cart.lineItems.flatMap((li) =>
    li.discountedPricePerQuantity.flatMap((discountPerQuant) =>
      getDiscountObjectFromDiscountedPrice(discountPerQuant.discountedPrice, cart)
    )
  );
};

const getDiscountObjectFromDiscountedPrice = (
  discountedPrice:
    | getActiveCart_inStore_me_activeCart_lineItems_discountedPricePerQuantity_discountedPrice
    | getActiveCart_inStore_me_activeCart_shippingInfo_discountedPrice
    | undefined
    | null,
  cart?: getActiveCart_inStore_me_activeCart
): Discount[] => {
  if (!discountedPrice) return [];
  return discountedPrice.includedDiscounts
    .filter((e) => e.discount?.isActive)
    .map((discount) => {
      const name = discount.discount?.nameAllLocales.find((e) => e.locale === getConfig().locale);
      const id = discount.discount?.id;
      const discountCodeId = cart?.discountCodes.find((e) => {
        return e.discountCode?.cartDiscounts[0]?.id === id;
      })?.discountCode?.id;
      return {
        id: discount.discount?.id || '',
        name: name?.value || '',
        nameAllLocales: discount.discount?.nameAllLocales,
        centAmount: 0,
        discountCodeId: discountCodeId || '',
        sortOrder: discount.discount?.sortOrder,
      };
    });
};

const getDiscountCentAmountForLineItems = (lineItems: getActiveCart_inStore_me_activeCart_lineItems[], e: Discount) => {
  return lineItems.reduce((liAcc, liCurr) => {
    const centAmountForLineItem = liCurr.discountedPricePerQuantity.reduce((perQuantAcc, perQuantCurr) => {
      const centAmountForQuantity = getDiscountCentAmountFromDiscountedPrice(perQuantCurr.discountedPrice, e);
      return perQuantAcc + perQuantCurr.quantity * centAmountForQuantity;
    }, 0);
    return liAcc + centAmountForLineItem;
  }, 0);
};

const getDiscountCentAmountFromDiscountedPrice = (
  discountedPrice:
    | getActiveCart_inStore_me_activeCart_lineItems_discountedPricePerQuantity_discountedPrice
    | getActiveCart_inStore_me_activeCart_shippingInfo_discountedPrice
    | undefined
    | null,
  e: Discount
) => {
  if (!discountedPrice) return 0;
  return discountedPrice.includedDiscounts
    .filter((discount) => discount.discount?.id === e.id)
    .reduce((discountAcc, discountCurr) => {
      const centAmountForDiscountTimesQuant = discountCurr.discountedAmount.centAmount;
      return discountAcc + centAmountForDiscountTimesQuant;
    }, 0);
};

/**
 *
 * Calculate the subtotal excluding any cart discounts.
 */
export const getSubtotalsForLineItemsFromCart = (
  cart: getActiveCart_inStore_me_activeCart
): { subtotalGrossCentAmount: number; subtotalNetCentAmount: number } => {
  const subtotalGrossCentAmount = cart.lineItems.reduce((acc, curr) => {
    const price = curr.price.discounted?.value.centAmount || curr.price.value.centAmount;
    return acc + price * curr.quantity;
  }, 0);

  const subtotalNetCentAmount = cart.lineItems.reduce((acc, curr) => {
    const price = curr.price.discounted?.value.centAmount || curr.price.value.centAmount;
    let taxCorrection = 0;
    const roundingMethod = roundingModes[cart.taxRoundingMode] || roundingModes.HalfEven;
    if (curr.taxRate?.includedInPrice) {
      const taxRate = curr.taxRate?.amount || 0;
      if (cart.taxCalculationMode === 'UnitPriceLevel') {
        taxCorrection = roundingMethod(price - price / (1 + taxRate)) * curr.quantity;
        return acc + price * curr.quantity - taxCorrection;
      } else {
        // taxCalculationMode is on 'LineItemLevel'
        taxCorrection = (price - price / (1 + taxRate)) * curr.quantity;
        // on 'LineItemLevel' rounding is done after all other calculations
        return roundingMethod(acc + price * curr.quantity - taxCorrection);
      }
    }

    return acc + price * curr.quantity - taxCorrection;
  }, 0);

  return { subtotalGrossCentAmount, subtotalNetCentAmount };
};

export const getProductsNumber = (lineItems: getActiveCart_inStore_me_activeCart_lineItems[] | undefined) => {
  if (!lineItems) {
    return 0;
  }
  return lineItems.reduce((acc: number, curr: any) => acc + curr.quantity, 0);
};

export const getAnalyticsInfoFromLineItems = (
  lineItems: getActiveCart_inStore_me_activeCart_lineItems[],
  fromUpsell?: boolean
): AnalyticsLineItem[] => {
  return lineItems.map((li) => ({
    sku: li.variant?.sku,
    name: getVariantAttribute(li.variant, 'productDisplayTitle'),
    price: li.price.discounted?.value.centAmount / 100 || li.price.value.centAmount / 100,
    variant: getVariantAttribute(li.variant, 'variantTitle'),
    brand: getProductBrand(li.variant) ?? getConfig().brandName,
    quantity: li.quantity,
    category: li.productType?.name || '',
    ...(fromUpsell !== undefined && { fromUpsell }),
  }));
};
