import { makeStyles } from '@material-ui/core';
import Button from 'common/components/Button';
import Txt from 'common/components/Txt';
import { useAddToCart } from 'common/hooks/useAddToCart';
import {
  getProductsPriceAndStock_products_results_masterData_current_allVariants,
  getProductsPriceAndStock_products_results_masterData_current_slugAllLocales,
} from 'common/interfaces/generated/getProductsPriceAndStock';
import { getProductName, getVariantTitle } from 'common/lib/commercetools';
import { buildProductPageUrl } from 'common/lib/helpers';
import i18n from 'common/providers/i18n';
import getConfig from 'config';
import React from 'react';
import CrossSellProductImage from './CrossSellProductImage';
import CrossSellProductPrice from './CrossSellProductPrice';

const CrossSellProduct = ({
  item,
  showCta,
  slugs,
  startOpenTimer,
}: {
  showCta: boolean;
  item?: getProductsPriceAndStock_products_results_masterData_current_allVariants;
  slugs?: getProductsPriceAndStock_products_results_masterData_current_slugAllLocales[];
  startOpenTimer: () => void;
}) => {
  const classes = useStyles();
  const locale = getConfig().locale;
  const sku = item?.sku || '';
  // TODO: this doesn't work for GDAM, known issue
  const extraDescription = getVariantTitle(item);
  const addToCart = useAddToCart({ sku, extraDescription, fromCrossSell: true });
  const productSlug = (slugs && slugs.find((i) => i.locale === locale)?.value) || '';
  const productPageUrl = buildProductPageUrl(locale, productSlug, sku);
  const linkToPDP = getConfig().features.displayLinkToPDP;

  if (!item || !item.sku) {
    return null;
  }

  return (
    <div className={classes.root}>
      {linkToPDP && productSlug ? (
        <a href={productPageUrl}>
          <CrossSellProductImage item={item} />
        </a>
      ) : (
        <CrossSellProductImage item={item} />
      )}
      <Txt className={classes.title} align="center">
        {getProductName(item)}
      </Txt>
      <div className={classes.bottomLine}>
        <CrossSellProductPrice item={item} />
        {showCta && (
          <Button
            className={classes.cta}
            variant="secondary"
            onClick={async (e) => {
              e.stopPropagation();
              e.preventDefault();
              startOpenTimer();
              await addToCart();
            }}
          >
            {i18n.t('AddToBasket')}
          </Button>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      flex: '0 0 50%',
      maxWidth: '50%',
      paddingLeft: 8,
      paddingRight: 8,
    },
    [theme.breakpoints.up('lg')]: {
      '&:last-of-type': {
        paddingBottom: 0,
      },
    },
  },
  bottomLine: {
    marginTop: 'auto',
  },
  title: {
    display: 'block',
  },
  price: {
    display: 'block',
  },
  cta: {
    display: 'block',
    width: '100%',
    maxWidth: 200,
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default CrossSellProduct;
