import React from 'react';
import { makeStyles } from '@material-ui/core';
import CloseIcon from 'common/icons/CloseIcon';
import clsx from 'clsx';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SNACKBAR, SET_SNACKBAR } from 'common/queries';
import { getSnackbar } from 'common/interfaces/generated/getSnackbar';
import Txt from './Txt';
import MuiSnackbar from '@material-ui/core/Snackbar';
import { isGdamTheme } from 'common/lib/helpers';
interface Props {}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: isGdamTheme() ? theme.palette.primary.main : theme.palette.info.main,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    padding: theme.spacing(3),
    height: 'fit-content',
    width: 450,
    [theme.breakpoints.down('md')]: {
      width: 330,
    },
  },
  closeIcon: {
    width: 26,
    height: 26,
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  text: {
    color: theme.palette.error.contrastText,
  },
}));

const Snackbar = (props: Props): JSX.Element | null => {
  const { data } = useQuery<getSnackbar>(GET_SNACKBAR);

  const [setSnackbar] = useMutation(SET_SNACKBAR);

  const classes = useStyles();

  const close = () => {
    setSnackbar({ variables: { snackbar: null } });
  };

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={!!data?.snackbar}
      onClose={close}
      transitionDuration={0}
    >
      <div className={clsx(classes.root, data?.snackbar?.type === 'ERROR' && classes.error)} data-testid="snackbar">
        <Txt className={classes.text} data-testid="snackbar-message">
          {data?.snackbar?.message}
        </Txt>
        <div className={classes.closeIcon}>
          <CloseIcon
            onClick={close}
            height={'26'}
            width={'26'}
            strokeWidth={'2'}
            color={'#FFF'}
            data-testid="snackbar-close-icon"
          />
        </div>
      </div>
    </MuiSnackbar>
  );
};

export default Snackbar;
