import { getHub } from 'common/interfaces/sentry-hub';
import getConfig, { gdamThemes } from 'config';

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const retry = async (fn: () => any, times: number, delayTime: number): Promise<any> => {
  try {
    return await fn();
  } catch (err) {
    if (times > 1) {
      await delay(delayTime);
      return retry(fn, times - 1, delayTime * 4);
    } else {
      getHub()?.withScope((scope: any) => {
        scope.setExtra('errorInfo', err);
        scope.setExtra('fn', fn);
      });
      getHub()?.captureException(new Error('could not execute function after retrying'));
    }
  }
};

export const isDefined = <T>(argument: T | undefined): argument is T => {
  return argument !== undefined;
};

export const debounce = <T extends Function>(cb: T, wait = 100) => {
  let h: any = 0;
  let callable = (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => cb(...args), wait);
  };
  return callable as any as T;
};

export const omitDeep = (value: any, key: string): any => {
  if (typeof value !== 'object') return value;

  if (Array.isArray(value)) {
    return value.map((e) => omitDeep(e, key));
  }

  const copy = { ...value };

  Object.keys(copy).forEach((prop) => {
    if (prop === key) delete copy[key];
  });

  Object.entries(copy).forEach(([prop, value]) => {
    copy[prop] = omitDeep(value, key);
  });

  return copy;
};
export const removeUndefinedDeep = (value: any): any => {
  if (typeof value !== 'object') return value;

  if (Array.isArray(value)) {
    return value.map((e) => removeUndefinedDeep(e)).filter((e) => e !== undefined && e !== null);
  }

  const copy = { ...value };
  Object.entries(copy).forEach((kv) => {
    if (kv[1] === undefined || kv[1] === null) delete copy[kv[0]];
  });

  return copy;
};

export const redirect = (route: string) => {
  window.location.href = route;
};

export const getIsQuickCheckout = () => {
  const config = getConfig();
  let key: keyof typeof config.paymentMethods;
  for (key in config.paymentMethods) {
    if (config.paymentMethods[key]) {
      return true;
    }
  }
  return false;
};

export const ieDetection = () => {
  const userAgent = window.navigator.userAgent;
  const trident = userAgent.indexOf('Trident/');

  return trident > 0;
};

export const isGdamTheme = () => {
  return gdamThemes.includes(getConfig().theme);
};

export const buildProductPageUrl = (locale: string, productSlug: string, sku: string) => {
  return productSlug === '' ? undefined : `/${locale}/${productSlug}#${sku}`.toLowerCase();
};
