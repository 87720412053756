import { useQuery } from '@apollo/client';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Checkbox from 'common/components/formComponents/Checkbox';
import Txt from 'common/components/Txt';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import ChevronRight from 'common/icons/ChevronRight';
import {
  getProductsPriceAndStock_products_results_masterData_current_allVariants,
  getProductsPriceAndStock_products_results_masterData_current_slugAllLocales,
} from 'common/interfaces/generated/getProductsPriceAndStock';
import { getUpsellSkus } from 'common/interfaces/generated/getUpsellSkus';
import { toggleUpsellSku, toggleUpsellSkuVariables } from 'common/interfaces/generated/toggleUpsellSku';
import { getProductName } from 'common/lib/commercetools';
import { buildProductPageUrl } from 'common/lib/helpers';
import i18n from 'common/providers/i18n';
import { GET_UPSELL_SKUS, TOGGLE_UPSELL_SKU } from 'common/queries';
import getConfig from 'config';
import React from 'react';
import UpsellProductImage from './UpsellProductImage';
import UpsellProductPrice from './UpsellProductPrice';

const UpsellProduct = ({
  mainSku,
  variant,
  slugs,
}: {
  mainSku: string;
  variant: getProductsPriceAndStock_products_results_masterData_current_allVariants;
  slugs?: getProductsPriceAndStock_products_results_masterData_current_slugAllLocales[];
}) => {
  const classes = useStyles();
  const locale = getConfig().locale;
  const sku = variant.sku || '';
  const { data } = useQuery<getUpsellSkus>(GET_UPSELL_SKUS, {
    variables: {
      mainSku: mainSku,
    },
  });
  const selectedSkus = data?.upsellSkus ?? [];
  const [toggleSku] = useGraphqlMutation<toggleUpsellSku, toggleUpsellSkuVariables>(TOGGLE_UPSELL_SKU);
  const productSlug = (slugs && slugs.find((i) => i.locale === locale)?.value) || '';
  const productPageUrl = buildProductPageUrl(locale, productSlug, sku);
  const linkToPDP = getConfig().features.displayLinkToPDP;

  if (!variant.sku) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Checkbox
        id={sku}
        onChange={(_) => {
          toggleSku({
            variables: {
              mainSku: mainSku,
              sku,
            },
          });
        }}
        checked={selectedSkus.includes(sku)}
        className={classes.toggle}
      />
      {productPageUrl && linkToPDP ? (
        <a href={productPageUrl} className={clsx(classes.content, classes.link)}>
          <UpsellContent variant={variant} showLearnMore={true} />
        </a>
      ) : (
        <div className={classes.content}>
          <UpsellContent variant={variant} showLearnMore={false} />
        </div>
      )}
    </div>
  );
};

const UpsellContent = ({
  variant,
  showLearnMore,
}: {
  variant: getProductsPriceAndStock_products_results_masterData_current_allVariants;
  showLearnMore: boolean;
}) => {
  const classes = useStyles();

  return (
    <>
      <UpsellProductImage item={variant} />
      <Box ml={3}>
        <Txt fontWeight="bold" className={classes.title}>
          {getProductName(variant)}
        </Txt>
        <UpsellProductPrice item={variant} />
        {showLearnMore && (
          <span className={classes.learnMore}>
            <Txt>{i18n.t('upsellLearnMore')}</Txt>
            <ChevronRight className={classes.learnMoreIcon} />
          </span>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      marginBottom: 8,

      '&:last-child': {
        marginBottom: 0,
      },
    },
    toggle: {
      flex: '0 0 56px',
      maxWidth: 56,
      alignItems: 'center',
      justifyContent: 'flex-end',

      '& .MuiCheckboxInput-inputContainer': {
        marginTop: 0,
        marginRight: 16,
      },

      [theme.breakpoints.up('sm')]: {
        flex: '0 0 64px',
        maxWidth: 64,
      },
    },
    content: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 24,
      paddingBottom: 24,
    },
    learnMore: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    learnMoreIcon: {
      marginLeft: theme.spacing(1),

      '& polyline': {
        stroke: 'currentColor',
      },
    },
    title: {
      display: 'block',
    },
    price: {},
    cta: {},
    link: {
      color: 'inherit',
      textDecoration: 'none',

      '&:hover': {
        '& .MuiUpsellProduct-learnMore': {
          color: theme.palette.primary.main,
        },
      },
    },
  }),
  { name: 'MuiUpsellProduct' }
);

export default UpsellProduct;
