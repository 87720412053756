import React, { HTMLProps } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import CheckmarkIcon from 'common/icons/CheckmarkIcon';

interface Props extends HTMLProps<HTMLInputElement> {
  children?: JSX.Element;
  id: string;
}
const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'start',
      cursor: 'pointer',
      '-webkit-tap-highlight-color': 'transparent',
    },
    inputContainer: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(3),
      flexShrink: 0,
      position: 'relative',
      height: 24,
      width: 24,
    },
    input: {
      position: 'absolute',
      height: 24,
      width: 24,
      cursor: 'pointer',
      margin: 0,
    },
    hidden: {
      opacity: 0,
      zIndex: 1,
    },
    checkmark: {
      display: 'flex',
      border: `1px solid ${theme.palette.primary.main}`,
      justifyContent: 'center',
      alignItems: 'center',
      height: 24,
      width: 24,
    },
    checked: {
      height: 24,
      width: 24,
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: 'MuiCheckboxInput' }
);

const Checkbox = (props: Props): JSX.Element => {
  const { children, id, className, checked, ...rest } = props;
  const classes = useStyles();

  return (
    <label htmlFor={id} className={clsx(classes.root, className)}>
      <div className={classes.inputContainer} role="checkbox" aria-checked={checked}>
        <input
          className={clsx(classes.input, classes.hidden)}
          id={id}
          type="checkbox"
          aria-checked={checked}
          checked={checked}
          onChange={(e) => {
            if (rest.onChange) rest.onChange(e);
          }}
          {...rest}
        />
        <div className={clsx(classes.input, classes.checkmark)}>
          <div className={checked ? classes.checked : ''}>{checked && <CheckmarkIcon />}</div>
        </div>
      </div>
      {children}
    </label>
  );
};

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey {
    MuiCheckboxInput: keyof ReturnType<typeof useStyles>;
  }
}

export default Checkbox;
