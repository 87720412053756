import React from 'react';
import { makeStyles } from '@material-ui/core';
import { getActiveCart_inStore_me_activeCart_lineItems } from 'common/interfaces/generated/getActiveCart';
import { getProductName } from 'common/lib/commercetools';
import { isGdamTheme } from 'common/lib/helpers';
import clsx from 'clsx';

interface Props {
  lineItem: getActiveCart_inStore_me_activeCart_lineItems;
  hoverable?: boolean;
}

const size = 120;
const hoverScale = 90;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: size,
      height: size,
      margin: 0,
      backgroundColor: isGdamTheme() ? '#FFFFFF' : theme.palette.grey['300'],
      display: 'flex',
      justifyContent: 'center',
      flexShrink: 0,
      alignItems: 'center',
      overflow: 'hidden',
      '& img': {
        maxHeight: '100%',
        maxWidth: size,
      },
    },
    hoverable: {
      '& img': {
        transform: `scale(${hoverScale / 100})`,
        maxWidth: `calc(${size}px / ${hoverScale} * 100)`,
        transition: 'transform 200ms ease-in-out',
      },
    },
  }),
  { name: 'MuiLineItemCardImage' }
);

const LineItemCardImage = ({ lineItem, hoverable = false }: Props): JSX.Element => {
  const classes = useStyles();

  const name = getProductName(lineItem.variant);
  const imageUrl = lineItem.variant?.images.length ? lineItem.variant?.images[0].url : undefined;
  const imageUrlSmall = imageUrl?.replace(/\.png$/, '-small.png').replace(/\.jpg$/, '-small.jpg');
  const imageKey = imageUrl?.split('/')[imageUrl.split('/').length - 1].split('.')[0] || '';

  const filteredAssets = lineItem.variant?.assets.filter((asset) =>
    Object.values(asset.nameAllLocales)
      .map((locale) => locale.value)
      .includes(imageKey)
  );

  const webpUrl = filteredAssets?.length
    ? filteredAssets[0]?.sources.find((source) => source.uri.includes('-small'))?.uri || imageUrl
    : imageUrl;

  return (
    <picture className={clsx(classes.root, hoverable && classes.hoverable)} data-testid="line-item-image">
      <source type="image/webp" srcSet={webpUrl}></source>
      <img src={imageUrlSmall} alt={name}></img>
    </picture>
  );
};

export default LineItemCardImage;
