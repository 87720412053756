import React from 'react';
import Checkbox from 'common/components/formComponents/Checkbox';
import Txt from 'common/components/Txt';
import { useCart } from 'common/hooks/useCart';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { UPDATE_ACTIVE_CART } from 'common/queries';
import i18n from 'common/providers/i18n';
import getConfig from 'config';
import { analytics } from 'common/analytics/actions';
import { makeOptimisticResponseForMarketingOptIn } from 'common/lib/optimistic/cart';
import { Box, makeStyles } from '@material-ui/core';

interface Props {
  className?: string;
}

const MarketingOptInExplicit = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { className } = props;
  const { cart } = useCart();
  const { custom, id: cartId } = cart!;
  const [updateCart] = useGraphqlMutation(UPDATE_ACTIVE_CART);

  const isOptInSet = custom?.customFieldsRaw?.find((e) => e.name === 'marketingConsent')?.value !== undefined;
  const isOptIn = custom?.customFieldsRaw?.find((e) => e.name === 'marketingConsent')?.value === true;

  return (
    <Box className={className}>
      <Txt fontWeight="bold">{i18n.t('marketingOptInTitle')}</Txt>
      <Box mt={2} mb={3}>
        <Txt data-testid="marketing-opt-in-text" fontWeight="light">
          {i18n.t('marketingOptInText', { brand: getConfig().brandName })}
        </Txt>{' '}
        {/* space here on purpose */}
        <a
          target="_blank"
          rel="noopener noreferrer"
          data-testid="marketing-opt-in-link"
          href={getConfig().urls.privacyPolicy}
        >
          <Txt variant="link">{i18n.t('PrivacyPolicy')}</Txt>
        </a>
      </Box>
      <Box className={classes.container}>
        <Checkbox
          className={classes.checkbox}
          checked={isOptInSet && isOptIn}
          onChange={async () => {
            const isChecked = isOptInSet && isOptIn;
            await updateCart({
              variables: {
                actions: [
                  {
                    setCustomField: {
                      name: 'marketingConsent',
                      value: isChecked ? 'false' : 'true',
                    },
                  },
                ],
              },
              optimisticResponse: makeOptimisticResponseForMarketingOptIn(cart!, isChecked ? false : true),
            });
            // inverse since it takes the value from before execution
            await analytics.setMarketingOptIn(cartId, !(isOptInSet && isOptIn));
          }}
          id="marketingOptInTrue"
        >
          <Txt>{i18n.t('marketingOptInCheckbox.yes')}</Txt>
        </Checkbox>
        <Checkbox
          className={classes.checkbox}
          checked={isOptInSet && !isOptIn}
          onChange={async () => {
            const isChecked = isOptInSet && !isOptIn;
            await updateCart({
              variables: {
                actions: [
                  {
                    setCustomField: {
                      name: 'marketingConsent',
                      value: isChecked ? 'true' : 'false',
                    },
                  },
                ],
              },
              optimisticResponse: makeOptimisticResponseForMarketingOptIn(cart!, isChecked ? true : false),
            });
            // inverse since it takes the value from before execution
            await analytics.setMarketingOptIn(cartId, isOptInSet && !isOptIn);
          }}
          id="marketingOptInFalse"
        >
          <Txt>{i18n.t('marketingOptInCheckbox.no')}</Txt>
        </Checkbox>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',

    '& > :last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',

      '& > :last-child': {
        marginRight: 0,
      },
    },
  },
  checkbox: {
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6),
      marginBottom: 0,
    },
  },
}));

export default MarketingOptInExplicit;
