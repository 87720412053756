import React, { FC, HTMLProps } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
interface Props {
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary';
  fullWidth?: boolean;
  disabled?: boolean;
}

const Button: FC<Props & HTMLProps<HTMLButtonElement>> = (props) => {
  const { variant, fullWidth, className, disabled, ...rest } = props;
  const classes = useStyles();
  return (
    <button
      className={clsx(
        classes.root,
        fullWidth && classes.fullWidth,
        variant === 'secondary' && classes.secondary,
        disabled && classes.disabled,
        className
      )}
      disabled={disabled}
      {...rest}
    />
  );
};

const useStyles = makeStyles(
  (theme) => ({
    // when changing these styles, also change the braintree button style in the Payment component
    root: {
      ...theme.typography.button,
      cursor: 'pointer',
      width: 210,
      height: 45,
      padding: '10px  0px',
      color: theme.palette.button.primary.contrastText,
      backgroundColor: theme.palette.button.primary.main,
      borderWidth: 0,
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette.button.primary.hover,
        color: theme.palette.button.primary.contrastText,
      },
      '&:active': {
        backgroundColor: theme.palette.button.primary.active,
      },
      '&:disabled': {
        color: theme.palette.button.primary.inactiveText || '#000000',
        backgroundColor: theme.palette.button.primary.disabled,
        opacity: theme.palette.button.primary.disabledOpacity || 0.2,
      },
    },
    secondary: {
      border: `1px solid ${theme.palette.button.secondary.main}`,
      backgroundColor:
        theme.palette.button.secondary.backgroundColorDefault || theme.palette.button.secondary.contrastText,
      color: theme.palette.button.secondary.contrastTextDefault || theme.palette.button.secondary.main,
      '&:hover': {
        border: `1px solid ${theme.palette.button.secondary.hover}`,
        backgroundColor: theme.palette.button.secondary.hover,
        color: theme.palette.button.secondary.contrastText,
      },
      '&:active': {
        border: `1px solid ${theme.palette.button.secondary.active}`,
        backgroundColor: theme.palette.button.secondary.active,
        color: theme.palette.button.secondary.contrastText,
      },
    },
    fullWidth: {
      width: '100%',
    },
    disabled: {
      cursor: 'default',
    },
  }),
  { name: 'MuiGDButton' }
);

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey {
    MuiGDButton: keyof ReturnType<typeof useStyles>;
  }
}

export default Button;
