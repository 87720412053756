import { BaseConfig } from 'config';

const config: BaseConfig = {
  authHost: 'https://auth.europe-west1.gcp.commercetools.com',
  host: 'https://api.europe-west1.gcp.commercetools.com',
  locale: 'en-GB',
  loqateApiKey: 'RA95-KW17-TY66-XN39',
  bazaarvoiceUrl:
    'https://stg.api.bazaarvoice.com/notifications/rsa_encryption_key/public/current/?passkey=9m5v9x6t49c4ecnwvu7av6qb',
  paymentEnvironment: 'test',
  paymentMethods: {
    applePay: false,
    googlePay: false,
    paypal: false,
  },
  sentryDSN: 'https://5d1c19d9b4384df997e761ccd1c7b70f@o319558.ingest.sentry.io/5101124',
  theme: 'whitelabel',
  urls: {
    basket: '/basket.html',
    checkout: '/checkout.html',
    confirmation: '/confirmation.html',
    getInTouch: '/someUrl',
    privacyPolicy: '/someUrl',
    shippingAndReturns: '/someUrl',
    termsConditions: '/someUrl',
    catalog: '/',
    retailer: '',
    upsellOverview: '',
  },
  features: {
    displayTaxes: 'NONE',
    displayProductBrand: false,
    minimumStockQuantity: 3,
    externalTax: false,
    enableCartOptimisticResponse: false,
    showOutOfStock: true,
    use3DS: true,
    allowPOBoxes: true,
    displayLinkToPDP: false,
    crossSelling: false,
    marketingOptInExplicit: false,
    termsAndConditionsCheckbox: false,
  },
  textKeys: {},
  showCrossSellCta: false,
};

export default config;
