import { updateActiveCart } from 'common/interfaces/generated/updateActiveCart';
import i18n from 'common/providers/i18n';
import { GET_PRODUCT_VARIANT_PRICE_AND_STOCK, UPDATE_ACTIVE_CART } from 'common/queries';
import { useCart } from './useCart';
import useSnackbar from './useSnackbar';
import { SnackbarType } from 'common/interfaces/generated/globalTypes';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import getConfig from 'config';
import { getChannels } from 'common/lib/commercetools';

export const useCheckPrices = (): (() => void) => {
  const { cart, loading } = useCart();
  const client = useApolloClient();
  const snackbar = useSnackbar();

  const [getProduct] = useLazyQuery(GET_PRODUCT_VARIANT_PRICE_AND_STOCK, { fetchPolicy: 'network-only' });

  if (!cart) {
    return () => {
      if (!loading) {
        console.error('No cart.');
      }
    };
  }

  return async () => {
    // const { supplyChannelId, distributionChannelId } = await getChannels(client);
    // const total = cart?.taxedPrice?.totalNet.centAmount;
    // const res = await client.mutate<updateActiveCart>({
    //   mutation: UPDATE_ACTIVE_CART,
    //   variables: {
    //     id: cart.id,
    //     version: cart.version,
    //     actions: [
    //       {
    //         recalculate: {
    //           updateProductData: true,
    //         },
    //       },
    //     ],
    //   },
    // });
    // const newTotal = res?.data?.updateMyCart?.taxedPrice?.totalNet.centAmount;
    // if (total !== newTotal) {
    //   snackbar({ message: i18n.t('pricesHaveChanged'), type: SnackbarType.INFO });
    //   cart?.lineItems.forEach((lineItem) => {
    //     getProduct({
    //       variables: {
    //         sku: lineItem.variant?.sku,
    //         currency: getConfig().currency,
    //         supplyChannelId: supplyChannelId,
    //         distributionChannelId: distributionChannelId,
    //         country: getConfig().country,
    //       },
    //     });
    //   });
    // }
  };
};
