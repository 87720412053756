import React from 'react';
import CartButton from './components/CartButton';
import Providers from 'common/providers/Providers';
import { useCart } from 'common/hooks/useCart';
import { Hidden, makeStyles } from '@material-ui/core';
import getConfig from 'config';
import { getProductsNumber, getAnalyticsInfoFromLineItems } from 'common/lib/cart';
import useGraphqlMutation from 'common/hooks/useGraphlMutation';
import { SET_QUICKVIEW_STATE, GET_QUICKVIEW_STATE } from 'common/queries';
import { analytics } from 'common/analytics/actions';
import { getQuickviewState } from 'common/interfaces/generated/getQuickviewState';
import { useQuery } from '@apollo/client';
import { redirect } from 'common/lib/helpers';

export interface Props {
  hideWhenEmpty?: string;
}

const useStyles = makeStyles((theme) => ({
  cartButtonPlaceholder: {
    width: '40px',
    height: '27px',
    [theme.breakpoints.up('lg')]: {
      width: '46px',
      height: '32.7px',
    },
  },
}));

export const Cart = ({ hideWhenEmpty = 'false' }: Props): JSX.Element | null => {
  const { cart } = useCart();
  const { data } = useQuery(GET_QUICKVIEW_STATE);
  const [setQuickviewOpen] = useGraphqlMutation<getQuickviewState>(SET_QUICKVIEW_STATE);
  const classes = useStyles();

  if (JSON.parse(hideWhenEmpty) && (!cart || cart?.lineItems.length === 0)) {
    return <div className={classes.cartButtonPlaceholder}></div>;
  }

  return (
    <>
      <Hidden lgUp>
        <CartButton
          onClick={() => {
            redirect(getConfig().urls.basket);
          }}
          products={getProductsNumber(cart?.lineItems)}
        />
      </Hidden>
      <Hidden mdDown>
        <CartButton
          open={!!data?.isQuickviewOpen}
          onClick={() => {
            setQuickviewOpen({ variables: { open: !data?.isQuickviewOpen } });
            if (!data?.isQuickviewOpen) {
              if (cart) {
                analytics.viewElement(cart?.id, 'quickview', getAnalyticsInfoFromLineItems(cart?.lineItems));
              }
            }
          }}
          products={getProductsNumber(cart?.lineItems)}
        />
      </Hidden>
    </>
  );
};

const ProvidedCart = (props: Props) => {
  return (
    <Providers>
      <Cart {...props} />
    </Providers>
  );
};

export default ProvidedCart;
