import React from 'react';
import Txt from '../Txt';
import { getActiveCart_inStore_me_activeCart_lineItems } from 'common/interfaces/generated/getActiveCart';

interface Props {
  lineItem: getActiveCart_inStore_me_activeCart_lineItems;
}

const LineItemCardExtraDescription = (props: Props): JSX.Element => {
  const { lineItem } = props;

  return (
    <Txt data-testid="line-item-extra-description" fontWeight="light">
      {lineItem.custom?.customFieldsRaw?.find((e) => e.name === 'extraDescription')?.value}
    </Txt>
  );
};

export default LineItemCardExtraDescription;
