import React, { useState, createContext } from 'react';
import { makeStyles, Dialog, Backdrop } from '@material-ui/core';
import CloseIcon from 'common/icons/CloseIcon';
import ErrorContent from 'common/components/ErrorContent';
import i18n from './i18n';

interface Props {
  children?: JSX.Element;
}

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    cursor: 'pointer',
    marginLeft: 'auto',
    alignSelf: 'flex-end',
    margin: theme.spacing(4),
    marginBottom: 0,
  },
}));

export const ErrorDialogContext = createContext<(message: string) => void>((message: string) => {});

const ErrorDialog = (props: Props): JSX.Element => {
  const { children } = props;
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const openError = (message: string) => {
    setErrorMessage(message);
  };

  const onClose = () => {
    setErrorMessage(undefined);
  };

  return (
    <>
      <ErrorDialogContext.Provider value={openError}>{children}</ErrorDialogContext.Provider>

      <Dialog
        open={!!errorMessage}
        onClose={onClose}
        aria-labelledby="error-dialog"
        maxWidth={'md'}
        data-testid="dialog"
      >
        <CloseIcon
          className={classes.closeIcon}
          onClick={onClose}
          height={'55'}
          width={'55'}
          color={'#2D2D2D'}
          data-testid="dialog-close-icon"
        />
        <ErrorContent errorMessage={errorMessage} ctaFn={onClose} ctaText={i18n.t('Close')} />
        <Backdrop open={true} />
      </Dialog>
    </>
  );
};

export default ErrorDialog;
