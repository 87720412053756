import React from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
}

const ChevronRight = (props: Props): JSX.Element => {
  const { className, onClick, ...rest } = props;

  return (
    <svg
      {...rest}
      onClick={onClick}
      className={className}
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline stroke="#2D2D2D" strokeWidth="1.5" points="1 1 6 6 1 11" />
    </svg>
  );
};

export default ChevronRight;
