import React from 'react';
import Txt from '../Txt';
import { getActiveCart_inStore_me_activeCart_lineItems } from 'common/interfaces/generated/getActiveCart';
import { getProductBrand } from 'common/lib/commercetools';

interface Props {
  lineItem: getActiveCart_inStore_me_activeCart_lineItems;
  className?: string;
}

const LineItemCardBrand = (props: Props): JSX.Element => {
  const { lineItem, className } = props;
  return (
    <Txt data-testid="line-item-product-brand" fontWeight="bold" className={className}>
      {getProductBrand(lineItem.variant)}
    </Txt>
  );
};

export default LineItemCardBrand;
