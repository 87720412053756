import { useQuery } from '@apollo/client';
import { getStore } from 'common/interfaces/generated/getStore';
import { GET_STORE } from 'common/queries';
import getConfig from 'config';

export const useStore = () => {
  const { data, loading } = useQuery<getStore>(GET_STORE, {
    variables: { key: getConfig().commercetools.store },
  });
  const store = data?.store;

  return { store, loading };
};
