import gdamTheme from '../gdam/gdam-theme';

const theme: any = JSON.parse(JSON.stringify(gdamTheme));

theme.palette.button = {
  ...theme.palette.button,
  primary: {
    main: '#D7324F',
    hover: '#C31E3B',
    active: '#EB4663',
    contrastText: '#FFFFFF',
    disabled: '#8c8c8c',
  },
  secondary: {
    main: '#C31E3B',
    hover: '#C31E3B',
    active: '#EB4663',
    contrastText: '#FFFFFF',
    disabled: '#8c8c8c',
  },
};
theme.palette.primary = {
  ...theme.palette.primary,
  main: '#D7324F',
  contrastText: '#FFFFFF',
};
theme.palette.secondary = {
  ...theme.palette.secondary,
  main: '#D7324F',
  contrastText: '#FFFFFF',
};

theme.palette.info = { ...theme.palette.info, dark: '#0085C2' };

theme.typography.button = {
  ...theme.typography.button,
  fontSize: '15px',
  lineHeight: '20px',
  letterSpacing: 0.5,
  fontWeight: 500,
};
theme.typography.fontFamily = 'Montserrat, Helvetica, Arial, "Lucida Grande", sans-serif';
theme.typography.fontFaces = [...theme.typography.fontFaces];

export default theme;
