import React, { MutableRefObject, FocusEvent, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core';
import { validation, validate, phoneNumberRegexMap, postcodeRegexMap } from 'common/lib/validation';
import i18n from 'common/providers/i18n';
import Input from 'common/components/formComponents/Input';
import { checkoutPaths } from 'modules/Checkout/Checkout';
import { useNavigate } from 'react-router-dom';
import { useCart } from 'common/hooks/useCart';
import FormField from 'common/components/formComponents/FormField';
import CountrySelect from 'common/components/formComponents/CountrySelect';
import getConfig from 'config';
import {
  getActiveCart_inStore_me_activeCart_billingAddress,
  getActiveCart_inStore_me_activeCart_shippingAddress,
} from 'common/interfaces/generated/getActiveCart';
import { AddressInput } from 'common/interfaces/generated/globalTypes';
import AddressSearch, { Address } from 'react-loqate';
import AddressSearchInput from './AddressSearchInput';
import Txt from 'common/components/Txt';
import { removeUndefinedDeep } from 'common/lib/helpers';
import { getValidLoqatePostcode, mergeLoqateAddressLines } from 'common/lib/loqate';
import { makeAddressInputFromAddress } from 'common/lib/commercetools';
import StateSelect from 'common/components/formComponents/StateSelect';
import { getStates, stateSupported, getState } from 'common/lib/states';

export enum AddressType {
  Billing = 'Billing',
  Shipping = 'Shipping',
}

type AddressChangeCallback = (address: AddressInput, updatedFields: AddressInput) => void;
type SubmitCallback = (newAddress: AddressInput) => void;

interface Props {
  formRef: MutableRefObject<any>;
  address:
    | getActiveCart_inStore_me_activeCart_shippingAddress
    | getActiveCart_inStore_me_activeCart_billingAddress
    | null;
  onValidate: (isValid: boolean) => void;
  onSubmit: SubmitCallback;
  onAddressChange: AddressChangeCallback;
  addressType: AddressType;
}
const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 450,
  },
  addressSearchList: {
    background: 'white',
    border: '1px solid currentColor',
    listStyle: 'none',
    padding: 0,
  },
  addressSearchOption: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: 'white',
    '&:hover': { color: theme.palette.primary.contrastText, backgroundColor: theme.palette.primary.main },
  },
  searchableFields: (props: any) => ({
    visibility: props.open ? undefined : 'hidden',
    height: props.open ? undefined : 0,
  }),
}));

export const AddressForm = (props: Props): JSX.Element => {
  const config = getConfig();

  const { address, onSubmit, onAddressChange, formRef, onValidate, addressType } = props;
  const [open, setOpen] = useState(addressType === AddressType.Billing || address?.postalCode || false);
  const [isPostcodeLoqateValid, setIsPostcodeLoqateValid] = useState<boolean | undefined>(undefined);
  const [stateError, setStateError] = useState<string | undefined>(undefined);

  const classes = useStyles({ open });

  const navigate = useNavigate();
  const { cart } = useCart();
  if (cart && !cart.customerEmail) {
    navigate(checkoutPaths.email);
  }

  const initialValues = {
    firstName: address?.firstName ?? '',
    lastName: address?.lastName ?? '',
    address1: address?.streetName ?? '',
    address2: address?.additionalStreetInfo ?? '',
    city: address?.city ?? '',
    postcode: address?.postalCode ?? '',
    phone: address?.phone ?? '',
    state: address?.state ?? config.state ?? '',
  };
  // (react) state handling is all over the place; form state
  // handling should be done all through formik
  const valueCountry = address?.country || config.country;
  const valueState = address?.state || '';
  const states = getStates(valueCountry);

  useEffect(() => {
    formik.validateForm();
  }, [address]);

  const getCTAddressFromFormikValues = (values: typeof initialValues, state: string, country: string): AddressInput => {
    return {
      firstName: values.firstName,
      lastName: values.lastName,
      streetName: values.address1,
      additionalStreetInfo: values.address2,
      city: values.city,
      state: state,
      country: country,
      postalCode: values.postcode,
      phone: addressType === AddressType.Shipping ? values.phone : undefined,
    };
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const newAddress = getCTAddressFromFormikValues(values, valueState, valueCountry);
      await onSubmit(newAddress);
    },
    validate: (values) => {
      const errors: any = {};

      errors.firstName = validate(values.firstName, [validation.required]);
      errors.lastName = validate(values.lastName, [validation.required]);

      errors.address1 = validate(
        values.address1,
        config.features.allowPOBoxes ? [validation.required] : [validation.isNotPOBox, validation.required],
        {
          country: valueCountry,
        }
      );
      errors.address2 = validate(
        values.address2,
        addressType === AddressType.Billing || config.features.allowPOBoxes ? [] : [validation.isNotPOBox],
        {
          country: valueCountry,
        }
      );
      errors.city = validate(values.city, [validation.required]);
      errors.postcode = validate(values.postcode, [validation.required, validation.postcode], {
        country: valueCountry,
      });
      errors.phone = validate(
        values.phone,
        phoneNumberRegexMap[config.country]?.required && addressType === AddressType.Shipping
          ? [validation.required, validation.phone]
          : [validation.phone]
      );
      errors.state = validate(values.state, states.length > 0 ? [formValidateState] : []);

      const cleaned = removeUndefinedDeep(errors);

      onValidate(!Object.keys(cleaned).length);
      return cleaned;
    },
  });

  const formValidateState = (state: string | null) => {
    const skipStateValidation = states.length === 0;
    if (skipStateValidation) {
      return undefined;
    }

    if (!state) {
      return i18n.t('validation.required');
    }

    if (stateSupported(state, valueCountry)) {
      return undefined;
    }

    return i18n.t('validation.stateNotSupported');
  };

  const validateState = (state: string | null) => {
    const error = formValidateState(state);
    setStateError(error);
    if (error) {
      return undefined;
    }

    return state;
  };

  const validatePostCodeWithLoqate = async (postcode: string) => {
    if (!postcode) {
      setIsPostcodeLoqateValid(true);
      return postcode;
    }

    if (addressType !== AddressType.Billing) {
      const validated = await getValidLoqatePostcode(postcode, address?.country);
      setIsPostcodeLoqateValid(!!validated);
      return validated;
    }
  };

  const formatPostCode = (postcode: string) => {
    const regexForCountry = postcodeRegexMap[valueCountry];
    // Validated with the regex; no first see if we have a formatting rule
    // defined. Otherwise, let's just return the postcode itself.
    if (regexForCountry?.format) {
      return regexForCountry.format(postcode);
    }

    return postcode;
  };

  const setAddressFromSearch = (searchAddress: Address) => {
    const { addressLine1, addressLine2 } = mergeLoqateAddressLines(searchAddress);
    const postcode = searchAddress.PostalCode;
    // We can (and should) ignore province/state when no states are defined in the app itself
    const state = states.length > 0 ? searchAddress.ProvinceCode : '';
    const country = searchAddress.CountryIso2;

    formik.values.postcode = postcode;
    formik.values.address1 = addressLine1;
    formik.values.address2 = addressLine2;
    formik.values.city = searchAddress.City;
    formik.values.state = state;

    formik.validateForm();

    if (state) {
      validateState(state);
    }

    if (
      config.supportedCountries.includes(country) &&
      (country !== address?.country || state !== address?.state || postcode !== address?.postalCode)
    ) {
      const ctAddress = getCTAddressFromFormikValues(formik.values, state, country);
      onAddressChange(ctAddress, {
        country,
        state: state ?? undefined,
      });
    }
    setIsPostcodeLoqateValid(true);

    const input = window.document.getElementById('address-search-input') as HTMLInputElement | null;
    if (input) input.value = '';
    setOpen(true);
  };

  return (
    <form ref={formRef} className={classes.form} onSubmit={formik.handleSubmit} noValidate data-testid="address-form">
      <fieldset className={classes.form}>
        <legend className="legend">{i18n.t('Delivery')}</legend>

        <FormField
          required
          label={i18n.t('FirstName')!}
          error={formik.touched.firstName ? formik.errors.firstName : undefined}
        >
          <Input
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            data-testid="firstName"
          />
        </FormField>

        <FormField
          required
          label={i18n.t('LastName')!}
          error={formik.touched.lastName ? formik.errors.lastName : undefined}
        >
          <Input
            id="lastName"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            data-testid="lastName"
          />
        </FormField>

        {addressType === AddressType.Shipping && (
          <FormField
            error={formik.touched.phone ? formik.errors.phone : undefined}
            valid={formik.touched.phone && formik.values.phone ? !formik.errors.phone : undefined}
            label={i18n.t('Phone')!}
            contextLabel={config.textKeys.phoneContext && i18n.t(config.textKeys.phoneContext)!}
            required={phoneNumberRegexMap[config.country]?.required}
          >
            <Input
              type="tel"
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              data-testid="phone"
            />
          </FormField>
        )}

        {config.supportedCountries.length <= 1 && (
          <FormField
            data-testid="country-select"
            required
            label={i18n.t('Country')!}
            contextLabel={
              addressType === AddressType.Shipping
                ? config.textKeys.deliveryCountryContext && i18n.t(config.textKeys.deliveryCountryContext)!
                : undefined
            }
          >
            <CountrySelect
              id="country"
              name="country"
              value={valueCountry}
              onChange={(country) => {
                const newState = getState(valueState, country);
                onAddressChange(makeAddressInputFromAddress(address), {
                  country,
                  state: newState,
                });
              }}
              allCountries={addressType === AddressType.Billing}
            />
          </FormField>
        )}

        {addressType === AddressType.Shipping && (
          <FormField required>
            <AddressSearch
              locale={config.locale}
              apiKey={config.loqateApiKey}
              countries={config.supportedCountries}
              components={{ Input: AddressSearchInput }}
              classes={{
                list: classes.addressSearchList,
                listItem: classes.addressSearchOption,
              }}
              onSelect={setAddressFromSearch}
            />
          </FormField>
        )}

        {!open && (
          <span onClick={() => setOpen(true)}>
            <Txt variant="link">{i18n.t('EnterYourAddressManually')}</Txt>
          </span>
        )}
        <div className={classes.searchableFields}>
          {config.supportedCountries.length > 1 && (
            <FormField
              required
              label={i18n.t('Country')!}
              contextLabel={
                addressType === AddressType.Shipping
                  ? config.textKeys.deliveryCountryContext && i18n.t(config.textKeys.deliveryCountryContext)!
                  : undefined
              }
            >
              <CountrySelect
                id="country"
                name="country"
                value={valueCountry}
                onChange={(country) => {
                  const newState = getState(valueState, country);
                  onAddressChange(makeAddressInputFromAddress(address), {
                    country,
                    state: newState,
                  });
                  // make all values touched when country changes so validation errors are shown
                  const touched: any = {};
                  Object.keys(formik.values).forEach((k) => (touched[k] = true));
                  formik.setTouched(touched);
                }}
                data-testid="country"
                allCountries={addressType === AddressType.Billing}
              />
            </FormField>
          )}

          {states.length > 0 && (
            <FormField required label={i18n.t('State')!} error={stateError}>
              <StateSelect
                id="state"
                value={valueState}
                onChange={(state) => {
                  validateState(state);
                  onAddressChange(makeAddressInputFromAddress(address), {
                    country: valueCountry,
                    state,
                  });
                  formik.setTouched({ state: true });
                  formik.setFieldValue('state', state);
                }}
                country={valueCountry}
                data-testid="state"
              />
            </FormField>
          )}

          <FormField
            required
            label={i18n.t('AddressLine1')!}
            error={formik.touched.address1 ? formik.errors.address1 : undefined}
          >
            <Input
              id="address1"
              name="address1"
              value={formik.values.address1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              data-testid="address1"
            />
          </FormField>

          <FormField
            label={i18n.t('AddressLine2')!}
            error={formik.touched.address2 || formik.errors.address2 ? formik.errors.address2 : undefined}
          >
            <Input
              id="address2"
              name="address2"
              value={formik.values.address2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              data-testid="address2"
            />
          </FormField>

          <FormField required label={i18n.t('TownCity')!} error={formik.touched.city ? formik.errors.city : undefined}>
            <Input
              id="city"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                formik.handleBlur(e);
                // const city = e.target.value
                // onAddressChange(makeAddressInputFromAddress(address), {
                //   country: valueCountry,
                //   city,
                // });
              }}
              data-testid="city"
            />
          </FormField>

          {/* loqate checking is not handled through formik validation, since it should be non-blocking for continuing to payment */}
          <FormField
            required
            label={i18n.t('Postcode')!}
            valid={
              formik.touched.postcode && formik.values.postcode
                ? !formik.errors.postcode && isPostcodeLoqateValid !== false
                : undefined
            }
            error={
              formik.touched.postcode
                ? formik.values.postcode && isPostcodeLoqateValid === false && !formik.errors.postcode
                  ? i18n.t('validation.postcodeLoqate')!
                  : formik.errors.postcode
                : undefined
            }
            contextLabel={
              addressType === AddressType.Shipping
                ? config.textKeys.deliveryPostalCodeContext && i18n.t(config.textKeys.deliveryPostalCodeContext)!
                : undefined
            }
          >
            <Input
              id="postcode"
              name="postcode"
              value={formik.values.postcode}
              onChange={(e: any) => {
                e.target.value = formatPostCode(e.target.value);
                formik.handleChange(e);
              }}
              onBlur={async (e: FocusEvent<HTMLInputElement>) => {
                const value = e.target.value;
                formik.handleBlur(e);
                const loqateFormattedPostCode = await validatePostCodeWithLoqate(value);
                let formattedPostCode;
                if (loqateFormattedPostCode) {
                  formattedPostCode = loqateFormattedPostCode;
                } else {
                  formattedPostCode = formatPostCode(value);
                }
                if (formattedPostCode) formik.setFieldValue('postcode', formattedPostCode);

                onAddressChange(makeAddressInputFromAddress(address), {
                  country: valueCountry,
                  postalCode: formattedPostCode,
                });
              }}
              data-testid="postcode"
            />
          </FormField>
        </div>
      </fieldset>
    </form>
  );
};
