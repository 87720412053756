import React from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
}

const ArrowLeftIcon = (props: Props): JSX.Element => {
  const { className, onClick, ...rest } = props;
  return (
    <svg
      {...rest}
      onClick={onClick}
      className={className}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7071 19.7071C11.3166 20.0976 10.6834 20.0976 10.2929 19.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929L10.2929 6.29289C10.6834 5.90237 11.3166 5.90237 11.7071 6.29289C12.0976 6.68342 12.0976 7.31658 11.7071 7.70711L7.41421 12L21 12C21.5523 12 22 12.4477 22 13C22 13.5523 21.5523 14 21 14L7.41421 14L11.7071 18.2929C12.0976 18.6834 12.0976 19.3166 11.7071 19.7071Z"
        fill="#2D2D2D"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
