import React, { useEffect, useState } from 'react';
import { paypalCheckout } from 'braintree-web';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Clients, useSubmitNonce } from '../BraintreeHelpers';
import { useCart } from 'common/hooks/useCart';
import getConfig from 'config';
import { useErrorDialog } from 'common/hooks/useErrorDialog';
import i18n from 'common/providers/i18n';
import { analytics, PaymentMethod } from 'common/analytics/actions';
import { useLoading } from 'modules/Checkout/Checkout';
import useUpdateBillingAddress from 'common/hooks/useUpdateBillingAddress';
import useUpdateEmailShippingBilling from 'common/hooks/useUpdateEmailShippingBilling';
import { PaymentVariant } from 'common/interfaces/enums';

interface Props {
  className?: string;
  clients?: Clients;
  variant: PaymentVariant;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    '& #paypal-button': {
      maxWidth: `450px !important`,
      width: '100%',
      height: `40px !important`,
    },
    '& .paypal-button-content': {
      width: '100% !important',
      boxSizing: 'inherit !important',
      margin: '0px !important',
      height: `40px !important`,
      borderRadius: '0px !important',
      '&::before': {
        background: 'none !important',
      },
    },
    '& .paypal-button.paypal-style-checkout.paypal-size-medium.paypal-shape-rect .paypal-button-content': {
      height: `40px !important`,
      maxHeight: '40px !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: `${theme.typography.body1.fontSize}px !important`,
      fontWeight: 'bold',
      '& img': {
        marginTop: 1,
      },
    },
  },
}));

const PaypalButton = (props: Props): JSX.Element => {
  const { className, clients, variant } = props;
  const isQuickCheckout = variant === 'quickCheckout';
  const classes = useStyles();
  const { cart } = useCart();
  const { shippingAddress } = cart!;
  const [paypalClient, setPaypalClient] = useState<any | undefined>(undefined);
  const { setLoading } = useLoading();
  const errorDialog = useErrorDialog();
  const updateBillingAddress = useUpdateBillingAddress();
  const setEmailShippingBilling = useUpdateEmailShippingBilling();
  const submitNonce = useSubmitNonce();

  useEffect(() => {
    if (!clients || paypalClient) return;
    const init = async () => {
      const ppClient = await paypalCheckout.create({
        client: clients?.braintreeClient,
      });

      // ts ignore until typings are updated
      // @ts-ignore
      await ppClient.loadPayPalSDK({
        currency: getConfig().currency,
        intent: 'authorize',
      });

      setPaypalClient(ppClient);
    };
    init();
  }, [clients, paypalClient]);

  useEffect(() => {
    const container = document.getElementById('paypal-button');
    if (!paypalClient || container?.firstChild) return;

    const paypalPaymentProps: any = {
      flow: 'checkout',
      amount: (cart!.taxedPrice?.totalGross.centAmount / 100).toString(),
      currency: getConfig().currency,
      displayName: getConfig().brandName,
      locale: getConfig().locale,
      enableShippingAddress: true,
      shippingAddressEditable: isQuickCheckout,
      enableBillingAddress: true,
      billingAddressEditable: isQuickCheckout,
      onError: (err: Error) => {
        console.error('Paypal Button error:', err);
      },
    };

    if (!isQuickCheckout) {
      paypalPaymentProps.shippingAddressOverride = {
        recipientName: shippingAddress?.firstName + ' ' + shippingAddress?.lastName,
        line1: shippingAddress?.streetName,
        line2: shippingAddress?.lastName,
        city: shippingAddress?.city,
        countryCode: shippingAddress?.country,
        postalCode: shippingAddress?.postalCode,
        phone: shippingAddress?.phone,
      };
    }

    // ts ignore until typings are updated
    // @ts-ignore
    paypal
      // @ts-ignore
      .Buttons({
        // @ts-ignore
        fundingSource: paypal.FUNDING.PAYPAL,
        createOrder: () => {
          setLoading(true);
          return paypalClient.createPayment(paypalPaymentProps);
        },

        // @ts-ignore
        onApprove: async (data) => {
          const payload = await paypalClient.tokenizePayment(data);
          const paypalDetails = payload.details;

          const email = paypalDetails.email;
          const shippingAddress = {
            firstName: paypalDetails.shippingAddress.recipientName ?? '',
            lastName: '',
            streetName: paypalDetails.shippingAddress.line1 ?? '',
            additionalStreetInfo: paypalDetails.shippingAddress.line2 ?? '',
            postalCode: paypalDetails.shippingAddress.postalCode ?? '',
            city: paypalDetails.shippingAddress.city ?? '',
            country: paypalDetails.shippingAddress.countryCode ?? '',
          };
          const billingAddress = {
            firstName: paypalDetails.firstName ?? '',
            lastName: paypalDetails.lastName ?? '',
            streetName: paypalDetails.billingAddress.line1 ?? '',
            additionalStreetInfo: paypalDetails.billingAddress.line2 ?? '',
            postalCode: paypalDetails.billingAddress.postalCode ?? '',
            city: paypalDetails.billingAddress.city ?? '',
            country: paypalDetails.billingAddress.countryCode ?? '',
          };
          const shippingCountry = shippingAddress.country.toUpperCase();
          if (!getConfig().supportedCountries.includes(shippingCountry)) {
            setLoading(false);
            errorDialog(
              i18n.t('errors.noShippingForCountry', {
                shippingCountries: getConfig()
                  .supportedCountries.map((country) => i18n.t(`countriesLong.${country}`))
                  .join(', '),
              })
            );
            return;
          }

          let res;

          if (isQuickCheckout) {
            res = await setEmailShippingBilling(email, shippingAddress, billingAddress);
          } else {
            res = await updateBillingAddress(billingAddress);
          }

          if (res?.updateMyCart) {
            analytics.pay(res.updateMyCart.id, PaymentMethod.PAYPAL, variant);
            submitNonce(
              payload.nonce,
              clients,
              res.updateMyCart,
              res.updateMyCart.version,
              res.updateMyCart.billingAddress
            );
          }
        },
        onCancel: () => {
          setLoading(false);
        },
        onError: (err) => {
          setLoading(false);
          console.error('PayPal error', err);
          errorDialog(i18n.t('errors.tryDifferentPaymentMethod'));
        },
      })
      .render('#paypal-button');
  }, [cart, paypalClient]);

  return <div className={clsx(classes.root, className)} id="paypal-button"></div>;
};

export default PaypalButton;
