import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const InvalidIcon = ({ className }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <svg
      className={clsx(classes.root, className)}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="invalidIcon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47594 0 0 4.47715 0 10C0 15.5241 4.47715 20 10 20C15.5241 20 20 15.5228 20 10C20 4.47594 15.5228 0 10 0ZM14.0573 12.7717L11.2856 10L14.0573 7.22832C14.3017 6.98396 14.3017 6.58781 14.0573 6.34343L13.6566 5.94268C13.4122 5.69832 13.0157 5.69866 12.7717 5.94267L10 8.71435L7.22832 5.94267C6.98396 5.69832 6.58781 5.69829 6.34343 5.94268L5.94268 6.34343C5.69832 6.58779 5.69866 6.98431 5.94267 7.22832L8.71435 10L5.94267 12.7717C5.69832 13.016 5.69829 13.4122 5.94268 13.6566L6.34343 14.0573C6.58779 14.3017 6.98431 14.3013 7.22832 14.0573L10 11.2856L12.7717 14.0573C13.016 14.3017 13.4122 14.3017 13.6566 14.0573L14.0573 13.6566C14.3017 13.4122 14.3013 13.0157 14.0573 12.7717Z"
        fill="#D93F6A"
      />
    </svg>
  );
};

export default InvalidIcon;
