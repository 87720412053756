import React from 'react';
import Txt from 'common/components/Txt';
import i18n from 'common/providers/i18n';
import getConfig from 'config';

interface Props {}

const NeedHelp = (props: Props): JSX.Element => {
  return (
    <span>
      <Txt fontWeight="light">{i18n.t('NeedHelp?')}</Txt>{' '}
      <a target="_blank" rel="noopener noreferrer" href={getConfig().urls.getInTouch}>
        <Txt variant="link">{i18n.t('GetInTouch')}</Txt>
      </a>
    </span>
  );
};

export default NeedHelp;
