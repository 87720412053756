import React, { useEffect, useState } from 'react';
import { useCart } from 'common/hooks/useCart';
import { useApolloClient } from '@apollo/client';
import { GET_PRODUCT_VARIANT_PRICE_AND_STOCK } from 'common/queries';
import getConfig from 'config';
import { getProductVariantPriceStock } from 'common/interfaces/generated/getProductVariantPriceStock';
import CheckoutPayment from './CheckoutPayment';
import ErrorContent from 'common/components/ErrorContent';
import i18n from 'common/providers/i18n';
import { redirect } from 'common/lib/helpers';
import { Clients } from './BraintreeHelpers';
import { getChannels, getProductName } from 'common/lib/commercetools';

interface InsufficientStock {
  name: String;
  available: number;
}

interface Props {
  braintreeClients?: Clients;
}

const CheckoutPaymentStockCheck = (props: Props): JSX.Element => {
  const { braintreeClients } = props;
  const { cart } = useCart();
  const { lineItems } = cart!;
  const client = useApolloClient();
  const [noStock, setNoStock] = useState<(InsufficientStock | undefined)[]>([]);

  useEffect(() => {
    const checkStock = async () => {
      const { supplyChannelId, distributionChannelId } = await getChannels(client);

      const promises = lineItems.map(
        async (li) =>
          await client.query({
            query: GET_PRODUCT_VARIANT_PRICE_AND_STOCK,
            fetchPolicy: 'network-only',
            variables: {
              sku: li.variant?.sku,
              supplyChannelId: supplyChannelId,
              distributionChannelId: distributionChannelId,
              currency: getConfig().currency,
              country: getConfig().country,
            },
          })
      );
      const res = await Promise.all(promises);
      const noStockProds = lineItems
        .map((li, index) => {
          const name = getProductName(li.variant);
          const inCart = li.quantity;
          const data: getProductVariantPriceStock = res[index].data;
          const inStock = data.product?.masterData.current?.variant?.availability?.channels.results.length
            ? data.product?.masterData.current?.variant?.availability?.channels.results[0].availability
                .availableQuantity
            : 0;
          // its allowed to still be bought at minimum stock, so +1
          const available = inStock - getConfig().features.minimumStockQuantity + 1;
          return inCart > available ? { name, available } : undefined;
        })
        .filter((e) => e);

      setNoStock(noStockProds);
    };

    checkStock();
  }, []);

  if (noStock.length)
    return (
      <ErrorContent
        errorMessage={noStock
          .map((e) => {
            if (e) {
              if (e?.available <= 0) return i18n.t('errors.noMoreLeft', { name: e.name });
              return i18n.t('errors.onlyNLeft', { name: e?.name, available: e?.available });
            }
          })
          .join('\n')}
        ctaFn={() => {
          redirect(getConfig().urls.basket);
        }}
        ctaText="Go back"
      />
    );
  return <CheckoutPayment braintreeClients={braintreeClients} />;
};

export default CheckoutPaymentStockCheck;
