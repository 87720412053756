import { updateActiveCart } from 'common/interfaces/generated/updateActiveCart';
import {
  createMyCart,
  createMyCart_createMyCart,
  createMyCart_createMyCart_lineItems,
  createMyCart_createMyCart_lineItems_price,
  createMyCart_createMyCart_lineItems_totalPrice,
  createMyCart_createMyCart_lineItems_variant,
} from 'common/interfaces/generated/createMyCart';
import { getDiscountsFromCart, getSubtotalsForLineItemsFromCart } from 'common/lib/cart';
import { getActiveCart_inStore_me_activeCart } from 'common/interfaces/generated/getActiveCart';
import { Discount } from 'common/interfaces';
import { getProductVariantPriceStock_product } from 'common/interfaces/generated/getProductVariantPriceStock';
import { AddressInput } from 'common/interfaces/generated/globalTypes';
import getConfig from 'config';
import { getShippingMethodsByCart_shippingMethodsByCart } from 'common/interfaces/generated/getShippingMethodsByCart';

type CartTypes = getActiveCart_inStore_me_activeCart | createMyCart_createMyCart;

const getShippingCostsFromCart = (
  cart: CartTypes
): { shippingCostGrossCentAmount: number; shippingCostNetCentAmount: number } => {
  const { shippingInfo } = cart;
  const shippingGrossCentAmount = shippingInfo?.taxedPrice?.totalGross.centAmount || 0;
  const shippingNetCentAmount = shippingInfo?.taxedPrice?.totalNet.centAmount || 0;
  const [shippingDiscounts] = getDiscountsFromCart(cart);

  const calculateShippingCosts = (shippingCentAmount: number, shippingDiscounts: Discount[]) =>
    shippingCentAmount + shippingDiscounts.reduce((a, c) => a + c.centAmount, 0);

  const shippingCostGrossCentAmount = !!shippingGrossCentAmount
    ? calculateShippingCosts(shippingGrossCentAmount, shippingDiscounts)
    : 0;
  const shippingCostNetCentAmount = !!shippingNetCentAmount
    ? calculateShippingCosts(shippingNetCentAmount, shippingDiscounts)
    : 0;

  return { shippingCostGrossCentAmount, shippingCostNetCentAmount };
};

const getTotalsForCart = (cart: CartTypes): { totalGrossCentAmount: number; totalNetCentAmount: number } => {
  const { subtotalGrossCentAmount, subtotalNetCentAmount } = getSubtotalsForLineItemsFromCart(cart);
  const { shippingCostGrossCentAmount, shippingCostNetCentAmount } = getShippingCostsFromCart(cart);

  const totalNetCentAmount = shippingCostNetCentAmount + subtotalNetCentAmount;
  const totalGrossCentAmount = shippingCostGrossCentAmount + subtotalGrossCentAmount;

  return { totalGrossCentAmount, totalNetCentAmount };
};

// TODO: The +3 is very arbitrary; depends on the amount of API extensions that will kick in.
const getNextCartVersion = (cart: CartTypes) => cart.version + 3;

export function makeOptimisticResponseForLineItems(cart: createMyCart_createMyCart): createMyCart;
export function makeOptimisticResponseForLineItems(cart: getActiveCart_inStore_me_activeCart): updateActiveCart;
export function makeOptimisticResponseForLineItems(cart: CartTypes): any {
  const mutationKey = cart.version === 0 ? 'createMyCart' : 'updateMyCart';

  if (!cart.taxedPrice) {
    return { [mutationKey]: { ...cart } };
  }

  const { totalGrossCentAmount, totalNetCentAmount } = getTotalsForCart(cart);
  return {
    [mutationKey]: {
      ...cart,
      version: getNextCartVersion(cart),
      taxedPrice: {
        ...cart.taxedPrice,
        totalGross: {
          ...cart.taxedPrice?.totalGross,
          centAmount: totalGrossCentAmount,
        },
        totalNet: {
          ...cart.taxedPrice?.totalNet,
          centAmount: totalNetCentAmount,
        },
      },
    },
  };
}

export const makeOptimisticResponseForAddressChange = (
  cart: getActiveCart_inStore_me_activeCart,
  updatedFields: AddressInput
): updateActiveCart => {
  if (!cart.shippingAddress)
    return {
      updateMyCart: { ...cart },
    };

  return {
    updateMyCart: {
      ...cart,
      shippingAddress: {
        ...cart.shippingAddress,
        ...updatedFields,
      },
    },
  };
};

export const makeOptimisticResponseForShippingMethod = (
  cart: getActiveCart_inStore_me_activeCart,
  shippingMethod: getShippingMethodsByCart_shippingMethodsByCart
): updateActiveCart => {
  if (
    !cart.shippingInfo?.taxedPrice ||
    getConfig().features.externalTax ||
    !cart.shippingInfo.shippingMethod ||
    !cart.taxedPrice
  )
    return {
      updateMyCart: { ...cart },
    };
  const rate = shippingMethod.zoneRates[0]?.shippingRates[0];
  let shippingPriceNet = rate?.price.centAmount || 0;

  const { subtotalGrossCentAmount } = getSubtotalsForLineItemsFromCart(cart);
  if (rate?.freeAbove && subtotalGrossCentAmount > rate.freeAbove.centAmount) {
    shippingPriceNet = 0;
  }

  const oldShippingPriceGross = cart.shippingInfo?.taxedPrice?.totalGross.centAmount;
  const oldShippingPriceNet = cart.shippingInfo?.taxedPrice?.totalNet.centAmount;

  const includedInPrice = cart.shippingInfo.taxRate ? cart.shippingInfo.taxRate.includedInPrice : true;
  const taxRate = cart.shippingInfo.taxRate?.amount || 0.2;

  const shippingPriceGross = includedInPrice ? shippingPriceNet : shippingPriceNet * (1 + taxRate);

  const newCartTotalGross = cart.taxedPrice?.totalGross.centAmount - oldShippingPriceGross + shippingPriceGross;
  const newCartTotalNet = cart.taxedPrice?.totalNet.centAmount - oldShippingPriceNet + shippingPriceNet;

  return {
    updateMyCart: {
      ...cart,
      version: getNextCartVersion(cart),
      shippingInfo: {
        ...cart.shippingInfo,
        shippingMethod: {
          ...cart.shippingInfo?.shippingMethod,
          id: shippingMethod.id,
        },
        taxedPrice: {
          ...cart.shippingInfo?.taxedPrice,
          totalGross: {
            ...cart.shippingInfo?.taxedPrice?.totalGross,
            centAmount: shippingPriceGross,
          },
          totalNet: {
            ...cart.shippingInfo?.taxedPrice?.totalNet,
            centAmount: shippingPriceNet,
          },
        },
      },
      taxedPrice: {
        ...cart.taxedPrice,
        totalGross: {
          ...cart.taxedPrice?.totalGross,
          centAmount: newCartTotalGross,
        },
        totalNet: {
          ...cart.taxedPrice?.totalNet,
          centAmount: newCartTotalNet,
        },
      },
    },
  };
};

export const makeOptimisticResponseForMarketingOptIn = (
  cart: getActiveCart_inStore_me_activeCart,
  nextOptIn: boolean
): updateActiveCart => {
  return {
    updateMyCart: {
      ...cart,
      version: getNextCartVersion(cart),
      custom: {
        __typename: 'CustomFieldsType',
        customFieldsRaw: [
          {
            __typename: 'RawCustomField',
            name: 'marketingConsent',
            value: nextOptIn,
          },
        ],
      },
    },
  };
};

export const makeOptimisticResponseForLineItem = ({
  sku,
  variant,
  totalPrice,
  price,
  supplyChannelId,
  distributionChannelId,
}: {
  sku: string;
  variant?: createMyCart_createMyCart_lineItems_variant;
  totalPrice?: createMyCart_createMyCart_lineItems_totalPrice;
  price?: createMyCart_createMyCart_lineItems_price;
  supplyChannelId: string;
  distributionChannelId: string;
}): createMyCart_createMyCart_lineItems => {
  // TODO: Tax-rate should be dynamic
  const taxRate = getConfig().features.externalTax ? 0 : 0.2;
  const includedInPrice = true;

  return {
    __typename: 'LineItem',
    id: sku,
    quantity: 1,
    productId: '',
    productType: { __typename: 'ProductTypeDefinition', name: '' },
    productSlugAllLocales: [{ __typename: 'LocalizedString', locale: 'en-GB', value: 'testProductSlug' }],
    variant: variant ?? null,
    discountedPricePerQuantity: [],
    totalPrice: (totalPrice as createMyCart_createMyCart_lineItems['totalPrice']) ?? null,
    price: (price as createMyCart_createMyCart_lineItems['price']) ?? null,
    taxRate: { __typename: 'TaxRate', includedInPrice, amount: taxRate },
    supplyChannel: { __typename: 'Channel', id: supplyChannelId },
    distributionChannel: { __typename: 'Channel', id: distributionChannelId },
    custom: {
      __typename: 'CustomFieldsType',
      customFieldsRaw: [],
    },
  };
};

interface AddToCartItem {
  sku: string;
  product: getProductVariantPriceStock_product;
}

export const makeOptimisticResponseForAddToCart = ({
  cart,
  items,
  supplyChannelId,
  distributionChannelId,
}: {
  cart: getActiveCart_inStore_me_activeCart;
  items: AddToCartItem[];
  supplyChannelId: string;
  distributionChannelId: string;
}) => {
  let lineItems = [...cart.lineItems];

  for (const item of items) {
    const isExistingLineItem = lineItems.find((li) => li.variant?.sku === item.sku);

    if (isExistingLineItem) {
      lineItems = lineItems.map((li) => {
        if (li.variant?.sku === item.sku) {
          return { ...isExistingLineItem, quantity: isExistingLineItem.quantity + 1 };
        }
        return li;
      });
    } else {
      lineItems = [
        ...lineItems,
        makeOptimisticResponseForLineItem({
          sku: item.sku,
          variant: item.product.masterData.current?.variant ?? undefined,
          price: item.product.masterData.current?.variant?.price ?? undefined,
          supplyChannelId,
          distributionChannelId,
        }),
      ];
    }
  }

  const result = makeOptimisticResponseForLineItems({
    ...cart,
    lineItems,
  });

  return result as unknown as updateActiveCart;
};
