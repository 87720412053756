import React from 'react';
import { makeStyles, Hidden } from '@material-ui/core';
import i18n from 'common/providers/i18n';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkoutPaths } from 'modules/Checkout/Checkout';
import OrderSummary from 'common/components/OrderSummary/OrderSummary';
import Txt from 'common/components/Txt';
import OrderSummaryTotals from 'common/components/OrderSummary/OrderSummaryTotals';
import OrderSummaryPromoCode from 'common/components/OrderSummary/OrderSummaryPromoCode';
import CheckoutDelivery from './scenes/CheckoutDelivery/CheckoutDelivery';
import LineItemCards from 'common/components/LineItemCard/LineItemCards';
import OrderSummaryLineItemCard from 'common/components/OrderSummary/OrderSummaryLineItemCard';
import MobileOrderSummary from 'common/components/MobileOrderSummary/MobileOrderSummary';
import Sticky from 'common/components/Sticky';
import CheckoutPaymentStockCheck from './scenes/CheckoutPayment/CheckoutPaymentStockCheck';
import { Clients } from './scenes/CheckoutPayment/BraintreeHelpers';
import BackButton from './components/BackButton';

interface Props {
  braintreeClients?: Clients;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(6),
    maxWidth: 600 + 150 + 450,
    width: '100%',
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 450,
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  spacer: {
    width: 38,
  },
  addressPayment: {
    width: '100%',
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  responsiveTitle: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(8),
  },
  sticky: {
    marginLeft: 'auto',
    flexShrink: 0,
    width: 450,
  },
}));

const CheckoutDeliveryPayment = (props: Props): JSX.Element => {
  const { braintreeClients } = props;
  const classes = useStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const windowHeight = window.innerHeight;

  const onBackButtonClick = () => {
    switch (pathname) {
      case checkoutPaths.delivery:
        navigate(checkoutPaths.email);
        break;
      case checkoutPaths.payment:
        navigate(checkoutPaths.delivery);
        break;
      default:
        navigate('/');
    }
  };

  return (
    <div className={classes.root} data-testid="checkout-delivery-payment">
      <section className={classes.addressPayment}>
        <nav className={classes.nav}>
          <BackButton onClick={onBackButtonClick} />
          {pathname === checkoutPaths.delivery ? (
            <Txt align="center" variant={'headingFour'} fontWeight="bold">
              {i18n.t('Delivery')}
            </Txt>
          ) : (
            <Txt align="center" variant={'headingFour'} fontWeight="bold">
              {i18n.t('Payment')}
            </Txt>
          )}
          <div className={classes.spacer}></div> {/*needed for styling */}
        </nav>
        {pathname === checkoutPaths.delivery ? (
          <CheckoutDelivery />
        ) : (
          <CheckoutPaymentStockCheck braintreeClients={braintreeClients} />
        )}
        <Hidden xlUp>
          <MobileOrderSummary>
            <LineItemCards maxHeight={300} component={OrderSummaryLineItemCard} variant="checkout" />
            <OrderSummaryTotals spacing="small" />
            <OrderSummaryPromoCode />
          </MobileOrderSummary>
        </Hidden>
      </section>
      <Hidden lgDown>
        <Sticky className={classes.sticky}>
          <OrderSummary>
            <LineItemCards
              maxHeight={windowHeight >= 920 ? 375 : 250}
              component={OrderSummaryLineItemCard}
              variant="checkout"
            />
            <OrderSummaryTotals />
            <OrderSummaryPromoCode />
          </OrderSummary>
        </Sticky>
      </Hidden>
    </div>
  );
};

export default CheckoutDeliveryPayment;
