import { createTheme } from '@material-ui/core/styles';
import getConfig, { Theme } from 'config';

import robertsradioTheme from './robertsradio/robertsradio-theme';
import morphyRichardsTheme from './morphy-richards/morphy-richards-theme';
import convectAirTheme from './gdam/convectair-theme';
import cadetTheme from './gdam/cadet-theme';
import dimplexTheme from './gdam/dimplex-theme';
import faberTheme from './gdam/faber-theme';
import nectreTheme from './gdam/nectre-theme';
import gdamTheme from './gdam/gdam-theme';
import whitelabelTheme from './whitelabel/whitelabel-theme';

export const getTheme = (theme?: Theme) => {
  switch (theme || getConfig()?.theme) {
    case 'robertsradio':
      return robertsradioTheme;
    case 'convectair':
      return convectAirTheme;
    case 'cadet':
      return cadetTheme;
    case 'dimplex':
      return dimplexTheme;
    case 'faber':
      return faberTheme;
    case 'nectre':
      return nectreTheme;
    case 'gdam':
      return gdamTheme;
    case 'morphy-richards':
      return morphyRichardsTheme;
    default:
      return whitelabelTheme;
  }
};

const muiTheme = createTheme(getTheme());
export default muiTheme;
