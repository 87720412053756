import React from 'react';
import ReactSelect, { components, Props as ReactSelectProps } from 'react-select';
import { makeStyles, Theme } from '@material-ui/core';

import ChevronDown from 'common/icons/ChevronDown';
import i18n from 'common/providers/i18n';

interface Props extends ReactSelectProps {
  id?: string;
  name?: string;
  disabled?: boolean;
}

const textStyling = (theme: Theme) => ({
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
  fontWeight: '300',
  color: theme.palette.text.primary,
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: 12,
    '& .react-select__control': {
      border: 'none',
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.grey['700']}`,
      '&::placeholder': {
        color: theme.palette.grey['600'],
        fontWeight: 300,
      },
      '&:hover': {
        borderColor: theme.palette.grey['700'],
      },
    },
    '& .react-select__control--is-focused': {
      outline: 'none',
      border: 'none',
      boxShadow: 'none',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '& .react-select__value-container': {
      border: 'none',
    },
    '& .react-select__menu': {
      marginTop: 0,
      boxShadow: theme.shadows[2],
      zIndex: 2,
    },
    '& .react-select__input': {
      fontSize: theme.typography.body1.fontSize,
    },
    '& .react-select__control--is-disabled': {
      backgroundColor: 'transparent',
      '& .react-select__single-value--is-disabled': {
        color: theme.palette.grey['600'],
        fontWeight: '300',
      },
      '& .react-select__indicators': {
        visibility: 'hidden',
      },
    },
    '& .react-select__option--is-focused': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
    },
    '& .react-select__option--is-selected': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
    },
    '& .react-select__option:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '& .react-select__option': {
      paddingLeft: theme.spacing(8),
      ...textStyling(theme),
    },
    '& .react-select__single-value': {
      ...textStyling(theme),
    },
    '& .react-select__menu-notice': {
      ...textStyling(theme),
    },
  },
}));

const DropdownIndicator = (props: any) => {
  // for some reason when using an if statement here, the chevron renders just fine but is not clickable
  // TODO: check on new version of app

  // if (props.selectProps?.menuIsOpen)
  //   return (
  //     <components.DropdownIndicator {...props}>
  //       <UpChevron />
  //     </components.DropdownIndicator>
  //   );
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown />
    </components.DropdownIndicator>
  );
};

const Select = (props: Props): JSX.Element => {
  const { options, value, onChange, id, name, disabled } = props;
  const classes = useStyles();

  return (
    <ReactSelect
      classNamePrefix="react-select"
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
      className={classes.root}
      value={value}
      options={options}
      onChange={onChange}
      inputId={id}
      name={name}
      noOptionsMessage={() => i18n.t('NoOptions')}
      isDisabled={disabled}
    />
  );
};

export default Select;
