import { filterChannels } from 'common/lib/commercetools';
import { useCart } from './useCart';
import { DistributionChannelId, SupplyChannelId } from 'global';
import { useStore } from './useStore';

interface UseChannelsReturn {
  loading: boolean;
  channels: {
    distributionChannelId: DistributionChannelId;
    supplyChannelId: SupplyChannelId;
  };
}

export const useChannels = (): UseChannelsReturn => {
  const { store, loading } = useStore();

  const { cart } = useCart();
  if (loading || !store) {
    return {
      loading: loading,
      channels: {
        distributionChannelId: undefined,
        supplyChannelId: undefined,
      },
    };
  }

  const { distributionChannelId, supplyChannelId } = filterChannels({
    store: store!,
    shippingAddress: cart?.shippingAddress,
  });

  return {
    loading: false,
    channels: {
      distributionChannelId: distributionChannelId,
      supplyChannelId: supplyChannelId,
    },
  };
};
