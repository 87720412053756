import { GET_ACTIVE_CART, SET_MOST_RECENT_SKU } from 'common/queries';
import { analytics } from 'common/analytics/actions';
import { getAnalyticsInfoFromLineItems, ensureCart } from 'common/lib/cart';
import { ApolloClient, useApolloClient } from '@apollo/client';
import i18n from 'common/providers/i18n';
import { useErrorDialog } from './useErrorDialog';
import { getActiveCart, getActiveCart_inStore_me_activeCart } from 'common/interfaces/generated/getActiveCart';
import { getChannels, getProductPriceAndStock } from 'common/lib/commercetools';
import getConfig from 'config';
import useGraphqlMutation from './useGraphlMutation';
import { getMostRecentSku } from 'common/interfaces/generated/getMostRecentSku';
import { makeOptimisticResponseForAddToCart } from 'common/lib/optimistic/cart';
import { useUpdateActiveCart } from './useUpdateActiveCart';

const checkProductAvailabilityFnc = (
  client: ApolloClient<object>,
  cart: getActiveCart_inStore_me_activeCart | null
) => {
  return async (sku: string) => {
    const { product, available } = await getProductPriceAndStock({
      client,
      sku,
      cart,
    });

    return {
      sku,
      product,
      available,
    };
  };
};

export const useAddToCart = ({
  sku,
  extraDescription,
  fromCrossSell,
  upsellSkus,
}: {
  sku: string;
  extraDescription?: string;
  fromCrossSell?: boolean;
  upsellSkus?: string[];
}) => {
  const client = useApolloClient();
  const errorDialog = useErrorDialog();
  const [setMostRecentSku] = useGraphqlMutation<getMostRecentSku>(SET_MOST_RECENT_SKU);
  const updateActiveCart = useUpdateActiveCart();

  return async () => {
    const resCartQuery = await client.query<getActiveCart>({
      query: GET_ACTIVE_CART,
      variables: {
        storeKey: getConfig().commercetools.store,
      },
    });
    let cart = resCartQuery?.data?.inStore?.me?.activeCart;
    const checkProductAvailability = checkProductAvailabilityFnc(client, cart);

    const { product, available } = await checkProductAvailability(sku);

    let resolvedUpsell =
      upsellSkus && upsellSkus.length > 0
        ? await Promise.all(upsellSkus.map((sku) => checkProductAvailability(sku)))
        : [];

    if (!product || !available) {
      errorDialog(i18n.t('noStock'));
      return;
    }

    if (!cart) {
      cart = await ensureCart(client);
      if (!cart) {
        throw new Error('Cart could not be created');
      }
    }

    const { supplyChannelId, distributionChannelId } = await getChannels(client);

    const optimisticResponse = getConfig().features.enableCartOptimisticResponse
      ? makeOptimisticResponseForAddToCart({
          cart,
          items: [
            {
              sku,
              product,
            },
            ...resolvedUpsell.map(({ sku, product }: { sku: string; product: any }) => ({
              sku,
              product,
            })),
          ],
          supplyChannelId,
          distributionChannelId,
        })
      : undefined;

    const custom = extraDescription
      ? {
          typeKey: 'custom-line-item-fields',
          fields: [{ name: 'extraDescription', value: `\"${extraDescription}\"` }],
        }
      : undefined;

    const actions = [
      {
        addLineItem: {
          sku,
          supplyChannel: {
            id: supplyChannelId,
          },
          distributionChannel: {
            id: distributionChannelId,
          },
          custom: custom,
        },
      },
      ...(upsellSkus ?? []).map((sku) => ({
        addLineItem: {
          sku,
          supplyChannel: {
            id: supplyChannelId,
          },
          distributionChannel: {
            id: distributionChannelId,
          },
        },
      })),
    ];

    setMostRecentSku({ variables: { sku } });

    const resMutation = await updateActiveCart({
      cart,
      actions,
      optimisticResponse,
      checkShipingMethod: true,
    });

    const mainLineItem = resMutation?.data?.updateMyCart?.lineItems.filter((li) => li.variant?.sku === sku);
    const upsellLineItems = resMutation?.data?.updateMyCart?.lineItems.filter(
      (li) => li.variant?.sku && upsellSkus?.includes(li.variant?.sku)
    );

    if (mainLineItem) {
      const analyticsLineItems = getAnalyticsInfoFromLineItems(
        mainLineItem,
        upsellSkus && upsellSkus.length > 0 && false
      ).map((li) => {
        li.quantity = 1;
        return li;
      });

      if (upsellLineItems) {
        const upsellAnalyticsLineItems = getAnalyticsInfoFromLineItems(upsellLineItems, true).map((li) => {
          li.quantity = 1;
          return li;
        });
        analytics.addLineItem(cart.id, !!fromCrossSell, analyticsLineItems.concat(upsellAnalyticsLineItems));
      } else {
        analytics.addLineItem(cart.id, !!fromCrossSell, analyticsLineItems);
      }
    }
  };
};
