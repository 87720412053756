import { useApolloClient } from '@apollo/client';
import { getActiveCart_inStore_me_activeCart } from 'common/interfaces/generated/getActiveCart';
import { getShippingMethodsByCart } from 'common/interfaces/generated/getShippingMethodsByCart';
import { MyCartUpdateAction } from 'common/interfaces/generated/globalTypes';
import { updateActiveCart } from 'common/interfaces/generated/updateActiveCart';
import { GET_SHIPPING_METHODS_BY_CART, UPDATE_ACTIVE_CART } from 'common/queries';

/**
 * Updates the active cart and also checks if we still have a valid
 * shipping method on the cart after the update.
 * If not, it will query for available shipping methods
 * and either reset it or set the first shipping method as default
 */
export const useUpdateActiveCart = () => {
  const client = useApolloClient();

  return async ({
    cart,
    actions,
    optimisticResponse,
    checkShipingMethod,
  }: {
    cart: getActiveCart_inStore_me_activeCart;
    actions: MyCartUpdateAction[];
    optimisticResponse?: any;
    checkShipingMethod?: boolean;
  }) => {
    let resMutation = await client.mutate<updateActiveCart>({
      mutation: UPDATE_ACTIVE_CART,
      variables: {
        id: cart?.id,
        version: cart?.version,
        actions,
      },
      optimisticResponse,
    });
    const updatedCart = resMutation.data?.updateMyCart;

    if (checkShipingMethod && updatedCart?.shippingInfo?.shippingMethodState === 'DoesNotMatchCart') {
      const {
        data: { shippingMethodsByCart },
      } = await client.query<getShippingMethodsByCart>({
        query: GET_SHIPPING_METHODS_BY_CART,
        variables: {
          cartId: updatedCart?.id,
        },
        fetchPolicy: 'network-only',
      });

      const method = shippingMethodsByCart[0];

      const newShippingMethod = method
        ? {
            typeId: 'shipping-method',
            id: method.id,
          }
        : undefined;

      resMutation = await client.mutate<updateActiveCart>({
        mutation: UPDATE_ACTIVE_CART,
        variables: {
          id: updatedCart?.id,
          version: updatedCart?.version,
          actions: [
            {
              setShippingMethod: {
                shippingMethod: newShippingMethod,
              },
            },
          ],
        },
      });
    }

    return resMutation;
  };
};
