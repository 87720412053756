import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { getProductsPriceAndStock_products_results_masterData_current_allVariants } from 'common/interfaces/generated/getProductsPriceAndStock';
import { priceFromCents } from 'common/lib/commercetools';
import React from 'react';
import Txt from 'common/components/Txt';

interface Props {
  item: getProductsPriceAndStock_products_results_masterData_current_allVariants;
  className?: string;
}

const UpsellProductPrice = ({ item, className }: Props) => {
  const classes = useStyles();

  const price = item?.price?.value.centAmount;
  const discountPrice = item?.price?.discounted?.value.centAmount;

  return (
    <div className={clsx(classes.root, className)}>
      <Txt fontWeight="bold" data-testid="line-item-price">
        {discountPrice ? priceFromCents(discountPrice) : priceFromCents(price)}
      </Txt>
      {discountPrice && <Txt className={classes.oldPrice}>{priceFromCents(price)}</Txt>}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  oldPrice: {
    marginLeft: theme.spacing(2),
    textDecoration: 'line-through',
    color: theme.palette.error.main,
  },
}));

export default UpsellProductPrice;
